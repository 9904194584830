import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import CloseIcon from '@atlaskit/icon/core/close';
import Heading from '@atlaskit/heading';

const i18n = defineMessages({
	close: {
		id: 'content-types-header.content-types.share.close',
		defaultMessage: 'Close',
		description: 'Close button',
	},
});

export type DialogHeaderProps = {
	text: string;
	onClose: () => void;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogHeaderContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: token('space.150'),
});

export const DialogHeader: FC<DialogHeaderProps> = ({ text, onClose }) => {
	const intl = useIntl();
	return (
		<DialogHeaderContainer>
			<Heading size="medium">{text}</Heading>
			<Button
				appearance="subtle"
				iconBefore={<CloseIcon label={intl.formatMessage(i18n.close)} />}
				onClick={onClose}
			/>
		</DialogHeaderContainer>
	);
};
