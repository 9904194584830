import gql from 'graphql-tag';

export const EditorToolbarDockingPreferenceQuery = gql`
	query EditorToolbarDockingPreferenceQuery {
		userPreferences {
			confluenceEditorSettings {
				toolbarDockingInitialPosition
			}
		}
	}
`;
