import gql from 'graphql-tag';

export const ContentTitleMutation = gql`
	mutation ContentTitleMutation($contentId: ID!, $title: String) {
		updateTitle(contentId: $contentId, title: $title) {
			id
			title
			# updates link cache for content tree quick actions
			links {
				editui
				webui
			}
		}
	}
`;
