import type { FC } from 'react';
import React, { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useNativeCollabState } from '@confluence/native-collab';
import { usePresence } from '@confluence/native-collab/entry-points/usePresence';
import { deleteDraft, useLabels } from '@confluence/editor-features';
import { getParentOfDraft } from '@confluence/editor-features/entry-points/utils/getParentOfDraft';
import { useWillDeleteBlankDraftOnClose } from '@confluence/editor-features/entry-points/useWillDeleteBlankDraftOnClose';
import { useRestrictions } from '@confluence/editor-features/entry-points/useRestrictions';
import { useEmojiTitleCoverPictureAndContentState } from '@confluence/editor-features/entry-points/useEmojiTitleCoverPictureAndContentState';
import {
	deserializeState,
	useGetOnboardingState,
	useSetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { EDITOR_ONBOARDING_STATE_KEYS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { fg } from '@confluence/feature-gating';
import { useBlogTreeStore } from '@confluence/blog-tree/entry-points/useBlogTreeStore';
import { EDITOR_CLOSE_BUTTON_CLICK, setViewTransitionSource } from '@confluence/browser-metrics';
import { withPageTreeStateUpdater } from '@confluence/page-tree-refresh-state-container/entry-points/withPageTreeStateUpdater';
import type { PageTreeStateUpdater } from '@confluence/page-tree-refresh-state-container';

import { useEditorObjectHeaderContext } from '../EditorObjectHeaderContext';

type EditorCloseButtonComponentProps = {
	contentType: string;
	contentId: string;
	pageTreeStateUpdater: PageTreeStateUpdater;
	areMainButtonsDisabled?: boolean;
	isUnpublishedDraft?: boolean;
	useEmbeddedPublishButton?: boolean;
};

const i18n = defineMessages({
	closeButtonText: {
		id: 'content-types-header.close.name',
		defaultMessage: 'Close',
		description: 'Text for close button',
	},
	closeDraftButtonText: {
		id: 'content-types-header.close.draft.name',
		defaultMessage: 'Close draft',
		description: 'Text for close draft button',
	},
	discardDraftButtonText: {
		id: 'content-types-header.discard.draft.name',
		defaultMessage: 'Discard draft',
		description: 'Text for discard draft button',
	},
	closeButtonPublishTooltip: {
		id: 'content-types-header.editor.close.publish.tooltip',
		defaultMessage: 'Saves changes without publishing',
		description: 'Tooltip text displayed for close button when publishing',
	},
	closeButtonUpdateTooltip: {
		id: 'content-types-header.editor.close.update.tooltip',
		defaultMessage: 'Saves changes without updating',
		description: 'Tooltip text displayed for close button when updating',
	},
	discardOnCloseButtonTooltip: {
		id: 'content-types-header.editor.discard.close.tooltip',
		defaultMessage: 'Discard empty draft',
		description:
			"Tooltip text displayed for close button if it's an empty draft and will get discarded",
	},
});

export const ANALYTICS_SOURCE = 'next-FullPageEditorComponent';

export const EditorCloseButtonComponent: FC<EditorCloseButtonComponentProps> = ({
	contentType,
	contentId,
	pageTreeStateUpdater,
	areMainButtonsDisabled = false,
	isUnpublishedDraft = false,
	useEmbeddedPublishButton = false, // object header currently doesn't support Embedded Editor header. When we do, we should pass true for this.
}) => {
	const intl = useIntl();
	const [_, { forceRefetchBlogTree }] = useBlogTreeStore();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { saveDraft, navigateOnClose, triggerAnalyticsEvent, version, editorActions, title } =
		useEditorObjectHeaderContext();

	// START: discardDraftOnClose logic
	const [{ collabEditProvider }] = useNativeCollabState();
	const { isSingleUserSession } = usePresence(collabEditProvider);
	const {
		hasEmoji,
		hasCoverPicture,
		hasContentState,
		emojiCoverPictureAndContentStateSubscription,
	} = useEmojiTitleCoverPictureAndContentState();
	const { hasRestrictions } = useRestrictions();
	const hasLabels = useLabels(contentId);
	const hasTitle = Boolean(title);
	const hasBody = !editorActions.isDocumentEmpty();
	const discardDraftOnClose = useWillDeleteBlankDraftOnClose({
		isUnpublishedDraft,
		isSingleUserSession,
		hasTitle,
		hasBody,
		hasEmoji,
		hasCoverPicture,
		hasContentState,
		hasRestrictions,
		hasLabels,
	});
	// END: discardDraftOnClose logic

	// START: Recent Spotlight logic
	const { setOnboardingState } = useSetOnboardingState();
	const { data: onboardingStateData, loading: onboardingStateLoading } = useGetOnboardingState(
		Object.values(EDITOR_ONBOARDING_STATE_KEYS),
		!fg('cc_onboarding_experience') || version !== 1,
	);
	const { editorOnboardingEligiblity, recentSpotlightCompleted } =
		deserializeState(onboardingStateData);
	const shouldShowRecentSpotlight =
		!onboardingStateLoading && editorOnboardingEligiblity && !recentSpotlightCompleted; // Makes sure recentSpotlightCompleted is not undefined
	// END: Recent Spotlight logic

	const onClose = async () => {
		if (contentType === 'page') {
			setViewTransitionSource(EDITOR_CLOSE_BUTTON_CLICK);
		}
		if (shouldShowRecentSpotlight) {
			void setOnboardingState({
				key: EDITOR_ONBOARDING_STATE_KEYS.RECENT_SPOTLIGHT_OPEN,
				value: 'true',
			});
		}

		if (discardDraftOnClose) {
			triggerAnalyticsEvent('close', {
				discarded: true,
			});
			void deleteDraft({
				contentId,
				deleteOnlyIfBlank: true,
			}).then(async ({ data }) => {
				if (contentType === 'blogpost') {
					// refreshes blog tree on discard close of blog draft
					forceRefetchBlogTree();
				}
				triggerAnalyticsEvent('draftDiscarded', {
					wasDeleted: !!data?.deleteContent?.wasDeleted,
				});
				const parentId = await getParentOfDraft(contentId);
				if (parentId) {
					pageTreeStateUpdater.setForceFetchPageId(parentId);
				} else {
					createAnalyticsEvent({
						type: 'sendOperationalEvent',
						data: {
							action: 'notFound',
							actionSubject: 'discardedDraftParent',
							objectType: contentType,
							objectId: contentId,
						},
					}).fire();
				}
			});
			navigateOnClose({ isDiscarding: true });
		} else {
			triggerAnalyticsEvent('close');
			void saveDraft({
				isOnClose: true,
			}).then(() => navigateOnClose());
		}
	};

	let closeBtn = (
		<Button
			id="close-button"
			appearance="subtle"
			onClick={onClose}
			isDisabled={areMainButtonsDisabled}
			testId="close-button"
		>
			{discardDraftOnClose
				? intl.formatMessage(i18n.discardDraftButtonText)
				: intl.formatMessage(
						!useEmbeddedPublishButton && isUnpublishedDraft
							? i18n.closeDraftButtonText
							: i18n.closeButtonText,
					)}
		</Button>
	);

	// if the buttons are enabled, we should just show the normal tooltip of "close"
	if (!areMainButtonsDisabled) {
		closeBtn = (
			<Tooltip
				content={
					discardDraftOnClose
						? intl.formatMessage(i18n.discardOnCloseButtonTooltip)
						: intl.formatMessage(
								isUnpublishedDraft ? i18n.closeButtonPublishTooltip : i18n.closeButtonUpdateTooltip,
							)
				}
			>
				{closeBtn}
			</Tooltip>
		);
	}

	return (
		<Fragment>
			{emojiCoverPictureAndContentStateSubscription}
			{closeBtn}
		</Fragment>
	);
};

export const EditorCloseButton = withPageTreeStateUpdater(EditorCloseButtonComponent);
