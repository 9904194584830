import { useContext } from 'react';

import {
	WHITEBOARD_VERSION_HISTORY_LOAD_RESTORE_MODAL,
	WHITEBOARD_VERSION_HISTORY_RESTORE_EXPERIENCE,
	WHITEBOARD_VERSION_HISTORY_SIDE_PANEL_EXPERIENCE,
	DATABASE_VERSION_HISTORY_LOAD_RESTORE_MODAL,
	DATABASE_VERSION_HISTORY_RESTORE_EXPERIENCE,
	DATABASE_VERSION_HISTORY_SIDE_PANEL_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

type useVersionHistoryExperienceTrackingType = {
	contentType: string;
	experience: 'restoreModal' | 'restoreExperience' | 'sidePanel';
	task: 'start' | 'succeed';
};

const experienceMap = {
	whiteboard: {
		restoreModal: WHITEBOARD_VERSION_HISTORY_LOAD_RESTORE_MODAL,
		restoreExperience: WHITEBOARD_VERSION_HISTORY_RESTORE_EXPERIENCE,
		sidePanel: WHITEBOARD_VERSION_HISTORY_SIDE_PANEL_EXPERIENCE,
	},
	database: {
		restoreModal: DATABASE_VERSION_HISTORY_LOAD_RESTORE_MODAL,
		restoreExperience: DATABASE_VERSION_HISTORY_RESTORE_EXPERIENCE,
		sidePanel: DATABASE_VERSION_HISTORY_SIDE_PANEL_EXPERIENCE,
	},
};

export const useVersionHistoryExperienceTracking = () => {
	const experienceTracker = useContext(ExperienceTrackerContext);

	const trackExperience = ({
		contentType,
		experience,
		task,
	}: useVersionHistoryExperienceTrackingType) => {
		if (!contentType || !experience || !task) {
			return;
		}
		if (contentType === 'whiteboard' || contentType === 'database') {
			const experienceName = experienceMap[contentType]?.[experience];
			if (experienceName) {
				if (task === 'start') {
					experienceTracker.start({ name: experienceName });
				} else {
					experienceTracker.succeed({ name: experienceName });
				}
			}
		}
	};

	return {
		trackExperience,
	};
};
