import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Popup from '@atlaskit/popup';
import Button from '@atlaskit/button';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSpaceId } from '@confluence/space-utils';

import { DialogHeader } from './DialogHeader';
import { DialogBody } from './DialogBody';
import { DialogFooter } from './DialogFooter';

const i18n = defineMessages({
	shareDialogTitle: {
		id: 'content-types-header.content-types.share.share-dialog-title',
		defaultMessage: 'Share whiteboard',
		description: 'Text for the header of the share dialog',
	},
	shareButton: {
		id: 'content-types-header.content-types.share.share-button',
		defaultMessage: 'Share',
		description: 'Text for a button that allows a user to share their whiteboard with other users',
	},
	shareDatabaseDialogTitle: {
		id: 'content-types-header.content-types.share.share-database-dialog-title',
		defaultMessage: 'Share database',
		description: 'Text for the header of the share dialog when sharing a database',
	},
});

type ShareComponentProps = {
	contentId: string;
	contentType: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogContainer = styled.div({
	padding: `${token('space.300')} ${token('space.300')} 31px`,
	width: '480px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogContent = styled.div({
	cursor: 'auto',
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.250'),
});

type ShareDialogType = {
	title: string;
	onClose: () => void;
	contentId?: string;
	spaceId?: string;
};

export const ShareDialog: FC<ShareDialogType> = ({ title, onClose, contentId, spaceId }) => {
	return (
		<DialogContainer>
			<DialogHeader text={title} onClose={onClose} />
			<DialogContent>
				<DialogBody contentId={contentId} spaceId={spaceId} />
				<DialogFooter contentId={contentId || ''} />
			</DialogContent>
		</DialogContainer>
	);
};

export const ShareComponent: FC<ShareComponentProps> = ({ contentId, contentType }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const spaceId = useSpaceId();

	const onShareButtonClick = useCallback(() => {
		setIsOpen(!isOpen);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'share',
				containerType: 'space',
				containerId: spaceId,
				objectId: contentId,
				source: 'contentTypesHeader',
			},
		}).fire();
	}, [isOpen, spaceId, contentId, setIsOpen, createAnalyticsEvent]);

	return (
		<Popup
			content={() => (
				<ShareDialog
					title={
						contentType === 'database'
							? intl.formatMessage(i18n.shareDatabaseDialogTitle)
							: intl.formatMessage(i18n.shareDialogTitle)
					}
					onClose={() => setIsOpen(false)}
					contentId={contentId}
					spaceId={spaceId}
				/>
			)}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-end"
			shouldUseCaptureOnOutsideClick
			trigger={(triggerProps) => {
				return (
					<Button
						{...triggerProps}
						appearance="primary"
						onClick={onShareButtonClick}
						isSelected={isOpen}
					>
						{intl.formatMessage(i18n.shareButton)}
					</Button>
				);
			}}
		/>
	);
};
