import { LoadableLazy } from '@confluence/loadable';

export type { PageQuickActionAutomationMenuProps } from '../src/PageQuickActionAutomationMenu';
export const PageQuickActionAutomationMenu = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageQuickActionAutomationMenu" */ '../src/PageQuickActionAutomationMenu'
			)
		).PageQuickActionAutomationMenu,
});
