import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	requestToEdit: {
		defaultMessage: 'Request to edit',
		description:
			'This is the label for a button that allows a user with view only permission to request edit permission. On click of this button an email is sent to the content owner for approval.',
		id: 'no-permission.request.to.edit.button.label',
	},
	editRequestSent: {
		defaultMessage: 'Edit request sent',
		description:
			'This is the label for a disabled button that informs the user that the request to edit has been sent.',
		id: 'no-permission.edit.request.sent.button.label',
	},
	notSentFlagTitle: {
		defaultMessage: 'Your request wasn’t sent',
		description:
			'The title of the warning flag that appears when the request to edit failed. This informs that user that the request to edit was not sent.',
		id: 'no-permission.request.to.edit.not.sent.flag.title',
	},
	notSentFlagDescription: {
		defaultMessage:
			'We ran into a problem submitting your edit request. Try again or contact your admin.',
		description:
			"Body of warning flag when the request wasn't sent. It informs the user to try again or contact the admin as recourse for the failed request.",
		id: 'no-permission.request.to.edit.not.sent.flag.description',
	},
	requestSentFlagTitle: {
		defaultMessage: 'Request to edit sent',
		description: 'Title of info flag when the request was sent successfully.',
		id: 'no-permission.request.to.edit.request.sent.flag.title',
	},
	requestSentFlagDescription: {
		defaultMessage:
			'We’ve asked {ownerName} to give you edit access. You’ll get an email once your request is approved.',
		description:
			'Body of info flag when the request was sent successfully. It informs the user that the request was sent to the content owner and that the user will get an email once the request is approved.',
		id: 'no-permission.request.to.edit.request.sent.flag.description',
	},
	requestSentFlagAction: {
		defaultMessage: 'Got it',
		description:
			'Action to dismiss the request was sent info flag. This is an acknowledgment that the user has read the flag.',
		id: 'no-permission.request.to.edit.request.sent.flag.action',
	},
});
