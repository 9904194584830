import { createStore, createHook, createActionsHook } from 'react-sweet-state';

type InitialState = {
	activeSpotlightIndex: number | null;
	lastActiveSpotlightIndex: number | null;
};

const initialState: InitialState = {
	activeSpotlightIndex: null,
	lastActiveSpotlightIndex: null,
};

const Store = createStore({
	initialState,
	actions: {
		goToStep:
			(nextStep: number) =>
			({ setState }) => {
				setState({ activeSpotlightIndex: nextStep });
			},
		resetTour:
			() =>
			({ setState }) => {
				setState(initialState);
			},
		initiateTour:
			() =>
			({ setState }) => {
				setState({
					activeSpotlightIndex: 0,
				});
			},
		proceedToNext:
			() =>
			({ setState, getState }) => {
				const { activeSpotlightIndex } = getState();
				if (activeSpotlightIndex === null) return;

				setState({
					activeSpotlightIndex: activeSpotlightIndex + 1,
					lastActiveSpotlightIndex: activeSpotlightIndex,
				});
			},
		concludeTour:
			() =>
			({ setState, getState }) => {
				const { activeSpotlightIndex } = getState();

				setState({
					activeSpotlightIndex: null,
					lastActiveSpotlightIndex: activeSpotlightIndex,
				});
			},
	},
});

export const useMoreActionsObjectSidebarTourState = createHook(Store);
export const useMoreActionsObjectSidebarTourActions = createActionsHook(Store);
