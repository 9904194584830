import React, { useState, useCallback, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { Stack, Box, Text } from '@atlaskit/primitives';

import { ScreenEvent } from '@confluence/analytics';
import { fg } from '@confluence/feature-gating';
import {
	useSetOnboardingState,
	useGetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useChoreographerAssets } from '@confluence/choreographer-services';
import { useObjectSidebarState } from '@confluence/object-sidebar-api';

import { useMoreActionsObjectSidebarTourState } from './useMoreActionsObjectSidebarTourState';
import { OBJECT_SIDEBAR_TOUR_TARGETS } from './objectSidebarTourTargets';
import { MessageType } from './objectSidebarTourMessageTypes';
import { IS_DISMISSED_FLAG, isOnboardingStateEqual } from './objectSidebarTourHelpers';
import { useIsMoreActionsHintDismissed } from './useIsMoreActionsHintDismissed';

const { IS_MORE_ACTIONS_TOUR_VISIBLE } = ONBOARDING_EXPERIENCES;

export enum MoreActionsObjectSidebarTourSteps {
	FIRST,
	SECOND,
}

const i18n = defineMessages({
	dismiss: {
		id: 'object-sidebar-components.more-actions.tour.dismiss',
		defaultMessage: 'Dismiss',
		description: 'A label that indicates which button should be clicked to dismiss the tour',
	},
	goThereNow: {
		id: 'object-sidebar-components.more-actions.tour.go-there-now',
		defaultMessage: 'Go there now',
		description:
			'A label that indicates which button should be clicked to go to the next step of the tour',
	},
	ok: {
		id: 'object-sidebar-components.more-actions.tour.ok',
		defaultMessage: 'OK',
		description: 'A label that indicates which button should be clicked to end the tour',
	},
	weHaveReorganized: {
		id: 'object-sidebar-components.more-actions.tour.we-have-reorganized',
		defaultMessage: 'We’ve reorganized a bit',
		description: "The heading of a spotlight highlighting the menu's organization",
	},
	easierToFindWhatYouNeed: {
		id: 'object-sidebar-components.more-actions.tour.easier-to-find-what-you-need',
		defaultMessage: 'We’re working hard to make it even easier to find what you need.',
		description:
			'The first paragraph of the body of a spotlight highlighting the benefits of the reorganized menu',
	},
	checkTheDetailsPanel: {
		id: 'object-sidebar-components.more-actions.tour.check-the-details-panel',
		defaultMessage:
			'Don’t see what you’re looking for? Check the new <strong>Details</strong> panel in the floating action bar.',
		description:
			'The first paragraph of the body of a spotlight highlighting the benefits of the reorganized menu, as well as where to find missing menu items',
	},
	getTheDetailsYouNeed: {
		id: 'object-sidebar-components.more-actions.tour.get-the-details-you-need',
		defaultMessage: 'Get the details you need',
		description:
			'The heading of a spotlight highlighting the benefits of the Details Panel, which includes information about the content it appears alongside',
	},
	viewAndManageActivity: {
		id: 'object-sidebar-components.more-actions.tour.view-and-manage-activity',
		defaultMessage: 'View and manage activity, related content, and more in a single panel.',
		description:
			'The body of a spotlight highlighting the benefits of the Details Panel, which includes information about the content it appears alongside',
	},
});

type UseSpotlightsProps = {
	next: () => void;
	end: () => void;
};

const useSpotlights = ({ next, end }: UseSpotlightsProps) => {
	const { formatMessage } = useIntl();

	return [
		{
			key: OBJECT_SIDEBAR_TOUR_TARGETS.MORE_ACTIONS_ITEMS,
			target: OBJECT_SIDEBAR_TOUR_TARGETS.MORE_ACTIONS_ITEMS,
			actions: [
				{ onClick: next, text: formatMessage(i18n.goThereNow) },
				{ onClick: end, text: formatMessage(i18n.dismiss) },
			],
			heading: formatMessage(i18n.weHaveReorganized),
			children: (
				<Stack space="space.300">
					<Box>{formatMessage(i18n.easierToFindWhatYouNeed)}</Box>
					<Box>
						{formatMessage(i18n.checkTheDetailsPanel, {
							strong: (text: React.ReactNode) => <Text as="strong">{text}</Text>,
						})}
					</Box>
				</Stack>
			),
		},
		{
			key: OBJECT_SIDEBAR_TOUR_TARGETS.SIDEBAR_DETAILS_BUTTON,
			target: OBJECT_SIDEBAR_TOUR_TARGETS.SIDEBAR_DETAILS_BUTTON,
			targetRadius: 100,
			actions: [{ onClick: end, text: formatMessage(i18n.ok) }],
			heading: formatMessage(i18n.getTheDetailsYouNeed),
			children: formatMessage(i18n.viewAndManageActivity),
		},
	];
};

type UseMoreActionsTourProps = {
	step: number;
	isMenuOpen?: boolean;
	setIsMenuOpen?: (isMenuOpen: boolean) => void;
};

const OVERFLOW_MENU_CHANGEBOARDING = 'overflowMenuChangeboarding';
const FLOATING_ACTION_BAR_CHANGEBOARDING = 'floatingActionBarChangeboarding';
const SCREEN_EVENT_SUBJECTS = [OVERFLOW_MENU_CHANGEBOARDING, FLOATING_ACTION_BAR_CHANGEBOARDING];

export const useMoreActionsObjectSidebarTour = ({
	step,
	isMenuOpen,
	setIsMenuOpen,
}: UseMoreActionsTourProps) => {
	const { sidebarControlState } = useObjectSidebarState();
	const isFirstTourTargetVisible = step === MoreActionsObjectSidebarTourSteps.FIRST && isMenuOpen;
	const isSecondTourTargetVisible = sidebarControlState === 'shown';

	const [
		{ activeSpotlightIndex, lastActiveSpotlightIndex },
		{ concludeTour: endTour, proceedToNext: goToNext, initiateTour, resetTour },
	] = useMoreActionsObjectSidebarTourState();

	const { setOnboardingState } = useSetOnboardingState();
	const [messageType, setMessageType] = useState<MessageType>(MessageType.Engagement);

	const {
		data: isMoreActionsTourOnboardingStateData,
		error: isMoreActionsTourOnboardingStateError,
		loading: isMoreActionsTourOnboardingStateLoading,
	} = useGetOnboardingState(
		IS_MORE_ACTIONS_TOUR_VISIBLE,
		!fg('confluence_frontend_object_sidebar_changeboarding'),
	);

	const isTourCompleted = isMoreActionsTourOnboardingStateData?.onboardingState?.some(
		isOnboardingStateEqual(IS_MORE_ACTIONS_TOUR_VISIBLE, IS_DISMISSED_FLAG),
	);

	const isMoreActionsHintDismissed = useIsMoreActionsHintDismissed();
	const isMoreActionsTourCompleted =
		!isMoreActionsTourOnboardingStateError &&
		!isMoreActionsTourOnboardingStateLoading &&
		isTourCompleted;

	const isHintVisible =
		isMoreActionsTourCompleted &&
		!isMoreActionsHintDismissed &&
		// This prevents the hint from appearing when the tour is dismissed during the first step
		// (while the menu is still open)
		isSecondTourTargetVisible &&
		lastActiveSpotlightIndex !== MoreActionsObjectSidebarTourSteps.FIRST;

	const { Spotlight } = useChoreographerAssets();

	const proceedToNext = useCallback(() => {
		goToNext();
		setIsMenuOpen?.(false);
		setMessageType(MessageType.Transactional);
	}, [goToNext, setIsMenuOpen]);

	const concludeTour = useCallback(() => {
		endTour();
		setOnboardingState({ key: IS_MORE_ACTIONS_TOUR_VISIBLE, value: IS_DISMISSED_FLAG });
	}, [endTour, setOnboardingState]);

	useEffect(() => {
		if (
			isFirstTourTargetVisible &&
			isSecondTourTargetVisible &&
			!isTourCompleted &&
			!isMoreActionsTourOnboardingStateError &&
			!isMoreActionsTourOnboardingStateLoading &&
			fg('confluence_frontend_object_sidebar_changeboarding')
		) {
			setTimeout(() => {
				initiateTour();
			}, 0);
		}
	}, [
		initiateTour,
		isFirstTourTargetVisible,
		isSecondTourTargetVisible,
		isTourCompleted,
		isMoreActionsTourOnboardingStateError,
		isMoreActionsTourOnboardingStateLoading,
	]);

	const spotlights = useSpotlights({
		next: proceedToNext,
		end: concludeTour,
	});

	return {
		activeSpotlight:
			activeSpotlightIndex === step ? (
				<>
					<Spotlight
						messageType={messageType}
						shouldWatchTarget
						dialogPlacement="left bottom"
						targetBgColor={token('elevation.surface.overlay')}
						{...spotlights[activeSpotlightIndex]}
					/>
					<ScreenEvent
						id={SCREEN_EVENT_SUBJECTS[activeSpotlightIndex]}
						name={SCREEN_EVENT_SUBJECTS[activeSpotlightIndex]}
					/>
				</>
			) : null,
		initiateTour,
		proceedToNext,
		concludeTour,
		resetTour,
		activeSpotlightIndex,
		lastActiveSpotlightIndex,
		isTourCompleted,
		isHintVisible,
	};
};
