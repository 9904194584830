import type { ComponentProps } from 'react';
import { useCallback, useRef } from 'react';

import type { DialogsContainerProps } from '@confluence/dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { LoadableAfterPaint } from '@confluence/loadable';

type FeedbackCollectorLoaderProps = ComponentProps<typeof FeedbackCollectorLoader>;

export function useFeedbackCollector(
	props: Omit<FeedbackCollectorLoaderProps, keyof DialogsContainerProps>,
) {
	const { showModal } = useDialogs();

	const propsRef = useRef<typeof props>();
	propsRef.current = props;

	return useCallback(() => showModal(FeedbackCollectorLoader, propsRef.current!), [showModal]);
}

const FeedbackCollectorLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LazyFeedbackCollector" */ './FeedbackCollector'))
			.FeedbackCollector,
});
