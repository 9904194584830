import React, { useState } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { RestrictionsDataContainer } from '@confluence/restrictions/entry-points/RestrictionsDataContainer';

export const useRestrictions = () => {
	const [hasRestrictions, setHasRestrictions] = useState<boolean>(false);

	const hasRestrictionsSubscription = (
		<Subscribe to={[RestrictionsDataContainer]}>
			{(restrictionsState: RestrictionsDataContainer) => {
				setHasRestrictions(restrictionsState.state.hasRestrictions);
				return null;
			}}
		</Subscribe>
	);

	return { hasRestrictionsSubscription, hasRestrictions };
};
