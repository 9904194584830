import React, { createContext, useMemo } from 'react';
import type { FC, ReactNode } from 'react';

type ResolveWhiteboardCommentIds = {
	contentId: string | undefined;
	referenceId: string | undefined;
};

export type ResolvedCommentHandler = (resolvedIds: ResolveWhiteboardCommentIds) => void;

export type WhiteboardResolvedCommentContextType = {
	onReopenCommentCallback: ResolvedCommentHandler;
};

export const WhiteboardResolvedCommentContext = createContext<WhiteboardResolvedCommentContextType>(
	{
		onReopenCommentCallback: () => {},
	},
);

type WhiteboardResolvedCommentProviderProps = {
	callback: ResolvedCommentHandler;
	children?: ReactNode;
};

export const WhiteboardResolvedCommentProvider: FC<WhiteboardResolvedCommentProviderProps> = ({
	callback,
	children,
}) => {
	const contextValue = useMemo(
		() => ({
			onReopenCommentCallback: callback,
		}),
		[callback],
	);

	return (
		<WhiteboardResolvedCommentContext.Provider value={contextValue}>
			{children}
		</WhiteboardResolvedCommentContext.Provider>
	);
};
