import gql from 'graphql-tag';

export const DeleteDraftMutation = gql`
	mutation DeleteDraftMutation($contentId: ID!, $action: ContentDeleteActionType!) {
		deleteContent(contentId: $contentId, action: $action) {
			contentId
			wasDeleted
		}
	}
`;
