import { useCallback, useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { RoutesContext } from '@confluence/route-manager';
import {
	SPACE_OVERVIEW,
	VIEW_PAGE,
	EDIT_PAGE_V2,
	VIEW_BLOG,
	WHITEBOARD_BOARD,
	EDIT_BLOG_V2,
	EMBED_VIEW,
	DATABASE_VIEW,
} from '@confluence/named-routes';

import { useCopyToClipboard } from '../useCopyToClipboard';

import type {
	CopyLinkQuery as CopyLinkQueryType,
	CopyLinkQueryVariables,
} from './__types__/CopyLinkQuery';
import { CopyLinkQuery } from './CopyLinkQuery.graphql';
import { PAGE_MODE, ACTION_SUBJECT_ID } from './CopyLink';
import type { CopyLinkProps } from './CopyLink';

export enum SUPPORTED_CONTENT_TYPES {
	PAGE = 'page',
	BLOGPOST = 'blogpost',
	OVERVIEW = 'overview',
	WHITEBOARD = 'whiteboard',
	EMBED = 'embed',
	DATABASE = 'database',
}

type UseCopyContentLinkProps = Omit<
	CopyLinkProps,
	'shouldResetOnHover | buttonAppearance | tooltipPosition'
>;

type UseCopyContentLinkReturn = {
	queryError: ApolloError | undefined;
	unsupportedLinkError: Error | undefined;
	getCopyContentLink: () => string | undefined;
	copyContentLink: () => Promise<void>;
};

export const useCopyContentLink = ({
	pageMode,
	contentType,
	contentId,
	spaceKey,
	source,
}: UseCopyContentLinkProps): UseCopyContentLinkReturn => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [unsupportedLinkError, setUnsupportedLinkError] = useState<Error | undefined>();
	const { toUrl } = useContext(RoutesContext);
	const { copyToClipboard } = useCopyToClipboard();

	const {
		data,
		loading,
		error: queryError,
	} = useQuery<CopyLinkQueryType, CopyLinkQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CopyLinkQuery,
		{
			variables: { contentId },
		},
	);

	const getCopyContentLink = useCallback(() => {
		let copyLink;
		// Try to get the page link from queried data, shortened (for view mode) if possible.
		if (data) {
			const links = data?.singleContent?.links;
			if (pageMode === PAGE_MODE.EDIT) {
				copyLink = links?.editui;
			} else {
				// pageMode === "view" OR no page mode specified, eg for whiteboards
				copyLink = links?.tinyui || links?.webui;
			}
			if (copyLink && links?.base) {
				return `${links.base}${copyLink}`;
			}
		}

		// Fallback behavior: Use content type + page mode to construct URL.
		let routeName, additionalParams;
		if (pageMode === PAGE_MODE.EDIT) {
			routeName =
				contentType === SUPPORTED_CONTENT_TYPES.BLOGPOST ? EDIT_BLOG_V2.name : EDIT_PAGE_V2.name;
			additionalParams = {
				contentType:
					// EDIT_BLOG_V2 and EDIT_PAGE_V2 routes expect particular contentType format during route construction
					contentType === SUPPORTED_CONTENT_TYPES.BLOGPOST ? 'blog' : 'pages',
			};
		} else {
			switch (contentType) {
				case SUPPORTED_CONTENT_TYPES.OVERVIEW:
					routeName = SPACE_OVERVIEW.name;
					break;
				case SUPPORTED_CONTENT_TYPES.BLOGPOST:
					routeName = VIEW_BLOG.name;
					break;
				case SUPPORTED_CONTENT_TYPES.PAGE:
					routeName = VIEW_PAGE.name;
					break;
				case SUPPORTED_CONTENT_TYPES.WHITEBOARD:
					routeName = WHITEBOARD_BOARD.name;
					break;
				case SUPPORTED_CONTENT_TYPES.EMBED:
					routeName = EMBED_VIEW.name;
					break;
				case SUPPORTED_CONTENT_TYPES.DATABASE:
					routeName = DATABASE_VIEW.name;
				// omit unsupported content types
				default:
					const error = new Error(`Received an unexpected content type: '${contentType}'`);
					createAnalyticsEvent({
						type: 'sendOperationalEvent',
						data: {
							action: 'thrown',
							actionSubject: 'error',
							actionSubjectId: ACTION_SUBJECT_ID,
							source,
							attributes: {
								error: error?.message,
								contentType,
								pageMode,
							},
						},
					}).fire();
					setUnsupportedLinkError(error);
			}
		}

		if (routeName) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'copied',
					actionSubject: 'fallbackLink',
					actionSubjectId: ACTION_SUBJECT_ID,
					source,
					attributes: {
						queryError: queryError?.message,
						isDataLoading: loading,
						route: routeName,
					},
				},
			}).fire();

			// Omit contentSlug, because user perception is that link sharing
			// is more secure without the content title in the URL.
			return `${window.location.origin}${toUrl(routeName, {
				contentId,
				spaceKey,
				...additionalParams,
			})}`;
		}
		return undefined;
	}, [
		toUrl,
		contentId,
		contentType,
		spaceKey,
		pageMode,
		source,
		data,
		loading,
		queryError,
		createAnalyticsEvent,
	]);

	const copyContentLink = useCallback(() => {
		return copyToClipboard(getCopyContentLink());
	}, [getCopyContentLink, copyToClipboard]);

	return {
		queryError,
		unsupportedLinkError,
		getCopyContentLink,
		copyContentLink,
	};
};
