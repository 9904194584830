import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import { getApolloCache } from '@confluence/graphql';

import { ContentTitleFragment } from './ContentTitleFragment.fragment';
import { ContentTitleMutation } from './ContentTitleMutation.graphql';
import type {
	ContentTitleMutation as ContentTitleMutationType,
	ContentTitleMutationVariables,
} from './__types__/ContentTitleMutation';

type TitleUpdateData = {
	isEditing: boolean;
	setIsEditing: (boolean: boolean) => void;
	setUpdatedTitleFromPubsub: (value: string | undefined) => void;
	updateLoading: boolean;
	updateError?: ApolloError;
	updateTitle: (value: string) => void;
	updateCache: (value: string) => void;
};

export const useContentTypesTitleUpdate = (
	contentId: string,
	currentTitle: string,
): TitleUpdateData => {
	const [isEditing, setIsEditing] = useState(false);
	const [updatedTitleFromPubsub, setUpdatedTitleFromPubsub] = useState<string | undefined>(
		undefined,
	);
	const onMutationComplete = () => {
		setUpdateError(undefined);
		setIsEditing(false);
	};

	const [updateError, setUpdateError] = useState<ApolloError | undefined>(undefined);
	const [updateContentTitle, { loading: updateLoading }] = useMutation<
		ContentTitleMutationType,
		ContentTitleMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentTitleMutation,
		{
			onCompleted: onMutationComplete,
			onError: setUpdateError,
		},
	);

	const updateCache = useCallback(
		(value: string) => {
			const cache = getApolloCache();
			cache.writeFragment({
				id: `Content:${contentId}`,
				fragment: ContentTitleFragment,
				data: {
					id: contentId,
					title: value,
					__typename: 'Content',
				},
			});
		},
		[contentId],
	);

	const updateTitle = useCallback(
		async (value: string) => {
			if (value === currentTitle) {
				setIsEditing(false);
				// We have to manually clear the error here because it is no longer relevant to this most recent call to `updateTitle`,
				// since we're now attempting to set the title back to the current title, which should be valid.
				setUpdateError(undefined);
				if (updatedTitleFromPubsub && updatedTitleFromPubsub !== currentTitle) {
					updateCache(updatedTitleFromPubsub);
					setUpdatedTitleFromPubsub(undefined);
				}
				return;
			}

			try {
				await updateContentTitle({
					variables: {
						contentId,
						title: value,
					},
				});
			} catch (_) {
				// error handled by useMutation result updateError
			}
		},
		[contentId, currentTitle, updateCache, updateContentTitle, updatedTitleFromPubsub],
	);

	return {
		isEditing,
		setIsEditing,
		setUpdatedTitleFromPubsub,
		updateLoading,
		updateError,
		updateTitle,
		updateCache,
	};
};
