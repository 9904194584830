import React, { useState, Fragment } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { EmojiTitleStateContainer } from '@confluence/emoji-title/entry-points/emojiTitleStateContainer';
import { CoverPictureStateContainer } from '@confluence/page-cover-picture';
import { ContentStateStateContainer } from '@confluence/content-state';

export const useEmojiTitleCoverPictureAndContentState = () => {
	const [hasEmoji, setHasEmoji] = useState<boolean>(false);
	const [hasCoverPicture, setHasCoverPicture] = useState<boolean>(false);
	const [hasContentState, setHasContentState] = useState<boolean>(false);

	const emojiCoverPictureAndContentStateSubscription = (
		<Fragment>
			<Subscribe to={[EmojiTitleStateContainer]}>
				{(emojiTitleState: EmojiTitleStateContainer) => {
					setHasEmoji(!!emojiTitleState.state.selectedEmoji);
					return null;
				}}
			</Subscribe>
			<Subscribe to={[CoverPictureStateContainer]}>
				{(coverPictureState: CoverPictureStateContainer) => {
					setHasCoverPicture(coverPictureState.state.hasCoverPicture);
					return null;
				}}
			</Subscribe>
			<Subscribe to={[ContentStateStateContainer]}>
				{(contentStateState: ContentStateStateContainer) => {
					setHasContentState(!!contentStateState.state.contentState);
					return null;
				}}
			</Subscribe>
		</Fragment>
	);

	return {
		emojiCoverPictureAndContentStateSubscription,
		hasCoverPicture,
		hasEmoji,
		hasContentState,
	};
};
