import type { FocusEvent, FC } from 'react';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { css, styled } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import type { ApolloError } from 'apollo-client';

import { Box, xcss } from '@atlaskit/primitives';
import ErrorIcon from '@atlaskit/icon/core/error';
import type { InlineEditProps } from '@atlaskit/inline-edit';
import InlineEdit from '@atlaskit/inline-edit';
import { SpotlightCard } from '@atlaskit/onboarding';
import Textfield from '@atlaskit/textfield';
import type { TooltipPrimitiveProps } from '@atlaskit/tooltip';
import Tooltip, { TooltipPrimitive } from '@atlaskit/tooltip';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { PageSegmentLoadEnd, PageSegmentLoadStart } from '@confluence/browser-metrics';
import { ErrorDisplay, Attribution } from '@confluence/error-boundary';
import { DocumentContentTitle } from '@confluence/document-title';
import { markErrorAsHandled } from '@confluence/graphql';
import { usePubSubEvent } from '@confluence/pubsub-client';
import { useSpaceId } from '@confluence/space-utils';
import { useOptimisticTitleChange } from '@confluence/page-tree-refresh-state-container/entry-points/useOptimisticTitleChange';
import { fg } from '@confluence/feature-gating';

import { useContentTypesTitleUpdate } from './useContentTypesTitleUpdate';
import { CONTENT_TYPES_HEADER_TITLE_SEGMENT } from './perf.config';

export const MAX_VALID_BOARD_NAME_LENGTH = 60;

export const isDuplicateTitleError = (error: ApolloError) => {
	return error.message.includes('exists with the same title in this space');
};

const i18n = defineMessages({
	placeholder: {
		id: 'content-types-header.content-types-title.placeholder',
		defaultMessage: 'Untitled whiteboard',
		description: 'placeholder to populate the Read and Edit view inputs',
	},
	databaseTitlePlaceholder: {
		id: 'content-types-header.content-types-title.database-title-placeholder',
		defaultMessage: 'Untitled database',
		description: 'placeholder to populate empty Read and Edit title view inputs for a database',
	},
	embedTitlePlaceholder: {
		id: 'content-types-header.content-types-title.embed-title-placeholder',
		defaultMessage: 'Untitled Smart Link',
		description: 'placeholder to populate empty Read and Edit title view inputs for a Smart Link',
	},
	renameTooltip: {
		id: 'content-types-header.content-types-title.rename-tooltip',
		defaultMessage: 'Rename',
		description: 'Tooltip to appear when user hovers title',
	},
	permissionsTooltip: {
		id: 'content-types-header.content-types-title.permissions-tooltip',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "You don't have permission to edit this whiteboard",
		description: 'Tooltip to appear when title edit is disabled due to restrictions',
	},
	databasePermissionsTooltip: {
		id: 'content-types-header.content-types-title.database-permissions-tooltip',
		defaultMessage: 'You don’t have permission to edit this database',
		description: 'Tooltip to appear when database title edit is disabled due to restrictions',
	},
	embedPermissionsTooltip: {
		id: 'content-types-header.content-types-title.embed-permissions-tooltip',
		defaultMessage: 'You don’t have permission to edit this Smart Link',
		description: 'Tooltip to appear when a Smart Link title edit is disabled due to restrictions',
	},
	duplicateTitleError: {
		id: 'content-types-header.content-types-title.duplicate-title-error',
		defaultMessage: 'A whiteboard with this title already exists in this space',
		description: 'error to be shown in popup if there is duplicate title of same content type',
	},
	duplicateDatabaseTitleError: {
		id: 'content-types-header.content-types-title.duplicate-database-title-error',
		defaultMessage: 'A database with this title already exists in this space',
		description:
			'error to be shown in popup when setting the title of a database to a value already in use by another database in the same space',
	},
	duplicateEmbedTitleError: {
		id: 'content-types-header.content-types-title.duplicate-embed-title-error',
		defaultMessage: 'A Smart Link with this title already exists in this space',
		description:
			'error to be shown in popup when setting the title of a Smart Link to a value already in use by another Smart Link in the same space',
	},
	generalError: {
		id: 'content-types-header.content-types-title.general-error',
		defaultMessage: 'There was an error on our end. Refresh and try again.',
		description: 'general error to be shown in popup if the content title update request fails',
	},
	titleLengthError: {
		id: 'content-types-header.content-types-title.title-length-error',
		defaultMessage:
			'{maxLength, plural, one {Title has a maximum of {maxLength} character} other {Title has a maximum of {maxLength} characters}}',
		description: 'error to be shown once the title exceeds the maximum characters allowed',
	},
	onboardingSpotlightBody: {
		id: 'content-types-header.content-types-title.onboarding-spotlight-body',
		defaultMessage: 'Give your whiteboard a unique name',
		description:
			'Text for an onboarding spotlight describing the whiteboard title in the content type header',
	},
});

const i18nByContentType = {
	duplicateTitleError: {
		database: i18n.duplicateDatabaseTitleError,
		whiteboard: i18n.duplicateTitleError,
		embed: i18n.duplicateEmbedTitleError,
	},
	permissionsTooltip: {
		database: i18n.databasePermissionsTooltip,
		whiteboard: i18n.permissionsTooltip,
		embed: i18n.embedPermissionsTooltip,
	},
	placeholder: {
		database: i18n.databaseTitlePlaceholder,
		embed: i18n.embedTitlePlaceholder,
		whiteboard: i18n.placeholder,
	},
};

const height = '20px';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	alignItems: 'center',
	fontWeight: token('font.weight.medium'),
	width: 'fit-content',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& form, form > *': {
		margin: '0',
	},
});

// This needs to be a tagged template expression because @compiled isn't applying the `attr(data-value)`
// correctly when converted to using a CSS object instead
// eslint-disable-next-line @compiled/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const EditViewContainer = styled.div`
	overflow: hidden;
	&:before {
		content: attr(data-value);
		display: flex;
		font: ${token('font.body')};
		max-width: 100%;
		min-width: 158px;
		min-height: ${height}; /* Use line height as minimum height so empty state is still layed out correctly */
		padding: ${token('space.025')} ${token('space.100')}; /* 2px padding for borders + 8 px to match the padding on the input */
		white-space: pre; /* Hide this content as it exists under the input, but preserve whitespace to get correct spacing */
		overflow: hidden;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextViewContainer = styled.div<{ errorIcon: boolean; isBoldedTitle: boolean }>({
	position: 'absolute',
	top: '0',
	left: '0',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .text-field-input': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > [data-ds--text-field--input]': {
			font: token('font.body'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			fontWeight: (p) =>
				p.isBoldedTitle ? token('font.weight.bold') : token('font.weight.medium'),

			height,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			...(props) => props.errorIcon && { paddingRight: '0px' },
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&[data-invalid]:focus-within': {
			borderColor: `${token('color.icon.danger')}`,
		},
	},
});

const readOnlyContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: 'space.250',
	maxWidth: '100%',
	paddingTop: 'space.025',
	paddingBottom: 'space.025',
	paddingRight: 'space.075',
	paddingLeft: 'space.075',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	cursor: 'text',
});

const boldedReadOnlyContainerStyles = xcss({
	fontWeight: 'font.weight.bold',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
});

const popupContainerStyles = xcss({
	padding: 'space.200',
	color: 'color.text',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorIconWrapper = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&>span>svg': {
		width: '20px',
		height: '20px',
		maxWidth: 'unset',
	},
});

const readOnlyTitleWrapperStyles = xcss({
	padding: 'space.025', //to match 2px transparent border on InlineEdit readView
});

const tooltipStyles = css({
	background: `${token('color.background.neutral.bold')}`,
	color: `${token('color.text.inverse')}`,
	borderRadius: '3px',
	font: token('font.body.UNSAFE_small'),
	padding: '2px 6px',
	maxWidth: '420px',
});

// same styles as a tooltip but with no width restriction to accommodate longer messages

export const ToolTipOverride = forwardRef<HTMLDivElement, TooltipPrimitiveProps>(
	function ToolTipOverride({ children, ...props }, ref) {
		return (
			<TooltipPrimitive {...props} ref={ref} css={tooltipStyles}>
				{children}
			</TooltipPrimitive>
		);
	},
) as React.ForwardRefExoticComponent<TooltipPrimitiveProps>;

const spotlightWrapperStyles = xcss({
	marginTop: 'space.100',
});

export type ContentTypesTitleProps = {
	title: string;
	contentId: string;
	spaceName: string;
	readOnly?: boolean;
	updateContentTree: () => void;
	contentType: string;
	onCloseTitleSpotlight?: () => void;
	registerShowTitleSpotlightHandler?: (callback: () => void) => void;
};

function onFocus(evt: FocusEvent<HTMLInputElement>) {
	evt.target.select();
}

export const ContentTypesTitle: FC<ContentTypesTitleProps> = ({
	title,
	contentId,
	spaceName,
	readOnly,
	updateContentTree,
	contentType,
	onCloseTitleSpotlight,
	registerShowTitleSpotlightHandler,
}) => {
	const intl = useIntl();
	const placeholderText = intl.formatMessage(
		i18nByContentType.placeholder[contentType] || i18n.placeholder,
	);
	const {
		isEditing,
		setIsEditing,
		setUpdatedTitleFromPubsub,
		updateLoading,
		updateError,
		updateTitle,
		updateCache,
	} = useContentTypesTitleUpdate(contentId, title);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const spaceId = useSpaceId();

	const onPubSubEvent = useCallback(
		async (_aris: any, pubsubRequestBody: any) => {
			const title = pubsubRequestBody?.contentTitleUpdateRequestBody.contentTitle;

			if (!isEditing) {
				void updateCache(title);
				void updateContentTree();
				setUpdatedTitleFromPubsub(undefined);
			} else {
				setUpdatedTitleFromPubsub(title);
			}
		},
		[isEditing, updateCache, updateContentTree, setUpdatedTitleFromPubsub],
	);

	usePubSubEvent({
		contentId,
		contentType,
		eventName: `titleUpdated:${contentType}`,
		onEvent: onPubSubEvent,
		attribution: Attribution.CONTENT_TYPES,
	});

	const [isTitleSpotlightActive, setIsTitleSpotlightActive] = useState(false);

	useEffect(() => {
		if (isTitleSpotlightActive) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'onboardingSpotlightScreen',
					attributes: {
						contentType,
						spotlight: 'spotlight-content-type-title',
					},
					source: 'contentTypesHeader',
				},
			}).fire();
		}
	}, [contentType, createAnalyticsEvent, isTitleSpotlightActive]);

	useEffect(() => {
		registerShowTitleSpotlightHandler?.(() => {
			setIsEditing(true);
			setIsTitleSpotlightActive(true);
		});
	}, [registerShowTitleSpotlightHandler, setIsEditing, setIsTitleSpotlightActive]);

	const showNextSpotlight = useCallback(() => {
		if (isTitleSpotlightActive) {
			setIsTitleSpotlightActive(false);
			onCloseTitleSpotlight?.();
		}
	}, [isTitleSpotlightActive, onCloseTitleSpotlight, setIsTitleSpotlightActive]);

	useEffect(() => {
		if (updateError && isDuplicateTitleError(updateError)) {
			// This is handled in the UI and doesn't need to be reported
			markErrorAsHandled(updateError);
		}
	}, [updateError]);

	const renderEditView: InlineEditProps<string>['editView'] = ({ errorMessage, ...fieldProps }) => {
		const error = !!(fieldProps.isInvalid || updateError);

		const renderElemAfterInput = () => {
			if (error) {
				return (
					<ErrorIconWrapper>
						<ErrorIcon color={token('color.icon.danger')} spacing="spacious" label="error" />
					</ErrorIconWrapper>
				);
			}

			return null;
		};

		const renderErrorMessage = () => {
			if (fieldProps.isInvalid) {
				return errorMessage;
			} else if (updateError && isDuplicateTitleError(updateError)) {
				return intl.formatMessage(
					i18nByContentType.duplicateTitleError[contentType] || i18n.duplicateTitleError,
				);
			} else {
				return intl.formatMessage(i18n.generalError);
			}
		};

		return (
			<EditViewContainer data-value={fieldProps.value}>
				<TextViewContainer
					errorIcon={error}
					isBoldedTitle={fg('confluence_modernized_breadcrumbs')}
				>
					<Popup
						autoFocus={false}
						isOpen={error}
						placement="bottom-start"
						content={() => <Box xcss={popupContainerStyles}>{renderErrorMessage()}</Box>}
						trigger={(triggerProps) => (
							<Textfield
								{...triggerProps}
								{...fieldProps}
								testId="content-types-title-input"
								width="auto"
								isCompact
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className="text-field-input"
								placeholder={placeholderText}
								autoComplete="off"
								onFocus={onFocus}
								autoFocus
								isDisabled={updateLoading}
								isInvalid={error}
								maxLength={MAX_VALID_BOARD_NAME_LENGTH + 1}
								elemAfterInput={renderElemAfterInput()}
							/>
						)}
					/>
				</TextViewContainer>
			</EditViewContainer>
		);
	};

	const renderReadView: InlineEditProps<string>['readView'] = () => {
		const permissionsMessage =
			i18nByContentType.permissionsTooltip[contentType] || i18n.permissionsTooltip;
		const message = readOnly ? permissionsMessage : i18n.renameTooltip;
		return (
			<Tooltip component={ToolTipOverride} content={<FormattedMessage {...message} />}>
				<Box
					xcss={[
						readOnlyContainerStyles,
						fg('confluence_modernized_breadcrumbs') && boldedReadOnlyContainerStyles,
					]}
				>
					{title}
				</Box>
			</Tooltip>
		);
	};

	const validateName = (value: string | undefined) => {
		if (!value) {
			return;
		}

		if (value.length > MAX_VALID_BOARD_NAME_LENGTH) {
			return intl.formatMessage(i18n.titleLengthError, {
				maxLength: MAX_VALID_BOARD_NAME_LENGTH,
			});
		}
	};

	const onTitleEdit = useCallback(() => {
		setIsEditing(true);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'contentTypeHeaderTitle',
				containerType: 'space',
				containerId: spaceId,
				objectId: contentId,
				source: 'contentTypesHeader',
			},
		}).fire();
	}, [setIsEditing, createAnalyticsEvent, contentId, spaceId]);

	const { setOptimisticTitle } = useOptimisticTitleChange(contentId, title);
	const onTitleUpdate = useCallback(
		async (value: string) => {
			await updateTitle(value);
			setOptimisticTitle(value);
			updateContentTree();
			showNextSpotlight();
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'updated',
					actionSubject: 'contentTypeHeaderTitle',
					containerType: 'space',
					containerId: spaceId,
					objectId: contentId,
					source: 'contentTypesHeader',
				},
			}).fire();
		},
		[
			updateTitle,
			createAnalyticsEvent,
			contentId,
			showNextSpotlight,
			spaceId,
			updateContentTree,
			setOptimisticTitle,
		],
	);

	const renderInlineEdit = () => (
		<InlineEdit
			isEditing={isEditing}
			onEdit={onTitleEdit}
			validate={validateName}
			onConfirm={onTitleUpdate}
			defaultValue={title}
			editView={renderEditView}
			readView={renderReadView}
			hideActionButtons
		/>
	);

	const renderReadOnlyTitle = () => <Box xcss={readOnlyTitleWrapperStyles}>{renderReadView()}</Box>;

	return (
		<>
			<PageSegmentLoadStart metric={CONTENT_TYPES_HEADER_TITLE_SEGMENT} />
			{updateError && <ErrorDisplay error={updateError} />}
			<DocumentContentTitle contentTitle={title} contentSpace={spaceName} />
			<Title>{readOnly ? renderReadOnlyTitle() : renderInlineEdit()}</Title>
			{isTitleSpotlightActive && (
				<Box xcss={spotlightWrapperStyles}>
					{/* Render a SpotlightCard instead of a Spotlight in order to leave the target interactive (for editing the title) */}
					<SpotlightCard key="whiteboard-onboarding-title-target" width={287}>
						<FormattedMessage {...i18n.onboardingSpotlightBody} />
					</SpotlightCard>
				</Box>
			)}
			<PageSegmentLoadEnd metric={CONTENT_TYPES_HEADER_TITLE_SEGMENT} />
		</>
	);
};
