import React from 'react';
import { styled } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';

import { fg } from '@confluence/feature-gating';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentTypesHeaderContainer = styled.div<{
	isFloating?: boolean;
	hasPointerEvents?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: (props) => {
		return props.isFloating ? 'absolute' : 'relative';
	},
	background: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: (props) => {
		return props.hasPointerEvents ? 'auto' : 'none';
	},
	width: '100%',
	display: 'grid',
});

const titleAndBreadcrumbsBaseStyles = xcss({
	pointerEvents: 'auto',
	backgroundColor: 'elevation.surface',
	borderRadius: '3px',
	paddingBlockStart: 'space.100',
	paddingInline: 'space.100',
	paddingBlockEnd: 'space.050',
});

// Utilized only when the content header is pinned and during the viewing of public whiteboards
const alignCenterStyle = xcss({
	alignSelf: 'center',
});

const modernizedBreadcrumbsStyles = xcss({
	display: 'flex',
	flexWrap: 'nowrap',
	paddingBlockStart: 'space.050',
	height: '100%',
});

export const TitleAndBreadcrumbsWrapper = ({
	isContentHeaderPinned = false,
	children,
}: {
	isContentHeaderPinned?: boolean;
	children: React.ReactNode;
}) => {
	return (
		<Box
			xcss={[
				titleAndBreadcrumbsBaseStyles,
				isContentHeaderPinned && alignCenterStyle,
				fg('confluence_modernized_breadcrumbs') && modernizedBreadcrumbsStyles,
			]}
		>
			{children}
		</Box>
	);
};
