import { useRef, useState, useEffect, useCallback } from 'react';

import type { CollabEditProvider } from '@atlaskit/collab-provider';
import type { PresenceData } from '@atlaskit/collab-provider/types';

import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';

/**
 * To avoid multiple people saving the draft simultaneously, which causes a lock exception,
 * we only want the first active user to be in charge of saving.
 * See issue CFE-1369 for details.
 */
const checkIsFirstParticipant = (
	participants: PresenceData[] | null,
	currentUserSessionId: string | null,
): boolean => {
	if (participants === null || participants.length === 0 || currentUserSessionId === null) {
		return true;
	}
	// Check if the first participant is the current user
	return participants[0].sessionId === currentUserSessionId;
};

/**
 * Verifies if the current user is the primary participant and has edit permissions.
 * @returns {boolean} True if the user is the first participant with edit permissions,
 *                    false if the user has view only or is not the first participant.
 */
const isPrimaryParticipantWithPermissions = (
	participants: PresenceData[] | null,
	currentUserSessionId: string | null,
): boolean => {
	if (participants === null || participants.length === 0 || currentUserSessionId === null) {
		return true;
	}

	// Find the first participant in the sorted list with edit permissions
	const primaryParticipant = participants.find(
		(activeUser) => activeUser.permit?.isPermittedToEdit,
	);

	return !!primaryParticipant && primaryParticipant.sessionId === currentUserSessionId;
};

const updatePresence = (
	presenceData: { joined: PresenceData[]; left: PresenceData[] },
	currentParticipants: PresenceData[] | null,
): PresenceData[] => {
	const { joined = [], left = [] } = presenceData;

	let updatedParticipants: PresenceData[] = [];
	if (currentParticipants === null) {
		updatedParticipants = joined;
	} else {
		// Add new participants to the list
		updatedParticipants = [...currentParticipants, ...joined];
		// Remove participants who have left
		updatedParticipants = updatedParticipants.filter(
			(participant) => !left.some((leftUser) => leftUser.sessionId === participant.sessionId),
		);
	}

	// Sort the session IDs to ensure the comparison for choosing the
	// draft sync leader is deterministic.
	updatedParticipants.sort((a, b) => a.sessionId.localeCompare(b.sessionId));
	return updatedParticipants;
};

export const usePresence = (
	collabEditProvider: CollabEditProvider | null,
): { isFirstParticipant: boolean; isSingleUserSession: boolean } => {
	const isLivePage = useIsCurrentPageLive();
	const currentUserSessionId = useRef<string | null>(null);
	const currentParticipants = useRef<PresenceData[] | null>(null);
	const [isFirstParticipant, setIsFirstParticipant] = useState<boolean>(true);
	const [isSingleUserSession, setIsSingleUserSession] = useState<boolean>(true);

	// Listen to the connected event to grab the current user's sessionId
	useEffect(() => {
		if (!collabEditProvider) {
			return;
		}
		const handleConnected = (event) => {
			currentUserSessionId.current = event?.sid;
		};
		collabEditProvider.on('connected', handleConnected);
		return () => {
			collabEditProvider.off('connected', handleConnected);
		};
	}, [collabEditProvider]);

	const handleUpdatePresence = useCallback(
		(presenceData: { joined: PresenceData[]; left: PresenceData[] }) => {
			const updatedParticipants = updatePresence(presenceData, currentParticipants.current);
			currentParticipants.current = updatedParticipants;

			const isFirst = isLivePage
				? isPrimaryParticipantWithPermissions(updatedParticipants, currentUserSessionId.current)
				: checkIsFirstParticipant(updatedParticipants, currentUserSessionId.current);

			setIsFirstParticipant(isFirst);
			setIsSingleUserSession(currentParticipants.current.length <= 1);
		},
		[isLivePage],
	);

	useEffect(() => {
		if (!collabEditProvider) {
			return;
		}
		collabEditProvider.on('presence', handleUpdatePresence);
		return () => {
			collabEditProvider.off('presence', handleUpdatePresence);
		};
	}, [collabEditProvider, handleUpdatePresence]);

	return { isFirstParticipant, isSingleUserSession };
};
