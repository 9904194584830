import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useMemo, useEffect } from 'react';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import type { ContentTypesHeaderQueryNode } from './ContentTypesHeader';
import type { LastEditedVersion } from './object-header-items/LastEdited';

type UseObjectHeaderVersionType = {
	contentData: ContentTypesHeaderQueryNode | null | undefined;
	useDraftVersion: boolean;
};

const useObjectHeaderVersionDraftQuery = gql`
	query useObjectHeaderVersionDraftQuery($contentId: ID!) {
		content(id: $contentId, status: ["draft"]) {
			nodes {
				version {
					contentTypeModified
					number
					when
					friendlyWhen
				}
			}
		}
	}
`;

const DEFAULT_VERSION: LastEditedVersion = {
	contentTypeModified: false,
	number: 1,
	when: '',
	friendlyWhen: '',
};

/**
 * Gets object header version data. If the context is a draft being displayed, it will override the version data with that of the draft.
 * This is a short-term workaround to COMMENTS-3658, before we have a field which can return draft version data in main shared `ContentTypesHeader` query.
 *
 * This approach is limited as it lazily loads the draft version data, due to Apollo caching issues and query limitations.
 * This will cause a flicker when the draft version is loaded after the initial render.
 */
export const useObjectHeaderVersion = ({
	contentData,
	useDraftVersion,
}: UseObjectHeaderVersionType): LastEditedVersion => {
	const {
		data: draftData,
		loading: draftDataLoading,
		error,
	} = useQuery(useObjectHeaderVersionDraftQuery, {
		variables: {
			contentId: contentData?.id,
		},
		fetchPolicy: 'no-cache', // Apollo cache is keyed by content id, not content status, so avoid interfering with cache data
		skip: !useDraftVersion,
	});

	useEffect(() => {
		if (error) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.COMMENTS,
			});
			markErrorAsHandled(error);
		}
	}, [error]);

	const version = useMemo<LastEditedVersion>(() => {
		// Always prefer the regular content version as a fallback
		const contentVersion: LastEditedVersion = {
			contentTypeModified: contentData?.version?.contentTypeModified ?? false,
			number: contentData?.version?.number ?? 1,
			when: contentData?.version?.when ?? '',
			friendlyWhen: contentData?.version?.friendlyWhen ?? '',
		};

		// If editing draft of classic page, override version with draft version
		if (useDraftVersion) {
			if (draftDataLoading) {
				return DEFAULT_VERSION;
			}

			const draftVersion = draftData?.content?.nodes?.[0]?.version;
			if (draftVersion) {
				return {
					contentTypeModified: draftVersion.contentTypeModified,
					number: draftVersion.number,
					when: draftVersion.when,
					friendlyWhen: draftVersion.friendlyWhen,
				};
			}
		}
		return contentVersion;
	}, [useDraftVersion, contentData, draftData, draftDataLoading]);

	return version;
};
