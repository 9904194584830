import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

import { RestrictionsDialogMenuPropsProvider } from '@confluence/restrictions';

const i18n = defineMessages({
	restrictionsButton: {
		id: 'content-types-header.content-types.share.restrictions-button',
		defaultMessage: 'Manage privacy and visibility',
		description: 'A button to open Restrictions modal',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogFooterContainer = styled.div({
	fontWeight: token('font.weight.semibold'),
	display: 'flex',
	alignItems: 'center',
	gap: token('space.150'),
});

type DialogFooterProps = {
	contentId: string;
};

export const DialogFooter: FC<DialogFooterProps> = ({ contentId }) => {
	const intl = useIntl();

	return (
		<DialogFooterContainer>
			<RestrictionsDialogMenuPropsProvider contentId={contentId}>
				{(itemProps) => (
					<Button
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ textDecoration: 'underline' }}
						spacing="none"
						appearance="subtle-link"
						{...itemProps}
					>
						{intl.formatMessage(i18n.restrictionsButton)}
					</Button>
				)}
			</RestrictionsDialogMenuPropsProvider>
		</DialogFooterContainer>
	);
};
