import { getApolloClient } from '@confluence/graphql';

import { GetDraftParentQuery } from './GetDraftParentQuery.graphql';

/**
 * Gets the contentId of the specified draft content's parent by looking in the GraphQL cache.
 * If it's unable to find the parent, returns undefined.
 */
export const getParentOfDraft = async (contentId: string): Promise<string | undefined> => {
	const apolloClient = getApolloClient();
	// Apollo's `readQuery` method will throw an error if ANY of the requested fields are missing.
	// We want to gracefully handle this and return `undefined`, so we use the try/catch block to swallow errors.
	let parentId = undefined;
	try {
		const draftParentQueryResult = await apolloClient.readQuery({
			query: GetDraftParentQuery,
			variables: {
				contentId,
			},
		});
		parentId = draftParentQueryResult?.content?.nodes?.[0]?.ancestors?.slice(-1)?.[0]?.id;
	} catch {}

	return parentId;
};
