import { fg } from '@confluence/feature-gating';

/**
 This function is used in the Content Types header to determine if version history is enabled
 for a specific content type (i.e whiteboards/databases and any future content types)
 */
export const useIsVersionHistoryEnabled = (contentType: string | null | undefined): boolean => {
	return (
		(contentType === 'whiteboard' && fg('confluence_frontend_whiteboards_version_history_ui')) ||
		(contentType === 'database' && fg('confluence_frontend_databases_version_history_ui'))
	);
};
