import type { ComponentProps } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import type { RestrictionsDataContainerConfig } from '../types';

export class RestrictionsDataContainer extends Container<ComponentProps<any>> {
	constructor({
		loadUuid = '',
		contentId = null,
		userCanEditRestrictions = false,
		currentUserInfo = null,
		hasInheritedRestrictions = false,
		hasRestrictions = false,
		isLoaded = false,
		restrictionsDetails = null,
		spaceKey = null,
		spaceContainsExternalCollaborators = false,
		externalCollaboratorCount = 0,
		operations = null,
		contentTitle = null,
		edition = null,
		isSiteAdmin = false,
		contentType = null,
		contentSubType = null,
		isExternalShareEnabledForSite = false,
		isPageExternallyShared = false,
		isExternalShareVariation = false,
		isContentExternallySharable = false,
		canEditPublicLink = false,
		ancestors = [],
	}: RestrictionsDataContainerConfig = {}) {
		super();

		this.state = {
			loadUuid,
			contentId,
			userCanEditRestrictions,
			//   currentUserInfo: PropTypes.shape({
			//     id: PropTypes.string.isRequired,
			//     displayName: PropTypes.string.isRequired,
			//     isSuperAdmin: PropTypes.bool,
			//     profilePicture: PropTypes.shape({
			//       path: PropTypes.string
			//     })
			//   }).isRequired,
			currentUserInfo,
			hasInheritedRestrictions,
			hasRestrictions,
			isLoaded,

			// const restrictionsShape = {
			//   restrictions: PropTypes.shape({
			//     user: PropTypes.shape({
			//       nodes: PropTypes.arrayOf(
			//         PropTypes.shape({
			//           accountId: PropTypes.string,
			//           displayName: PropTypes.string,
			//           type: PropTypes.string
			//         })
			//       )
			//     }),
			//     group: PropTypes.shape({
			//       nodes: PropTypes.arrayOf(
			//         PropTypes.shape({
			//           name: PropTypes.string
			//         })
			//       )
			//     })
			//   })
			// };
			restrictionsDetails,
			spaceKey,
			spaceContainsExternalCollaborators,
			externalCollaboratorCount,
			operations,
			contentTitle,
			edition,
			isSiteAdmin,
			contentType,
			contentSubType,
			isExternalShareEnabledForSite,
			isPageExternallyShared,
			isExternalShareVariation,
			isContentExternallySharable,
			canEditPublicLink,
			ancestors,
		};
	}

	getData = () => {
		return this.state;
	};

	resetData = () => {
		void this.setState({
			loadUuid: '',
			contentId: null,
			contentPermissions: null,
			currentUserInfo: null,
			hasInheritedRestrictions: false,
			hasRestrictions: false,
			isLoaded: false,
			restrictionsDetails: null,
			spaceKey: null,
			spaceContainsExternalCollaborators: false,
			externalCollaboratorCount: 0,
			operations: null,
			contentTitle: null,
			edition: null,
			isSiteAdmin: false,
			contentType: null,
			contentSubType: null,
			contentStatus: undefined,
			isExternalShareEnabledForSite: false,
			isPageExternallyShared: false,
			isExternalShareVariation: false,
			isContentExternallySharable: false,
			canEditPublicLink: false,
			ancestors: [],
		});
	};

	setData = (data) => {
		if (Object.keys(data).some((k) => data[k] !== this.state[k])) {
			void this.setState(data);
		}
	};

	getIsLoaded = () => {
		return this.state.isLoaded;
	};

	getContentId = () => {
		return this.state.contentId;
	};

	getLoadUuid = () => {
		return this.state.loadUuid;
	};
}
