import { LoadableAfterPaint } from '@confluence/loadable';

export const GlobalFeedbackCollector = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GlobalFeedbackCollector" */ './GlobalFeedbackCollector'
			)
		).GlobalFeedbackCollector,
});

export type { GlobalFeedbackCollectorComponentProps } from './GlobalFeedbackCollector';
export { FeedbackSource } from './feedbackSourceType';
