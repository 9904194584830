// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import type { EmojiDescription, OptionalEmojiDescription } from '@atlaskit/emoji/types';

export class EmojiTitleStateContainer extends Container<EmojiTitleState> {
	state = {
		emojiPickerLoaded: false,
		isLoading: true,
		isMouseEnterTitle: false,
		isUpdatingEmoji: false,
		openFirstEmojiPicker: false,
		openSecondEmojiPicker: false,
		selectedEmoji: null,
		isEmojiButtonFocused: false,
		isRemovingEmoji: false,
	};

	onUpdateStart = () => this.setState({ isUpdatingEmoji: true });
	onUpdateEnd = () => {
		void this.setState({ isUpdatingEmoji: false });
		void this.setState({ isRemovingEmoji: false });
	};
	setIsEmojiButtonFocused = (isEmojiButtonFocused: boolean) =>
		this.setState({ isEmojiButtonFocused });

	setIsRemovingEmoji = (isRemovingEmoji: boolean) => this.setState({ isRemovingEmoji });

	// first emoji picker is open when user already selected an emoji
	// click on the emoji and want to change it to different emoji
	setOpenFirstEmojiPicker = (isOpen: boolean) => {
		if (this.state.openFirstEmojiPicker !== isOpen) {
			void this.setState({ openFirstEmojiPicker: isOpen });
		}
	};

	// second emoji picker is open when not yet selected an emoji
	// and want to add emoji via Add emoji button
	setOpenSecondEmojiPicker = (isOpen: boolean) => {
		if (this.state.openSecondEmojiPicker !== isOpen) {
			void this.setState({ openSecondEmojiPicker: isOpen });
		}
	};

	setSelectedEmoji = (emoji: OptionalEmojiDescription | EmojiDescription | null) =>
		this.setState({ selectedEmoji: emoji });

	onEmojiPickerLoaded = () => {
		if (!this.state.emojiPickerLoaded) {
			void this.setState({ emojiPickerLoaded: true });
		}
	};

	// for testing
	setContainerState = (newState: Partial<EmojiTitleState>) =>
		this.setState((state) => ({
			...state,
			...newState,
		}));

	setLoadingEmoji = (isLoading: EmojiTitleState['isLoading']) => this.setState({ isLoading });
}

type EmojiTitleState = {
	emojiPickerLoaded: boolean;
	isLoading: boolean;
	isMouseEnterTitle: boolean;
	isUpdatingEmoji: boolean;
	isEmojiButtonFocused: boolean;
	openFirstEmojiPicker: boolean;
	openSecondEmojiPicker: boolean;
	selectedEmoji: OptionalEmojiDescription | EmojiDescription | null;
	isRemovingEmoji: boolean;
};
