import type { FC, MouseEvent } from 'react';
import React, { useState, useEffect, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Popup from '@atlaskit/popup';
import Button from '@atlaskit/button';
import Textfield from '@atlaskit/textfield';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ErrorDisplay } from '@confluence/error-boundary';

const i18n = defineMessages({
	linkFieldLabel: {
		id: 'content-types-header.content-types.share.link-field-label',
		defaultMessage: 'share link text field',
		description: 'A label for field containing the link to share',
	},
	copyButton: {
		id: 'content-types-header.content-types.share.copy-button',
		defaultMessage: 'Copy link',
		description: 'Text of the button that allows users to copy the page link to clipboard',
	},
	copiedText: {
		id: 'content-types-header.content-types.share.copied-text',
		defaultMessage: 'Copied link to clipboard!',
		description: 'Content of the popup that appears when the link is copied to clipboard',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogBodyContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050'),
});

const popupContentStyles = xcss({
	padding: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentTriggerContainer = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: '37px !important',
		margin: token('space.050'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		paddingTop: `${token('space.025')} !important`,
	},
});

type DialogBodyType = {
	contentId?: string;
	spaceId?: string;
};

export const DialogBody: FC<DialogBodyType> = ({ contentId, spaceId }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [copyError, setCopyError] = useState<Error | undefined>();
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// eslint-disable-next-line no-restricted-syntax
	const shareUrl = window.location.href;

	const copyToClipboard = useCallback(
		(e: MouseEvent<HTMLElement>) => {
			navigator.clipboard.writeText(shareUrl).catch(setCopyError);
			setIsOpen(true);
			e.stopPropagation();

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'copyShareLink',
					containerType: 'space',
					containerId: spaceId,
					objectId: contentId,
					source: 'contentTypesHeader',
					attributes: {
						source: 'contentTypeShareModal',
					},
				},
			}).fire();
		},
		[shareUrl, contentId, spaceId, createAnalyticsEvent],
	);

	useEffect(() => {
		const timer = setTimeout(() => setIsOpen(false), 4000);
		return () => clearTimeout(timer);
	}, [isOpen]);

	const contentTrigger = useCallback(
		(triggerProps: any) => (
			<ContentTriggerContainer {...triggerProps}>
				<Button onClick={copyToClipboard}>{intl.formatMessage(i18n.copyButton)}</Button>
			</ContentTriggerContainer>
		),
		[copyToClipboard, intl],
	);

	return (
		<DialogBodyContainer>
			<Textfield
				value={shareUrl}
				name="shareLink"
				aria-label={intl.formatMessage(i18n.linkFieldLabel)}
				isReadOnly
			/>
			<Popup
				isOpen={isOpen}
				placement="bottom"
				content={() => <Box xcss={popupContentStyles}>{intl.formatMessage(i18n.copiedText)}</Box>}
				zIndex={1000}
				trigger={contentTrigger}
			/>
			{copyError && <ErrorDisplay error={copyError} />}
		</DialogBodyContainer>
	);
};
