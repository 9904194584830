import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { useThemeObserver } from '@atlaskit/tokens';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';

import type { WhiteboardAIAgentServiceInterface } from '@confluence/whiteboard-types/entry-points/WhiteboardAIAgentService';

import WhiteboardTeammateLight from '../assets/WhiteboardTeammateLight.svg';
import WhiteboardTeammateDark from '../assets/WhiteboardTeammateDark.svg';

const i18n = defineMessages({
	whiteboardTeammate: {
		id: 'content-types-header.content-types-actions.whiteboards.whiteboard-teammate-button.name',
		defaultMessage: 'Whiteboard Agent',
		description:
			'Name of the whiteboard teammate button that appears in the content types header of confluence and opens the ai agent dialog',
	},
	whiteboardAgentBetaLozenge: {
		id: 'content-types-header.content-types-actions.whiteboards.whiteboard-teammate-button.whiteboard-agent-beta-lozenge',
		defaultMessage: 'Beta',
		description:
			'Text and label for the beta lozenge that appears at the end of the whiteboard agent button',
	},
});

const buttonWrapperStyles = xcss({
	display: 'inline-block',
	border: '1px solid',
	borderColor: 'color.border',
	borderRadius: '4px',
});

// This is odd but since the image icon is intentionally too large for a standard icon button
// we need to offset it and make it absolute so it doesn't get clipped by the button overflow
const buttonIconStyles = xcss({
	width: '24px',
	height: '24px',
	position: 'absolute',
	top: '50%',
	left: '12px',
	transform: 'translateY(-50%)',
});

const buttonContentStyles = xcss({
	paddingLeft: 'space.400',
});

export type WhiteboardTeammateButtonProps = {
	whiteboardAIAgentService?: WhiteboardAIAgentServiceInterface;
};

export const WhiteboardTeammateButton = ({
	whiteboardAIAgentService,
}: WhiteboardTeammateButtonProps) => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const buttonRef = useRef<HTMLDivElement>(null);
	const Icon = colorMode === 'dark' ? WhiteboardTeammateDark : WhiteboardTeammateLight;
	const buttonLabel = formatMessage(i18n.whiteboardTeammate);

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (whiteboardAIAgentService) {
			whiteboardAIAgentService.setAIAgentButtonRef(buttonRef);
			whiteboardAIAgentService.onUpdateAIAgentDialogState((state) => {
				setIsOpen(state.isOpen);
			});
		}
	}, [whiteboardAIAgentService]);

	if (!whiteboardAIAgentService) {
		return null;
	}

	return (
		<Box ref={buttonRef} xcss={buttonWrapperStyles}>
			<Button
				appearance="subtle"
				onClick={() => whiteboardAIAgentService.toggleAIAgentDialog()}
				isSelected={isOpen}
				aria-label={buttonLabel}
				// aria-pressed does not appear to be getting set on new buttons
				testId={`whiteboard-teammate-button-${isOpen ? 'open' : 'closed'}`}
			>
				<Box xcss={buttonIconStyles}>
					<img src={Icon} alt="" data-testid="whiteboard-teammate-icon" />
				</Box>
				<Inline space="space.050" alignBlock="center">
					<Box xcss={buttonContentStyles}>{buttonLabel}</Box>
					<Lozenge isBold>{formatMessage(i18n.whiteboardAgentBetaLozenge)}</Lozenge>
				</Inline>
			</Button>
		</Box>
	);
};
