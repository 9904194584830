// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

import { COVERPICTUREWIDTH } from '@confluence/content-topper';

import type { UnsplashPhoto } from './EditorCoverPicture/unsplashAPI';

type CoverPictureStateContainerType = {
	hasCoverPicture: boolean;
	isUploadingPicture: boolean;
	isModalOpen: boolean;
	isRemovingPicture: boolean;
	isRepositioningPicture: boolean;
	isAddHeaderImageButtonFocused: boolean;
	coverPictureId: string;
	shouldShowReposition: boolean;
	isChangingPicture: boolean;
	coverPictureWidth: COVERPICTUREWIDTH;
	isAIUnsplashImage: boolean;
	shouldShowAIUnsplashHeaderToolbar: boolean;
	aiSuggestedUnsplashImages?: UnsplashPhoto[];
};
export class CoverPictureStateContainer extends Container<CoverPictureStateContainerType> {
	state = {
		hasCoverPicture: false,
		isUploadingPicture: false,
		isModalOpen: false,
		isRemovingPicture: false,
		isRepositioningPicture: false,
		isAddHeaderImageButtonFocused: false,
		coverPictureId: '',
		shouldShowReposition: false,
		isChangingPicture: false,
		coverPictureWidth: COVERPICTUREWIDTH.FULL,
		isAIUnsplashImage: false,
		aiSuggestedUnsplashImages: undefined,
		shouldShowAIUnsplashHeaderToolbar: false,
	};

	setHasCoverPicture = (hasCoverPicture) => this.setState({ hasCoverPicture });
	toggleCoverPictureWidthButton = (coverPictureWidth: COVERPICTUREWIDTH) =>
		this.setState({ coverPictureWidth });
	setCoverPictureId = (coverPictureId) => this.setState({ coverPictureId });
	setIsUploadingPicture = (isUploadingPicture) => this.setState({ isUploadingPicture });
	setIsModalOpen = (isModalOpen) => this.setState({ isModalOpen });
	setIsChangingPicture = (isChangingPicture) => this.setState({ isChangingPicture });
	setIsRemovingPicture = (isRemovingPicture) => this.setState({ isRemovingPicture });
	setIsRepositioningPicture = (isRepositioningPicture) => this.setState({ isRepositioningPicture });
	setShouldShowReposition = (shouldShowReposition) => this.setState({ shouldShowReposition });
	setIsAddHeaderImageButtonFocused = (isAddHeaderImageButtonFocused) =>
		this.setState({ isAddHeaderImageButtonFocused });
	setIsAIUnsplashImage = (isAIUnsplashImage) => this.setState({ isAIUnsplashImage });
	setAISuggestedUnsplashImages = (aiSuggestedUnsplashImages) =>
		this.setState({ aiSuggestedUnsplashImages });
	setShouldShowAIUnsplashHeaderToolbar = (shouldShowAIUnsplashHeaderToolbar) =>
		this.setState({ shouldShowAIUnsplashHeaderToolbar });
	resetToDefaults = () => {
		void this.setIsAddHeaderImageButtonFocused(false);
		void this.setIsUploadingPicture(false);
		void this.setIsChangingPicture(false);
		void this.setIsRemovingPicture(false);
		void this.setShouldShowReposition(false);
		void this.toggleCoverPictureWidthButton(COVERPICTUREWIDTH.FULL);
		void this.setIsAIUnsplashImage(false);
		void this.setAISuggestedUnsplashImages(undefined);
		void this.setShouldShowAIUnsplashHeaderToolbar(false);
	};
}
