import React, { createContext } from 'react';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';

import type { EditorActions } from '@atlaskit/editor-core';

import type { TriggerAnalyticsEventType } from '@confluence/editor-features';
import type { RetrieveEditorTitleAndContent } from '@confluence/editor-features/entry-points/useRetrieveEditorTitleAndContent';

type DefaultNoop = () => void;
type EmptyEditorActions = {
	isDocumentEmpty: DefaultNoop;
};

const defaultRetrieveEditorTitleAndContent: RetrieveEditorTitleAndContent = () =>
	Promise.resolve({
		title: '',
		adf: { type: '' },
	});

export type EditorObjectHeaderContextType = {
	saveDraft: (arg?: any) => Promise<void>;
	navigateOnClose: ((args?: { isDiscarding?: boolean }) => void) | DefaultNoop;
	triggerAnalyticsEvent: TriggerAnalyticsEventType | DefaultNoop;
	version: number;
	editorActions: EditorActions | EmptyEditorActions;
	title: string | null;
	onClickOpenContentCreationPanel?: () => void;
	isContentCreationPanelOpen?: boolean;
	retrieveEditorTitleAndContent: RetrieveEditorTitleAndContent;
	showRevertDialog?: () => void;
	onPreviewClicked: (...args: any[]) => void;
	hasEditPermission?: boolean;
	isPublishingContent: boolean;
	userOffline: boolean;
	forceDisabled?: boolean;
	editorTitle: string | null;
	collabLimitReached: boolean;
	collabEditConnected: boolean;
	onClickPublish: ((arg: {}) => () => void) | DefaultNoop;
	shouldRenderPreview: boolean;
	isUnpublishedDraft: boolean;
	validatePageAndOpenModal?: (actionSubjectId: string) => () => Promise<void>;
	canUndo?: boolean;
	canRedo?: boolean;
	undo?: () => boolean;
	redo?: () => boolean;
	activateFindReplace?: () => boolean;
};

export type EditorObjectHeaderContextProviderProps = {
	value: EditorObjectHeaderContextType;
	children?: React.ReactNode;
};

const defaultEditorObjectHeaderContext: EditorObjectHeaderContextType = {
	saveDraft: () => Promise.resolve(),
	navigateOnClose: () => {},
	triggerAnalyticsEvent: () => {},
	version: 0,
	editorActions: {
		isDocumentEmpty: () => {},
	},
	title: '',
	retrieveEditorTitleAndContent: defaultRetrieveEditorTitleAndContent,
	onPreviewClicked: () => {},
	isPublishingContent: false,
	userOffline: false,
	editorTitle: null,
	collabLimitReached: false,
	collabEditConnected: false,
	onClickPublish: () => {},
	shouldRenderPreview: false,
	isUnpublishedDraft: false,
};

const EditorObjectHeaderContext = createContext<EditorObjectHeaderContextType>(
	defaultEditorObjectHeaderContext,
);

const memoizeDeepEq = memoizeOne(<T,>(obj: T) => obj, isEqual);

export const EditorObjectHeaderContextProvider = ({
	children,
	value,
}: EditorObjectHeaderContextProviderProps) => {
	const memoizedValue = memoizeDeepEq(value);
	return (
		<EditorObjectHeaderContext.Provider value={memoizedValue}>
			{children}
		</EditorObjectHeaderContext.Provider>
	);
};

export const useEditorObjectHeaderContext = (): EditorObjectHeaderContextType => {
	const context = React.useContext(EditorObjectHeaderContext);
	if (!context) {
		throw new Error(
			'useEditorObjectHeaderContext must be used within a EditorObjectHeaderContextProvider, and a `value` must be provided to EditorObjectHeaderContextProvider',
		);
	}
	return context;
};
