import {
	createPageSegmentLoadMetric,
	CONTENT_TYPES_HEADER_TITLE_SEGMENT_KEY,
	DATABASE_HEADER_SEGMENT_METRIC_KEY,
	SMART_LINK_EMBED_HEADER_SEGMENT_METRIC_KEY,
	WHITEBOARD_HEADER_SEGMENT_METRIC_KEY,
} from '@confluence/browser-metrics';
import { RESTRICTIONS_BUTTON_METRIC } from '@confluence/restrictions/entry-points/pageSegmentLoadMetrics';
import { FAVOURITE_BUTTON_METRIC } from '@confluence/favourite-button/entry-points/pageSegmentLoadMetrics';
import { SHARE_BUTTON_PAGE_SEGMENT_LOAD } from '@confluence/share/entry-points/pageSegmentLoadMetrics';

export const CONTENT_TYPES_HEADER_TITLE_SEGMENT = createPageSegmentLoadMetric({
	key: CONTENT_TYPES_HEADER_TITLE_SEGMENT_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
});

const CONTENT_TYPES_HEADER_COMMON_SUBSEGMENT_METRICS = [
	CONTENT_TYPES_HEADER_TITLE_SEGMENT,
	FAVOURITE_BUTTON_METRIC,
	RESTRICTIONS_BUTTON_METRIC,
];

export const DATABASE_HEADER_SUBSEGMENT_METRICS = [
	...CONTENT_TYPES_HEADER_COMMON_SUBSEGMENT_METRICS,
];
export const DATABASE_HEADER_SEGMENT_METRIC = createPageSegmentLoadMetric({
	key: DATABASE_HEADER_SEGMENT_METRIC_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
	until: DATABASE_HEADER_SUBSEGMENT_METRICS,
});

export const SMART_LINK_EMBED_HEADER_SUBSEGMENT_METRICS = [
	...CONTENT_TYPES_HEADER_COMMON_SUBSEGMENT_METRICS,
	SHARE_BUTTON_PAGE_SEGMENT_LOAD,
];
export const SMART_LINK_EMBED_HEADER_SEGMENT_METRIC = createPageSegmentLoadMetric({
	key: SMART_LINK_EMBED_HEADER_SEGMENT_METRIC_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
	until: SMART_LINK_EMBED_HEADER_SUBSEGMENT_METRICS,
});

// TODO: Add WATCH_BUTTON_METRIC from @confluence/watch-dialog/entry-points/pageSegmentLoadMetrics back
// here once we finish rolling it out
export const WHITEBOARD_HEADER_SUBSEGMENT_METRICS = [
	...CONTENT_TYPES_HEADER_COMMON_SUBSEGMENT_METRICS,
	SHARE_BUTTON_PAGE_SEGMENT_LOAD,
];
export const WHITEBOARD_HEADER_SEGMENT_METRIC = createPageSegmentLoadMetric({
	key: WHITEBOARD_HEADER_SEGMENT_METRIC_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
	until: WHITEBOARD_HEADER_SUBSEGMENT_METRICS,
});
