import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { fg } from '@confluence/feature-gating';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import { PublicLinkPermissionsForObjectQuery } from '../graphql/PublicLinkPermissionsForObjectQuery.graphql';
import {
	type PublicLinkPermissionsForObject as PublicLinkPermissionsForObjectQueryType,
	type PublicLinkPermissionsForObjectVariables,
	PublicLinkPermissionsObjectType,
	PublicLinkPermissionsType,
} from '../graphql/__types__/PublicLinkPermissionsForObjectQuery';

export type PublicLinkPermissionsForObjectData = {
	hasEditPermission: boolean;
	permissions: PublicLinkPermissionsType[];
};

export type PublicLinkPermissionsForObjectQueryData = {
	isLoading: boolean;
	refetch: () => void;
	error?: Error;
	data?: PublicLinkPermissionsForObjectData;
};

export const usePublicLinkPermissionsForObject = ({
	objectId,
	objectType = PublicLinkPermissionsObjectType.CONTENT,
	skip,
}: {
	objectId: string;
	objectType?: PublicLinkPermissionsObjectType;
	skip?: boolean;
}): PublicLinkPermissionsForObjectQueryData => {
	const { loading, error, data, refetch } = useQuery<
		PublicLinkPermissionsForObjectQueryType,
		PublicLinkPermissionsForObjectVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PublicLinkPermissionsForObjectQuery,
		{
			variables: {
				objectId,
				objectType,
			},
			skip: skip || !fg('public_links_whiteboard_edit'),
		},
	);

	useEffect(() => {
		if (isUnauthorizedError(error)) markErrorAsHandled(error);
	}, [error]);

	const hasEditPermission: boolean =
		data?.publicLinkPermissionsForObject?.permissions.includes(PublicLinkPermissionsType.EDIT) ??
		false;

	const queryRefetch = fg('public_links_whiteboard_edit') ? refetch : () => {};

	return loading
		? { isLoading: true, refetch: queryRefetch }
		: {
				isLoading: false,
				error,
				refetch: queryRefetch,
				data: error
					? undefined
					: {
							hasEditPermission,
							permissions: data?.publicLinkPermissionsForObject?.permissions ?? [],
						},
			};
};
