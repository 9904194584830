/* eslint-disable check-file/filename-blocklist */
import type { ADNode } from '@atlaskit/editor-common/validator';

import type { ContentTypeType, PublishProps } from '@confluence/editor-features/entry-points/types';
import type { useWebItemLocationWebItemType as WebItem } from '@confluence/web-item-location';
import type { WithFlagsProps } from '@confluence/flags';

type RetrieveEditorTitleAndContent = () => Promise<{
	title?: string | null | undefined;
	adf: ADNode;
	syncRev?: string;
}>;

export type EllipsisDropdownComponentProps = EllipsisDropdownContainerPropsFromState &
	EllipsisDropdownContainerOwnProps &
	WithFlagsProps & {
		/**
		 * Callback function for clicking Revert/Delete button
		 */
		showRevertDialog: () => void;
	};

export type EllipsisDropdownContainerPropsFromState = {
	/**
	 * The type of the content using confluence values for contentType
	 */
	contentType: ContentTypeType | null;
	/**
	 * Flag which identifies whether we are editing a never published draft
	 */
	isUnpublishedDraft: boolean | null;
	contentId: string | undefined;
	isShowingLabelsDialog: boolean;
};

export enum Buttons {
	DELETE_DRAFT = 'delete-draft',
	EDIT_LABELS = 'edit-labels',
	MOVE_PAGE = 'move-page',
	PREVIEW = 'preview',
	PUBLISH_WITHOUT_NOTIFY = 'publish-without-notify',
	RESOLVED_COMMENTS = 'resolved-comments',
	TEMPLATE_BROWSER = 'template-browser',
	VIEW_CHANGES = 'view-changes',
	EXPORT = 'export',
	ADVANCED = 'advanced',
	ARCHIVE = 'archive',
	ATTACHMENTS = 'attachments',
	SWITCH_TO_LIVE_EDIT = 'switch-to-live-edit',
	CLASSIFY_CONTENT = 'classify-content',
}

export type EllipsisDropdownContainerOwnProps = {
	/**
	 * Callback function for clicking Preview button
	 */
	onPreviewClicked: (boolean) => void;
	saveDraft?: (arg?: any) => Promise<void>;
	onClickPublish: (arg: PublishProps) => () => void;
	/**
	 * Used to control publishing a page to a blogpost
	 * Sets/unsets the disabled/loading state of the publish button
	 */
	retrieveEditorTitleAndContent: RetrieveEditorTitleAndContent;
	/**
	 * Is the dropdown is disabled (we're publishing, or something's gone wrong?)
	 */
	isDisabled?: boolean;
	/**
	 * A key to indicate a reason why main buttons are disabled.
	 */
	tooltipDisabledState: string;
	spaceKey: string;
	spaceId?: string;

	webItems?: WebItem[];
	/**
	 * Current state of the editor panel
	 */
	isContentCreationPanelShown: boolean;
	/**
	 * props to re-open the in-editor panel
	 */
	onClickOpenContentCreationPanel: () => void;
	/**
	 * when undefined: all groups and buttons are shown
	 */
	allowedEllipsisButtons?: Buttons[];
	isImportDisabled?: boolean;
};

export type LivePagesEllipsisDropdownComponentProps = {
	contentId: string;
	spaceKey: string;
	isViewMode?: boolean;
	retrieveEditorTitleAndContent: RetrieveEditorTitleAndContent;
	allowedEllipsisButtons?: Buttons[];
	onClickPublish: (arg: PublishProps) => () => void;
	saveDraft?: () => void;
	onClickOpenContentCreationPanel?: () => void;
	isInitialMenuOpen?: boolean;
	isDisabled?: boolean;
};

export type CommonDropdownMenuProps = {
	isMenuOpen: boolean;
	handleMenuOpenChange: ({ isOpen }: { isOpen: boolean }) => void;
	children: React.ReactNode;
	testId?: string;
	isDisabled?: boolean;
	tooltipDisabledState?: string;
};
