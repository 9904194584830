import { LoadableLazy } from '@confluence/loadable';

export {
	LIVE_DOC_MODES_TOGGLE_TARGET,
	LIVE_DOC_SHARE_BUTTON_TARGET,
} from '../src/LiveDocSpotlightsTourComponent';

export const LiveDocSpotlightsTour = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LiveDocSpotlightsTour" */ '../src/LiveDocSpotlightsTour'
			)
		).LiveDocSpotlightsTour,
});

export { LiveDocSpotlightsTourManager } from '../src/LiveDocSpotlightsTourManager';
