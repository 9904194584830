import React from 'react';
import uuid from 'uuid/v4';

import { DropdownItem } from '@atlaskit/dropdown-menu';
import PageLiveDocIcon from '@atlaskit/icon-lab/core/page-live-doc';

import { useSwitchToLivePage } from '@confluence/live-pages-features/entry-points/useSwitchToLivePage';
import type { UseSwitchToLivePageProps } from '@confluence/live-pages-features/entry-points/useSwitchToLivePage';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { VIEW_PAGE, FORCE_TRANSITION_QUERY_PARAM } from '@confluence/named-routes';

import { useEditorObjectHeaderContext } from '../../EditorObjectHeaderContext';

export const ConvertToLivePageItem = ({
	item,
	contentMode,
	spaceId,
	flags,
	spaceKey,
	contentId,
	closeParentMenu,
}) => {
	const { retrieveEditorTitleAndContent, saveDraft } = useEditorObjectHeaderContext();
	const { push } = useRouteActions();

	const useSwitchToLivePagesProps =
		contentMode === 'view'
			? {
					contentId,
					spaceId,
					isUnpublishedDraft: false,
					flags,
					analyticsSource: 'viewPageScreen',
					adfOrigin: 'RENDERER',
					onSuccessfulConversion: () => {
						push(
							VIEW_PAGE.toUrl(
								{
									spaceKey,
									contentId,
								},
								// do not remove this query param. It is used to force a transition since the route is the same
								// and we need to force a transition to avoid using SSR data.
								{ query: { [FORCE_TRANSITION_QUERY_PARAM]: uuid() } },
							),
						);
					},
					retrieveEditorTitleAndContent: undefined,
				}
			: {
					contentId,
					spaceId,
					isUnpublishedDraft: contentMode === 'draft',
					flags,
					retrieveEditorTitleAndContent,
					saveDraft,
					analyticsSource: 'ellipsisDropdownComponent',
					adfOrigin: 'EDITOR',
					onSuccessfulConversion: () => {
						// Redirect to the route for the new live page.
						push(
							VIEW_PAGE.toUrl({
								spaceKey,
								contentId,
							}),
						);
					},
				};

	const switchToLivePage = useSwitchToLivePage(
		useSwitchToLivePagesProps as UseSwitchToLivePageProps,
	);

	return (
		<DropdownItem
			key={item.id}
			onClick={() => {
				closeParentMenu();
				switchToLivePage();
			}}
			elemBefore={<PageLiveDocIcon label="" />}
		>
			{item.label}
		</DropdownItem>
	);
};
