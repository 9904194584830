import type { FC, MouseEvent, KeyboardEvent } from 'react';
import React from 'react';
import { useQuery } from 'react-apollo';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { DropdownItem } from '@atlaskit/dropdown-menu';

import { useSessionData } from '@confluence/session-data';
import { ErrorDisplay, Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { getShortcutString } from '@confluence/shortcuts';
import { PageStar, StarIcon } from '@confluence/action-buttons/entry-points/pageStar';
import { fg } from '@confluence/feature-gating';

import { FavouriteButtonQuery } from './FavouriteButtonQuery.graphql';
import type {
	FavouriteButtonQuery as FavouriteButtonQueryType,
	FavouriteButtonQueryVariables,
} from './__types__/FavouriteButtonQuery';
import { getStarShortcut } from './FavouriteContentShortcutListener';

type FavouriteMenuItemProps = {
	contentId: string;
	contentTitle: string;
	contentMode: string;
};

const shortcutStyles = xcss({
	backgroundColor: 'color.background.neutral',
	color: 'color.text.accent.gray',
	paddingBlock: 'space.025',
	paddingInline: 'space.075',
	borderRadius: '2px',
	margin: 'space.025',
});

// This component is a dropdown menu item version of the favorite page star button. It is completely standalone and queries for it's own isFavourited data.
// It does not include any shortcut listener and should be used in conjunction with FavouriteContentShortcutListener.tsx if you need to add a listener.
// It is currently used in the page header more actions menu item
export const FavouriteMenuItem: FC<FavouriteMenuItemProps> = ({
	contentId,
	contentTitle,
	contentMode,
}) => {
	const { isLoggedIn } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { data, error } = useQuery<FavouriteButtonQueryType, FavouriteButtonQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		FavouriteButtonQuery,
		{
			variables: {
				contentId,
			},
			fetchPolicy: 'cache-first',
		},
	);

	if (error) {
		return <ErrorDisplay error={error} />;
	}

	const isStarred = Boolean(data?.singleContent?.metadata.currentuser?.favourited?.isFavourite);
	const contentType = data?.singleContent?.type;
	const shortcut = getStarShortcut(contentType || '', contentMode);
	const handleClick = (toggleStar) => (e: MouseEvent<Element> | KeyboardEvent<Element>) => {
		toggleStar(e);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'pageStar',
				attributes: {
					triggeredByKeyboardShortcut: false,
					action: isStarred ? 'unstar' : 'star',
					isInEditor: contentMode === 'live' || contentMode === 'edit' || contentMode === 'draft',
					isContentWrapper: fg('confluence_frontend_object_header'), // for now, this will always be true since FavouriteMenuItem is only used in Content Wrapper as of now
				},
				source: 'FavouriteMoreActionsMenuItem',
			},
		}).fire();
	};

	return (
		<ErrorBoundary attribution={Attribution.DISCO}>
			<PageStar
				contentId={contentId}
				isStarred={isStarred}
				contentTitle={contentTitle}
				analytics={{
					source: 'FavouriteMoreActionsMenuItem',
					attributes: {
						componentVersion: 'more-actions-menu-item',
						pageType: contentType,
					},
				}}
				shouldShowFlags
			>
				{({ toggle: toggleStar }) => (
					<DropdownItem
						onClick={handleClick(toggleStar)}
						elemBefore={<StarIcon isStarred={isStarred} spacing="none" />}
						elemAfter={
							shortcut &&
							getShortcutString(shortcut, undefined, true)
								.split(' ')
								.map((key) => (
									<Box key={`shortcut-${key}`} xcss={shortcutStyles}>
										{key}
									</Box>
								))
						}
						isDisabled={!isLoggedIn}
					>
						{isStarred ? (
							<FormattedMessage {...i18n.unstar} />
						) : (
							<FormattedMessage {...i18n.star} />
						)}
					</DropdownItem>
				)}
			</PageStar>
		</ErrorBoundary>
	);
};

const i18n = defineMessages({
	star: {
		id: 'favourite-button.content.star.label',
		defaultMessage: 'Star',
		description:
			'Label for unfilled star menu item which indicates that the content is unstarred & stars the content when clicked',
	},
	unstar: {
		id: 'favourite-button.content.unstar.label',
		defaultMessage: 'Unstar',
		description:
			'Label for filled star menu item which indicates that the content is unstarred & stars the content when clicked',
	},
});
