import React, { Fragment, useEffect, useRef } from 'react';
import type { Dispatch, FC, ReactNode } from 'react';
import { styled } from '@compiled/react';
import { useQuery } from 'react-apollo';
import { defineMessages, useIntl } from 'react-intl-next';
import { Subscribe } from 'unstated';

import { SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { Inline, Box, xcss, Flex } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import type { CollabEditOptions } from '@atlaskit/editor-common/collab';

import type { PageSegmentLoadMetric } from '@atlassian/browser-metrics/types';

import { shouldShowMobileWeb } from '@confluence/mobile-detection';
import {
	BREADCRUMBS_MODES,
	BreadcrumbsNonLoadable,
	buildContentPath,
} from '@confluence/breadcrumbs';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import {
	ContentTypeClassification,
	ClassificationEditModal,
	EditorClassificationTagWrapper,
	useIsDataClassificationEnabledForOrg,
	useShouldRenderEditorActionBarClassificationPlaceholder,
} from '@confluence/data-classification';
import { ClassificationModalContextProvider } from '@confluence/data-classification/entry-points/classificationModalContext';
import { useContentClassification } from '@confluence/data-classification/entry-points/queries';
import {
	useIsClassificationEnabledForContentTypeExpansion,
	useGetClassificationTagUIVariant,
} from '@confluence/data-classification/entry-points/hooks';
import { ErrorBoundary, Attribution, ErrorDisplay } from '@confluence/error-boundary';
import { isExternalShareRequest } from '@confluence/external-share-utils';
import {
	VALID_OPERATION_TARGETS,
	useIsNewShareEnabled,
	isEmojiTitleEnabled,
	getContentMode,
} from '@confluence/content-types-utils';
import type { ContentType } from '@confluence/content-types-utils';
import {
	ExperienceSuccess,
	CONTENT_TYPES_HEADER_EXPERIENCE,
	VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE,
	CONTENT_TYPES_HEADER_WATCH_EXPERIENCE,
} from '@confluence/experience-tracker';
import { ShareButton, CopyLinkButtonLoader, SUPPORTED_CONTENT_TYPES } from '@confluence/share';
import type { ShareContentType } from '@confluence/share';
import { ShareAndRestrictButton } from '@confluence/share-and-restrict-dialog';
import { PageTreeStateUpdater } from '@confluence/page-tree-refresh-state-container';
import {
	TitleAndBreadcrumbsWrapper,
	ContentTypesHeaderContainer,
} from '@confluence/content-types-header-base';
import { ArchivedPageBanner } from '@confluence/archive-pages';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import { EmojiTitleSelection } from '@confluence/emoji-title';
import { fg } from '@confluence/feature-gating';
import {
	LiveDocSpotlightsTour,
	LiveDocSpotlightsTourManager,
	LIVE_DOC_SHARE_BUTTON_TARGET,
} from '@confluence/onboarding-live-doc-changeboarding/entry-points/LiveDocSpotlightsTour';
import { useUnifiedShareDialogEligible } from '@confluence/experiment-unified-share-dialog';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useObjectSidebarState } from '@confluence/object-sidebar-api';
import { useRouteName } from '@confluence/route-manager/entry-points/RouteState';
import { CONTENT_HISTORY } from '@confluence/named-routes';
import type { WhiteboardAIAgentServiceInterface } from '@confluence/whiteboard-types/entry-points/WhiteboardAIAgentService';

import {
	DATABASE_HEADER_SEGMENT_METRIC,
	SMART_LINK_EMBED_HEADER_SEGMENT_METRIC,
	WHITEBOARD_HEADER_SEGMENT_METRIC,
} from './perf.config';
import { ContentTypesTitle } from './ContentTypesTitle';
import type { MainActions } from './content-types-actions/MainActions';
import type { Collaborators } from './content-types-actions/Collaborators';
import type { MoreActions } from './content-types-actions/MoreActions';
import type { ContentStatus } from './content-types-actions/ContentStatus';
import { ShareComponent } from './content-types-actions/Share';
import type {
	ContentTypesHeaderQuery as ContentTypesHeaderQueryType,
	ContentTypesHeaderQuery_content_nodes,
	ContentTypesHeaderQueryVariables,
} from './__types__/ContentTypesHeaderQuery';
import { ContentTypesHeaderQuery } from './ContentTypesHeaderQuery.graphql';
import { DEFAULT_HEADER_FEATURES } from './ContentTypesHeaderFeatures';
import type { HeaderFeaturesType } from './ContentTypesHeaderFeatures';
import { getObjectHeaderBreadcrumbsContentTitle } from './getObjectHeaderBreadcrumbsContentTitle';
import { useResizeObserver } from './useResizeObserver';
import { useHeaderItems } from './useHeaderItems';
import { useEditorObjectHeaderContext } from './EditorObjectHeaderContext';
import { useDisableEditorActions } from './useDisableEditorActions';
import { useProgressiveHeader } from './progressive-header/useProgressiveHeader';

// duplicated from ContentHeader
const ContentClassificationTagLoader = LoadableAfterPaint({
	name: 'ContentClassificationTag',
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentClassificationTag" */ '@confluence/data-classification'
			)
		).ContentClassificationTag,
});

// The generated type file for ContentTypesHeaderQuery is defining the 'version' field as
// required but we want it to be optional because it's not always in the query. This is a
// workaround to make it optional.
export type ContentTypesHeaderQueryNode = Omit<ContentTypesHeaderQuery_content_nodes, 'version'> &
	Partial<Pick<ContentTypesHeaderQuery_content_nodes, 'version'>>;

export const segmentMetricsMap: {
	[key: string]: PageSegmentLoadMetric | undefined;
} = {
	database: DATABASE_HEADER_SEGMENT_METRIC,
	embed: SMART_LINK_EMBED_HEADER_SEGMENT_METRIC,
	whiteboard: WHITEBOARD_HEADER_SEGMENT_METRIC,
};

const contentTypesBreadcrumbsContainerStyles = xcss({
	width: 'fit-content',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	flexWrap: 'wrap',
	minHeight: '20px',
	paddingRight: 'space.100',
	paddingLeft: 'space.100' /* To align better with the ContentTypesTitle component */,
});

const noPaddingStyles = xcss({
	paddingRight: 'space.0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentActionsWrapper = styled.div({
	backgroundColor: `${token('elevation.surface')}`,
	pointerEvents: 'auto',
	borderRadius: '3px',
	padding: token('space.100'),
	display: 'flex',
	minHeight: '40px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentActionContainer = styled.div<{ showSeparator?: boolean }>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		...(props) =>
			props.showSeparator && {
				content: "''",
				border: `1px solid ${token('color.border')}`,
				height: '16px',
				margin: `${token('space.0')} ${token('space.050')}`,
			},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ArchiveBannerContainer = styled.div<{
	isFullWidth?: boolean;
}>({
	display: 'flex',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...(props) =>
		props.isFullWidth
			? {
					margin: `0px ${token('space.200')} ${token('space.100')}`,
				}
			: {
					margin: `${token('space.negative.100')} auto 0px`,
					maxWidth: '640px',
				},
	pointerEvents: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledShare = styled.div({
	margin: `0px ${token('space.100')}`,
});

const flexNavigationContainerStyles = xcss({
	alignItems: 'flex-start',
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	boxSizing: 'border-box',
	justifyContent: 'space-between',
});

const objectHeaderFlexNavigationContainerStyles = xcss({
	paddingTop: 'space.0',
	paddingBottom: 'space.0',
	paddingLeft: 'space.0',
	paddingRight: 'space.0',
	overflow: 'hidden',
});

const floatingHeaderContainerStyles = xcss({
	marginTop: 'space.100',
	marginLeft: 'space.100',
	marginRight: 'space.100',
	backgroundColor: 'color.background.neutral.subtle',
	height: '58px',
});

const objectHeaderBreadcrumbsAndMetadataStyles = xcss({
	minWidth: 'fit-content',
	gap: 'space.075',
	alignItems: 'center',
	overflow: 'hidden',

	[media.above.xs]: {
		minWidth: '96px',
	},
});

const objectHeaderStyles = xcss({
	width: '100%',
	alignItems: 'center',
	justifyContent: 'space-between',
	backgroundColor: 'elevation.surface',
	height: '56px',
});

// If the object header's size changes, make sure to update the height constant
// FIXED_OBJECT_HEADER_SSR_HEIGHT in contentTypesHeaderConstants.ts
const fixedBorderHeaderContainerStyles = xcss({
	padding: 'space.150',
	borderBottom: `1px solid ${token('color.border')}`,
});

const fixedBorderlessHeaderContainerStyles = xcss({
	padding: 'space.150',
	borderBottom: `1px solid ${token('color.border.inverse')}`,
});

const floatingBlockHeaderStyles = xcss({
	borderRadius: '12px',
	padding: 'space.100',
	backgroundColor: 'elevation.surface.raised',
	boxShadow: 'elevation.shadow.raised',
	height: '56px',
	pointerEvents: 'auto',

	[media.above.xs]: {
		// overrides min width 0 set in contentBreadcrumbsAndMetadataStyles used for header responsiveness
		// floatingBlockHeaderStyles is only used for whiteboard headers (which do not support responsiveness)
		minWidth: 'fit-content',
	},
});

const objectHeaderActionsContainerStyles = xcss({
	flexShrink: 0,
	paddingRight: 'space.075',
});

const inlineRenameMarginStyles = xcss({
	marginLeft: 'space.negative.075',
});

export type ContentActions = {
	mainActions: MainActions | null;
	collaborators: Collaborators | null;
	contentStatus: ContentStatus | null;
	moreActions: MoreActions | null;
};

export type ContentTypesHeaderProps = {
	// content api isn't exposed via external share. Pass the content instead for these cases
	externalShareContent?: ContentTypesHeaderQueryNode;
	contentId: string;
	contentActions?: ContentActions;
	isFloating?: boolean;
	allowedFeatures?: HeaderFeaturesType;
	onCloseTitleSpotlight?: () => void;
	registerShowTitleSpotlightHandler?: (callback: () => void) => void;
	handleRegisterOnShowHeaderSpotlight?: (callback: () => void) => void;
	handleRegisterOnShowCollaboratorSpotlight?: (callback: () => void) => void;
	onHeightChange?: (height: number) => void;
	isHeaderPinned?: boolean;
	showVersionHistorySpotlight?: boolean;
	setShowVersionHistorySpotlight?: (value: boolean) => void;
	collabEditOption?: CollabEditOptions;
	isSpaceOverviewPage?: boolean;
	isConvertToFolderHighlighted?: boolean;
	setIsConvertToFolderHighlighted?: Dispatch<React.SetStateAction<boolean>>;
	whiteboardAIAgentService?: WhiteboardAIAgentServiceInterface;
};

export type HeaderItems = {
	// unique key name
	name: string;
	shouldShowSeparator: boolean;
	shouldRenderInContainer?: boolean;
	shouldRender: boolean;
	children: ReactNode;
};

const i18n = defineMessages({
	shareWhiteboard: {
		id: 'content-types-header.share-title.whiteboard',
		defaultMessage: 'Share whiteboard',
		description: 'Title for pop-up dialog for sharing a whiteboard',
	},
	shareDatabase: {
		id: 'content-types-header.share-title.database',
		defaultMessage: 'Share database',
		description: 'Title for pop-up dialog for sharing a database',
	},
	shareEmbed: {
		id: 'content-types-header.share-title.embed',
		defaultMessage: 'Share link',
		description: 'Title for pop-up dialog for sharing a smart link embed',
	},
});

const getShareeAction = (mode: PageMode) =>
	mode === PageMode.EDIT ? PageMode.EDIT : PageMode.VIEW;

const shareFormTitles = {
	database: i18n.shareDatabase,
	embed: i18n.shareEmbed,
	whiteboard: i18n.shareWhiteboard,
};

export const ContentTypesHeader: FC<ContentTypesHeaderProps> = ({
	externalShareContent,
	contentId,
	contentActions,
	isFloating = false,
	allowedFeatures = DEFAULT_HEADER_FEATURES,
	onCloseTitleSpotlight,
	registerShowTitleSpotlightHandler,
	onHeightChange,
	handleRegisterOnShowHeaderSpotlight,
	handleRegisterOnShowCollaboratorSpotlight,
	isHeaderPinned = false,
	showVersionHistorySpotlight,
	setShowVersionHistorySpotlight,
	collabEditOption,
	isSpaceOverviewPage = false,
	isConvertToFolderHighlighted = false,
	setIsConvertToFolderHighlighted,
	whiteboardAIAgentService,
}) => {
	const { formatMessage } = useIntl();
	const isExternal = isExternalShareRequest();
	const [headerHeightRef, height] = useResizeObserver();
	const objectHeaderRef = useRef<HTMLDivElement | null>(null);
	// If accessing these values outside of editor sessions, they will be default values
	const { editorTitle, hasEditPermission, shouldRenderPreview } = useEditorObjectHeaderContext();
	const { isUSDExperimentEnabled } = useUnifiedShareDialogEligible();
	const { isObjectSidebarShown } = useObjectSidebarState();

	const isVersionHistoryPage = useRouteName({
		selector: (routeName: string | undefined) => routeName === CONTENT_HISTORY.name,
	});

	useEffect(() => {
		onHeightChange?.(height);
	}, [height, onHeightChange]);

	const {
		data,
		error: ContentTypesHeaderQueryError,
		loading: contentTypesHeaderDataLoading,
	} = useQuery<ContentTypesHeaderQueryType, ContentTypesHeaderQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ContentTypesHeaderQuery,
		{
			skip: !!externalShareContent,
			variables: { contentId, useObjectHeader: fg('confluence_frontend_object_header') },
			fetchPolicy: fg('confluence_frontend_object_header') ? 'cache-and-network' : 'cache-first',
		},
	);

	const contentData = externalShareContent || data?.content?.nodes?.[0];
	const spaceKey = contentData?.space?.key || '';

	// Different pieces of content can have different modes, which need to be reflected in this header.
	// Eg, pages & blogposts can be 'draft', 'archived', 'view', 'edit', or 'live' (or 'embedded', or 'legacy'!)
	const rawContentType = (contentData?.type as ShareContentType) ?? undefined; // ShareButton's shareContentType prop doesn't like null values
	const isArchived = contentData?.status === 'archived';
	const isDraft = contentData?.status === 'draft';
	const isViewEditOrLive = useGetPageMode();

	const contentType = {
		type: rawContentType,
		mode: getContentMode(rawContentType, isArchived, isDraft, isViewEditOrLive),
	};

	const { shouldDisableEditorButtons } = useDisableEditorActions(contentId, contentType.mode);

	const isClassificationEnabled = useIsClassificationEnabledForContentTypeExpansion(
		contentData?.type ?? '',
	);
	const { isEnabled: isDataClassificationEnabledForOrg } = useIsDataClassificationEnabledForOrg();

	let contentStatusContext;
	if (contentType.mode === 'live') {
		contentStatusContext = 'current-and-draft' as const;
	} else if (contentType.mode === 'edit' || contentType.mode === 'draft') {
		contentStatusContext = 'draft-only' as const;
	} else {
		contentStatusContext = 'current-only' as const;
	}

	const contentClassificationMetadata = useContentClassification({
		contentId,
		spaceKeyOverride: contentData?.space?.key,
		contentStatusContext,
		shouldSkipQueries: !isClassificationEnabled,
		shouldReadFromQueries: true,
	});
	const classificationTagUiVariant = useGetClassificationTagUIVariant({
		contentTitle: contentData?.title ?? '',
		classificationName: contentClassificationMetadata.classification?.name,
	});

	const classificationProps = {
		isDataClassificationEnabledForOrg,
		spaceKey,
		contentId,
		contentType: contentType.type,
		contentClassificationMetadata,
		hasEditPermission: hasEditPermission || false, // hasEditPermission can be undefined, so coerce to false
		isLivePage: contentType.mode === 'live',
		userOffline: shouldDisableEditorButtons,
	};

	const shouldRenderClassificationPlaceholder =
		useShouldRenderEditorActionBarClassificationPlaceholder(classificationProps);

	const { breadcrumbsDisplayMode, isBorderlessHeader } = useProgressiveHeader({
		contentType: contentType.type,
		contentMode: contentType.mode || '',
		isSpaceOverviewPage,
		isVersionHistoryPage,
		isArchived,
		isObjectSidebarShown,
		contentTypesHeaderDataLoading,
		objectHeaderRef,
		contentId,
	});

	const readOnly =
		isExternal ||
		!contentData?.operations?.some(
			(operation) =>
				operation?.targetType &&
				VALID_OPERATION_TARGETS.includes(operation?.targetType as ContentType) &&
				operation?.operation === 'update',
		);
	const isMobile = shouldShowMobileWeb();

	const shouldShowEmojiTitle = isEmojiTitleEnabled(contentType.type);

	// Whiteboard and Database's archive banner renders in its own shell to account for a pinned solid header and a side panel
	// Similarly, pages and blogposts manage their own archive banner
	const showArchiveBanner = contentType.type === 'embed';
	const hasFullWidthBanner = contentType.type === 'embed';
	const showInlineRename = contentType.type !== 'page' && contentType.type !== 'blogpost';

	const { showBreadcrumbs: shouldShowBreadcrumbs } = allowedFeatures;

	const renderCopyLink = () => {
		if (
			Object.values(SUPPORTED_CONTENT_TYPES).includes(contentType.type as SUPPORTED_CONTENT_TYPES)
		) {
			return (
				<CopyLinkButtonLoader
					contentId={contentId}
					spaceKey={spaceKey}
					contentType={contentType.type}
					source="contentHeader"
					/**
					 * All currently-supported content types currently treats both viewing and
					 * editing the same. To avoid issues, we'll refer to viewing to maintain
					 * consistency, as using 'edit' would generate a different URL.
					 */
					pageMode="view"
				/>
			);
		}
	};
	const isNewShareEnabled = useIsNewShareEnabled(contentType.type);

	const shareFormTitle = shareFormTitles[contentType.type]
		? formatMessage(shareFormTitles[contentType.type])
		: undefined;

	const renderShare = () => {
		if (fg('confluence_frontend_unified_restrict_and_share') || isUSDExperimentEnabled) {
			return (
				<ShareAndRestrictButton
					contentId={contentId}
					contentType={contentType.type}
					shareeAction={getShareeAction(isViewEditOrLive)}
					source="contentHeader"
					isUnpublishedDraft={contentType.mode === 'draft'}
					contentSubType={contentType.mode === 'live' ? 'live' : undefined}
				/>
			);
		} else if (isNewShareEnabled) {
			return (
				<ShareButton
					contentId={contentId}
					triggerButtonAppearance="primary"
					shareFormTitle={shareFormTitle}
					shareTitle={contentData?.title ?? ''}
					//
					// So far, new content type URLs don't have a view/edit distinction: we're saying
					// 'view' here just to make the dialog's CTA button say 'Share'
					// rather than 'Invite'.
					shareeAction="view"
					// If, in the future, any new content type URLs change to include some UGC slug
					// like pages presently do, be sure to set a scrubbed URL in
					// `contentShareLink` here.
					//
					// https://atlassian.slack.com/archives/C02PTHXKDUY/p1698449660824349
					//
					shareContentType={contentType.type}
					//
					// Note: we decided _not_ to use disableKeyboardShortcut here. See
					// https://atlassian.slack.com/archives/C04428W5K0U/p1698683610526359
				/>
			);
		} else {
			return <ShareComponent contentId={contentId} contentType={contentType.type ?? ''} />;
		}
	};

	const wrappedRenderShare = () => {
		return (
			<SpotlightTarget name={LIVE_DOC_SHARE_BUTTON_TARGET}>
				{fg('confluence_frontend_object_header') ? (
					<Inline>{renderShare()}</Inline>
				) : (
					<StyledShare>{renderShare()}</StyledShare>
				)}
			</SpotlightTarget>
		);
	};

	// The primary reason for this structure is to support programmatic control of when a separator should render
	const headerItems: HeaderItems[] = useHeaderItems({
		contentId,
		contentType: contentType.type,
		contentMode: contentType.mode,
		contentActions,
		handleRegisterOnShowCollaboratorSpotlight,
		handleRegisterOnShowHeaderSpotlight,
		allowedFeatures,
		contentData,
		renderCopyLink,
		renderShare: wrappedRenderShare,
		showVersionHistorySpotlight,
		setShowVersionHistorySpotlight,
		collabEditOption,
		isSpaceOverviewPage,
		readOnly,
		isConvertToFolderHighlighted,
		setIsConvertToFolderHighlighted,
		whiteboardAIAgentService,
	});

	const renderActionContainers = () => {
		const contentToRender = headerItems.filter((contentActions) => contentActions.shouldRender);

		return contentToRender.map((contentAction, index) => {
			const { name, shouldShowSeparator, children, shouldRenderInContainer = true } = contentAction;
			const isLastEntry = index + 1 === contentToRender.length;
			const showSeparator = isLastEntry ? false : shouldShowSeparator;
			const testIdName = `${name}-action-container-${
				showSeparator ? 'with-separator' : 'without-separator'
			}`;

			return (
				<Fragment key={name}>
					{shouldRenderInContainer ? (
						<ContentActionContainer data-testid={testIdName} showSeparator={showSeparator}>
							{children}
						</ContentActionContainer>
					) : (
						children
					)}
				</Fragment>
			);
		});
	};

	const renderContentTypesInlineRenameBreadcrumb = () => {
		return (
			<Flex
				gap="space.075"
				alignItems="center"
				testId="inline-rename-breadcrumb-title"
				xcss={fg('confluence_frontend_object_header') && inlineRenameMarginStyles}
			>
				<Subscribe to={[PageTreeStateUpdater]}>
					{(pageStateUpdater: PageTreeStateUpdater) => {
						const updateContentTree = () => {
							pageStateUpdater.setForceFetchPageId(contentId);
						};

						return (
							<Inline space="space.0" alignBlock="center">
								{shouldShowEmojiTitle && (
									<EmojiTitleSelection
										data-testid="emoji-title-selection"
										contentId={contentId}
										isTitleDisabled={false}
										isSpaceTypesCreationFlowEnabled={false}
										isTitleCenterAligned
										hasCoverPicture={false}
										isReadOnly={readOnly}
										contentType={contentType.type}
										updateContentTree={updateContentTree}
									/>
								)}
								<ContentTypesTitle
									title={contentData?.title ?? ''}
									contentId={contentId}
									spaceName={contentData?.space?.name ?? ''}
									readOnly={readOnly}
									key={contentData?.title}
									updateContentTree={updateContentTree}
									contentType={contentType.type ?? ''}
									onCloseTitleSpotlight={onCloseTitleSpotlight}
									registerShowTitleSpotlightHandler={registerShowTitleSpotlightHandler}
								/>
							</Inline>
						);
					}}
				</Subscribe>
			</Flex>
		);
	};

	const getClassificationForObjectHeader = () => {
		if (
			contentType.type === 'whiteboard' ||
			contentType.type === 'database' ||
			contentType.type === 'embed'
		) {
			return isClassificationEnabled ? (
				<>
					<ContentTypeClassification
						variant={classificationTagUiVariant}
						spaceKey={spaceKey}
						contentId={contentId}
						contentType={contentType.type}
						contentClassificationMetadata={contentClassificationMetadata}
						readOnly={readOnly}
					/>
					<ClassificationEditModal
						contentId={contentId}
						spaceKey={spaceKey}
						contentClassificationMetadata={contentClassificationMetadata}
					/>
				</>
			) : null;
		} else if (
			contentType.mode === 'live' ||
			contentType.mode === 'draft' ||
			contentType.mode === 'edit'
		) {
			return (
				!fg('cc_page_experiences_byline_classification') && (
					<>
						<ClassificationEditModal
							contentId={contentId}
							spaceKey={spaceKey}
							contentClassificationMetadata={contentClassificationMetadata}
						/>
						<EditorClassificationTagWrapper
							{...classificationProps}
							shouldRenderPlaceholder={shouldRenderClassificationPlaceholder}
						/>
					</>
				)
			);
		} else if (isSpaceOverviewPage) {
			// we want to render the classification tag in the header for space overview pages
			return (
				<Box testId="content-classification-tag">
					<ContentClassificationTagLoader
						contentId={contentId}
						contentType={contentType.type || null}
						customSitesTitleComponent
						contentClassificationMetadata={contentClassificationMetadata}
						isEditMode={false}
					/>
				</Box>
			);
		} else {
			// view and archived
			return (
				!fg('cc_page_experiences_byline_classification') && (
					<Box testId="content-classification-tag">
						<ContentClassificationTagLoader
							contentId={contentId}
							contentType={contentType.type || null}
							customSitesTitleComponent
							contentClassificationMetadata={contentClassificationMetadata}
							isEditMode={false}
						/>
					</Box>
				)
			);
		}
	};

	const renderOriginalHeader = () => {
		return (
			<>
				<TitleAndBreadcrumbsWrapper isContentHeaderPinned={isHeaderPinned}>
					{shouldShowBreadcrumbs && (
						<Box
							xcss={[
								contentTypesBreadcrumbsContainerStyles,
								fg('confluence_modernized_breadcrumbs') && noPaddingStyles,
							]}
						>
							<BreadcrumbsNonLoadable
								compact
								contentId={contentId}
								spaceKey={spaceKey}
								showInlineRename
							/>
						</Box>
					)}
					{renderContentTypesInlineRenameBreadcrumb()}
					{isClassificationEnabled ? (
						<>
							<ContentTypeClassification
								variant={classificationTagUiVariant}
								spaceKey={spaceKey}
								contentId={contentId}
								contentType={contentType.type}
								contentClassificationMetadata={contentClassificationMetadata}
								readOnly={readOnly}
							/>
							<ClassificationEditModal
								contentId={contentId}
								spaceKey={spaceKey}
								contentClassificationMetadata={contentClassificationMetadata}
							/>
						</>
					) : null}
				</TitleAndBreadcrumbsWrapper>
				{!isMobile ? (
					<ContentActionsWrapper>{renderActionContainers()}</ContentActionsWrapper>
				) : (
					<Fragment>
						<ExperienceSuccess name={VIEW_PAGE_FAVORITE_BUTTON_EXPERIENCE} />
						<ExperienceSuccess name={CONTENT_TYPES_HEADER_WATCH_EXPERIENCE} />
					</Fragment>
				)}
			</>
		);
	};

	const renderObjectHeader = () => {
		if (shouldRenderPreview) return null;

		const isFloatingBlockHeader = contentType.type === 'whiteboard';

		// Return link for Version History breadcrumbs
		const contentLink =
			contentId && isVersionHistoryPage
				? buildContentPath({
						contentId,
						contentType: contentType.type,
						spaceKey,
					})
				: undefined;

		return (
			<Flex
				xcss={[
					objectHeaderStyles,
					isFloatingBlockHeader && floatingHeaderContainerStyles,
					!isFloatingBlockHeader && isBorderlessHeader && fixedBorderlessHeaderContainerStyles,
					!isFloatingBlockHeader && !isBorderlessHeader && fixedBorderHeaderContainerStyles,
				]}
				ref={objectHeaderRef}
				testId="object-header-container"
			>
				<Flex
					xcss={[
						objectHeaderBreadcrumbsAndMetadataStyles,
						isFloatingBlockHeader && floatingBlockHeaderStyles,
					]}
				>
					{/* don't show breadcrumbs when header data is still loading to prevent flickering of the header between pages */}
					{shouldShowBreadcrumbs &&
						!contentTypesHeaderDataLoading &&
						breadcrumbsDisplayMode !== BREADCRUMBS_MODES.HIDDEN && (
							<BreadcrumbsNonLoadable
								compact
								contentId={contentId}
								// Use the space key alias if it exists, otherwise use the space key.  We want to use
								// the alias because that is used in the route URL so SSR will pick that up as the
								// space key and use it in the preloading cache for the space detail query.  If we
								// use the true (UUID) space key here in the request, the SSR cache will not find it
								// in the cache and we won't see the breadcrumbs in the SSR'ed version of the header
								spaceKey={contentData?.space?.alias ?? contentData?.space?.key ?? ''}
								contentTitle={getObjectHeaderBreadcrumbsContentTitle({
									isSpaceOverviewPage,
									contentMode: contentType.mode,
									editorTitle,
									contentTitle: contentData?.title,
								})}
								showInlineRename={showInlineRename}
								shouldDisableMoveButton={shouldDisableEditorButtons}
								displayMode={breadcrumbsDisplayMode}
								contentLink={contentLink}
								contentType={contentType.type}
								subType={contentType.mode === 'live' ? 'live' : undefined}
							/>
						)}
					{/* only render this for content types because the object header is used for pages and blogs too which don't have inline rename breadcrumbs yet */}
					{showInlineRename && renderContentTypesInlineRenameBreadcrumb()}
					{getClassificationForObjectHeader()}
				</Flex>
				<Inline
					space="space.075"
					alignBlock="center"
					alignInline="end"
					xcss={[
						objectHeaderActionsContainerStyles,
						isFloatingBlockHeader && floatingBlockHeaderStyles,
					]}
					testId="object-header-actions-container"
				>
					{!isMobile && renderActionContainers()}
				</Inline>
			</Flex>
		);
	};

	const segmentMetric = segmentMetricsMap[contentType.type];

	const headerComponents = fg('confluence_frontend_object_header')
		? renderObjectHeader()
		: renderOriginalHeader();

	// We moved the classification modal context provider to the fullpageeditor component level for page editor to be able to use it
	// in the page editor byline. We need to conditionally render it here for the other content types.
	const ConditionalClassificationProvider =
		(contentType.mode === 'live' || contentType.mode === 'draft' || contentType.mode === 'edit') &&
		fg('cc_page_experiences_byline_classification')
			? Fragment
			: ClassificationModalContextProvider;
	const hasPointerEvents =
		contentType.type === 'whiteboard' ? false : fg('confluence_frontend_object_header');

	return (
		<ErrorBoundary attribution={Attribution.CONTENT_TYPES}>
			{segmentMetric && <PageSegmentLoadStart metric={segmentMetric} />}
			<ContentTypesHeaderContainer
				isFloating={isFloating}
				ref={headerHeightRef}
				hasPointerEvents={hasPointerEvents}
				key={contentId}
			>
				<Box
					xcss={[
						flexNavigationContainerStyles,
						fg('confluence_frontend_object_header') && objectHeaderFlexNavigationContainerStyles,
					]}
				>
					<ConditionalClassificationProvider>
						{contentType.mode === 'live' &&
						fg('cc_onboarding_changeboarding_for_live_pages_eap') ? (
							<LiveDocSpotlightsTourManager>
								{headerComponents}
								<LiveDocSpotlightsTour />
							</LiveDocSpotlightsTourManager>
						) : (
							headerComponents
						)}
					</ConditionalClassificationProvider>
				</Box>

				{showArchiveBanner && (
					<ArchiveBannerContainer isFullWidth={hasFullWidthBanner}>
						<ArchivedPageBanner contentId={contentId} marginStyle="none" />
					</ArchiveBannerContainer>
				)}
			</ContentTypesHeaderContainer>

			<ExperienceSuccess
				name={CONTENT_TYPES_HEADER_EXPERIENCE}
				attributes={{ contentId, contentType: contentType.type }}
			/>
			{ContentTypesHeaderQueryError && <ErrorDisplay error={ContentTypesHeaderQueryError} />}
		</ErrorBoundary>
	);
};
