import type { ReactNode, FC } from 'react';
import React, { Fragment, useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';

import { LIVE_DOC_MODES_TOGGLE_TARGET } from '@confluence/onboarding-live-doc-changeboarding/entry-points/LiveDocSpotlightsTour';
import {
	EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { WebItemLocation, PAGE_METADATA_BANNER } from '@confluence/web-item-location';
import { DRAFT_STATUS_LOZENGE_ID } from '@confluence/web-item';
import { RoutesContext, getSingleParamInt } from '@confluence/route-manager';

import type { LastEditedProps } from './LastEdited';
import { LastEdited } from './LastEdited';

const unpublishedChangesTextStyles = xcss({
	color: 'color.text.subtlest',
});

// this component is copied from content-header PageMetadataBanner.jsx to avoid circular dependency
// and refactored to ignore other webitems under PAGE_METADATA_BANNER except for the "Unpublished Changes" label that object header uses
export const UnpublishedChangesOrLastEdited: FC<LastEditedProps> = ({
	contentId,
	contentType,
	contentMode,
	spaceKey,
	version,
}) => {
	const { getQueryParams } = useContext(RoutesContext);
	const queryParams = getQueryParams();
	const versionOverride: number | null = getSingleParamInt(queryParams, 'versionOverride', null); // webitemlocation must use versionOverride, and not just version

	const renderLastEdited = () => {
		return (
			<SpotlightTarget name={LIVE_DOC_MODES_TOGGLE_TARGET}>
				<LastEdited
					contentId={contentId}
					version={version}
					contentType={contentType}
					contentMode={contentMode}
					spaceKey={spaceKey}
				/>
			</SpotlightTarget>
		);
	};

	// if not viewing page or blogpost, automatically return lastedited instead of unpublished changes.
	if (!(contentMode === 'view' && (contentType === 'page' || contentType === 'blogpost'))) {
		return renderLastEdited();
	}

	return (
		<Fragment>
			<ExperienceStart name={EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE} />
			<WebItemLocation
				tagName="span"
				location={PAGE_METADATA_BANNER}
				contentId={contentId}
				version={versionOverride || undefined}
			>
				{({ webItems }) => {
					let unpublishedChangesItem: ReactNode = null;
					if (webItems.some((item) => item.id === DRAFT_STATUS_LOZENGE_ID)) {
						unpublishedChangesItem = (
							<Box xcss={unpublishedChangesTextStyles}>
								<FormattedMessage {...i18n.unpublishedChanges} />
							</Box>
						);
					}

					return (
						<Fragment>
							<ExperienceSuccess name={EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE} />
							{unpublishedChangesItem || renderLastEdited()}
						</Fragment>
					);
				}}
			</WebItemLocation>
		</Fragment>
	);
};

const i18n = defineMessages({
	unpublishedChanges: {
		id: 'content-types-header.object-header-items.unpublished-changes.link.text',
		defaultMessage: 'Unpublished changes',
		description:
			'Text that shows on view page header when there are unpublished changes for the page',
	},
});
