import { defineMessages } from 'react-intl-next';

export const i18nShared = defineMessages({
	publishButton: {
		id: 'publish-modal.default-publish-button',
		description:
			'Button to bring up publish modal dialog for user to edit setting and publish the page',
		defaultMessage: 'Publish...',
	},
	updateButton: {
		id: 'publish-modal.update-button',
		description: 'Button to publish/update a previously published page without opening the modal',
		defaultMessage: 'Update',
	},
	chevronButtonTooltip: {
		id: 'publish-modal.split-button-tooltip',
		description:
			'Tooltip explaining that the right-side chevron/split button is used to open the publish modal and adjust publish settings',
		defaultMessage: 'Adjust update settings',
	},
	chevronButtonTooltipObjectHeader: {
		id: 'publish-modal.split-button-tooltip.object-header',
		description:
			'Tooltip explaining that the right-side chevron/split button displays a menu that allows adjusting publish settings.  This means "settings for updating", not "update the settings" (where "update" is a verb).',
		defaultMessage: 'Update settings',
	},
	updatePublicContentLabel: {
		id: 'publish-modal.update-public-content-label',
		description: 'Externally shared Update icon label',
		defaultMessage: 'Update public content',
	},
});
