import type { FC } from 'react';
import React, { useCallback } from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { defineMessages, useIntl } from 'react-intl-next';

import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import Button from '@atlaskit/button/standard-button';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import EyeOpenIcon from '@atlaskit/icon/core/eye-open';
import EyeOpenFilledIcon from '@atlaskit/icon/core/eye-open-filled';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { LoadableAfterPaint } from '@confluence/loadable';
import { ShortcutVisualizer, WATCH_CONTENT_SHORTCUT } from '@confluence/shortcuts';
import { WatchContentNudgeSpotlight, WatchButtonPlaceholder } from '@confluence/watch-dialog';

const i18n = defineMessages({
	startWatch: {
		id: 'content-types-header.start.watch',
		defaultMessage: 'Watch',
		description: 'Text for the start watch button in content types header',
	},
	stopWatch: {
		id: 'content-types-header.stop.watch',
		defaultMessage: 'Stop watching',
		description: 'Text for the stop button in content types header',
	},
});

type TriggerProps = {
	isWatching: boolean;
	isSelected: boolean;
	onClick: () => void;
	onMouseOver: () => void;
	triggerProps?: any;
	contentType: string;
	hasRecentlyUnwatchedContent: boolean;
};

export const ContentTypesWatchDialogButton: FC<WrappedComponentProps & TriggerProps> = ({
	isWatching,
	isSelected,
	onClick,
	onMouseOver,
	triggerProps,
	contentType,
	hasRecentlyUnwatchedContent,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleWatchButton = useCallback(async () => {
		onClick();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'content-watch-button',
				source: 'viewedContentScreen',
				attributes: {
					contentType,
				},
			},
		}).fire();
	}, [onClick, createAnalyticsEvent, contentType]);

	const icon = isWatching ? (
		<EyeOpenFilledIcon label={intl.formatMessage(i18n.stopWatch)} />
	) : (
		<EyeOpenIcon label={intl.formatMessage(i18n.startWatch)} />
	);

	const onDismissNudgeButtonClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'dismissed',
				actionSubject: 'button',
				actionSubjectId: 'WatchDialogWatchContentNudge',
				source: 'WatchButton',
			},
		}).fire();
	};

	const onWatchFromNudgeButtonClick = () => {
		onClick();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'watched',
				actionSubject: 'button',
				actionSubjectId: 'WatchDialogWatchContentNudge',
				source: 'WatchButton',
			},
		}).fire();
	};

	return (
		<SpotlightManager blanketIsTinted={false}>
			<SpotlightTarget name="content-watch-spotlight">
				<Tooltip
					content={
						<ShortcutVisualizer
							shortcut={WATCH_CONTENT_SHORTCUT}
							contentBefore={
								isWatching
									? intl.formatMessage(i18n.stopWatch)
									: intl.formatMessage(i18n.startWatch)
							}
						/>
					}
					position="top"
					hideTooltipOnClick
				>
					<Button
						onClick={handleWatchButton}
						onMouseOver={onMouseOver}
						appearance="subtle"
						iconBefore={icon}
						isSelected={isSelected}
						{...triggerProps}
						data-testId="watch-content-button"
					/>
				</Tooltip>
			</SpotlightTarget>
			{!isWatching && !hasRecentlyUnwatchedContent && (
				<WatchContentNudgeSpotlight
					onWatchFromNudgeButtonClick={onWatchFromNudgeButtonClick}
					onDismissNudgeButtonClick={onDismissNudgeButtonClick}
				/>
			)}
		</SpotlightManager>
	);
};

export const ContentTypesWatchDialogButtonLoader = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentTypesWatchDialogButton" */ './ContentTypesWatchDialogButton'
			)
		).ContentTypesWatchDialogButton,
	loading: WatchButtonPlaceholder,
});
