import React, { type ComponentType, type FC } from 'react';
import { Subscribe } from 'unstated';

import { PageTreeStateUpdater } from './PageTreeStateUpdater';

export type WithPageTreeStateUpdaterProps = {
	pageTreeStateUpdater: PageTreeStateUpdater;
};

export function withPageTreeStateUpdater<T>(
	WrappedComponent: ComponentType<T & WithPageTreeStateUpdaterProps>,
): FC<Omit<T, keyof WithPageTreeStateUpdaterProps>> {
	function withPageTreeStateUpdater(props) {
		return (
			<Subscribe to={[PageTreeStateUpdater]}>
				{(pageTreeStateUpdater: PageTreeStateUpdater) => (
					<WrappedComponent pageTreeStateUpdater={pageTreeStateUpdater} {...props} />
				)}
			</Subscribe>
		);
	}
	return withPageTreeStateUpdater;
}
