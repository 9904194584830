import { useEffect, useState, useRef } from 'react';

type UseResizeObserverResponse = [React.MutableRefObject<HTMLDivElement | null>, number];
export const useResizeObserver = (): UseResizeObserverResponse => {
	const [height, setHeight] = useState(0);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const observeTarget = ref.current;
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setHeight(entry.contentRect.height);
			});
		});

		if (observeTarget) {
			resizeObserver.observe(observeTarget);
			return () => {
				resizeObserver.unobserve(observeTarget);
			};
		} else {
			setHeight(0);
		}
	}, []);

	return [ref, height];
};
