import gql from 'graphql-tag';

export const PublicLinkPageQuery = gql`
	query PublicLinkPageQuery($pageId: ID!) {
		publicLinkPage(pageId: $pageId) {
			pageId
			pageStatus
			publicLinkUrlPath
		}
	}
`;
