/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContentDeleteActionType {
  DELETE_DRAFT = "DELETE_DRAFT",
  DELETE_DRAFT_IF_BLANK = "DELETE_DRAFT_IF_BLANK",
  MOVE_TO_TRASH = "MOVE_TO_TRASH",
  PURGE_FROM_TRASH = "PURGE_FROM_TRASH",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDraftMutation
// ====================================================

export interface DeleteDraftMutation_deleteContent {
  contentId: string;
  wasDeleted: boolean;
}

export interface DeleteDraftMutation {
  deleteContent: DeleteDraftMutation_deleteContent;
}

export interface DeleteDraftMutationVariables {
  contentId: string;
  action: ContentDeleteActionType;
}
