import React, { useMemo } from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import type { WatchQueryFetchPolicy } from 'apollo-client';
import { useQuery } from '@apollo/react-hooks';

import { useSessionData } from '@confluence/session-data';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';

import { PublicLinkPageQuery } from '../graphql/PublicLinkPageQuery.graphql';
import { PublicLinkPageStatus } from '../graphql/__types__/PublicLinkPageQuery';
import type {
	PublicLinkPageQuery as PublicLinkPageQueryType,
	PublicLinkPageQueryVariables,
} from '../graphql/__types__/PublicLinkPageQuery';

import type { PublicLinkContentQueryData } from './usePublicLinkContentQuery';
import { usePublicLinkContentQuery } from './usePublicLinkContentQuery';
import type { PublicLinkPermissionsForObjectQueryData } from './usePublicLinkPermissionsForObject';
import { usePublicLinkPermissionsForObject } from './usePublicLinkPermissionsForObject';

const KNOWN_ERROR_MESSAGES = [
	'This is a Standard/Premium feature. You are currently subscribed to the Free plan.',
	'Anonymous access is not permitted',
];

const i18n = defineMessages({
	publicLinkUrlPathError: {
		id: 'public-links.usePublicLinkDataProvider.sharePathError.message',
		defaultMessage: 'publicLinkStatus was ON, but no publicLinkUrlPath was provided',
		description: 'Error message when the Public link status is ON, but no share url is provided.',
	},
});

type RefetchType = 'all' | 'permission' | 'public-link';

export type PublicLinkPageDataCalculated = {
	/** Are Public Links enabled by admins at Site and Space levels */
	isPublicLinkAdminEnabled: boolean;
	/** Is Public Link allowed for content type, admin enabled, and status ON */
	isPublicLinkFullyEnabled: boolean;
	/** Are Public Links allowed for this content type */
	isPublicLinkAllowedForContentType: boolean;
	/** Are Editable Public Links allowed for this content type*/
	isPublicLinkEditAllowedForContentType: boolean;
	/** Does Public Link itself have edit permissions i.e. Editable Public Whiteboards */
	isEditPublicLink: boolean;
	/** Can current user edit Public Link status for this page */
	canEditPublicLink: boolean;
	publicLinkStatus?: PublicLinkPageStatus | null;
	publicLinkSharePath?: string | null;
	refetch: (dataToFetch?: RefetchType) => void;
};

interface PublicLinkPageProviderCommonData {
	errors: Error[];
}

export type PublicLinkPageProviderData = PublicLinkPageProviderCommonData &
	({ isLoading: true } | ({ isLoading: false } & PublicLinkPageDataCalculated));

type PublicLinkPageData = {
	pageStatus: PublicLinkPageStatus;
	publicLinkUrlPath: string | null;
};

type PublicLinkPageQueryData = {
	isLoading: boolean;
	error?: Error;
	data?: PublicLinkPageData | null | undefined;
	refetch: () => void;
};

type PublicLinkContentQueryProps =
	// if you want to skip usePublicLinkContentQuery, then a contentType must be provided since it's crucial for computing certain fields
	{ skip: true; contentType: string } | { skip: false };

const computeData = (
	pageData: PublicLinkPageQueryData,
	contentData: PublicLinkContentQueryData,
	permissionsData: PublicLinkPermissionsForObjectQueryData,
	isPublicLinkStatusOnForContent: boolean,
	enrolledContentTypes: Array<String>,
	edition: ConfluenceEdition | null,
): PublicLinkPageDataCalculated => {
	// Are Public Links allowed for this content type
	const isPublicLinkAllowedForContentType =
		!contentData.error &&
		contentData.data !== undefined &&
		enrolledContentTypes.includes(contentData.data.contentType);

	// Are Editable Public Links allowed for this content type
	const isPublicLinkEditAllowedForContentType =
		isPublicLinkAllowedForContentType &&
		edition === ConfluenceEdition.PREMIUM &&
		contentData.data?.contentType === 'whiteboard';

	// Can current user edit Public Link status
	const canEditPublicLink = !contentData.error && contentData.data?.canEditPublicLink === true;

	// Value for if the Public Link has edit permissions i.e. Editable Public Whiteboards
	const isEditPublicLink = !!(!permissionsData.error && permissionsData.data?.hasEditPermission);

	// Are Public Links enabled by admins at Site and Space levels
	const isPublicLinkAdminEnabled =
		isPublicLinkStatusOnForContent ||
		(!pageData.error && pageData.data?.pageStatus === PublicLinkPageStatus.OFF);

	// Is Public Link allowed for content type, admin enabled, and status ON
	const isPublicLinkFullyEnabled =
		isPublicLinkStatusOnForContent && isPublicLinkAdminEnabled && isPublicLinkAllowedForContentType;

	const refetch = (dataToFetch: RefetchType = 'all') => {
		// if dataToFetch !== permission, it means it's either all or public-link
		if (dataToFetch !== 'permission') {
			pageData.refetch();
		}
		// if dataToFetch !== public-link, it means it's either all or permission
		if (dataToFetch !== 'public-link') {
			isPublicLinkEditAllowedForContentType && permissionsData.refetch();
		}
	};
	return {
		isPublicLinkAdminEnabled,
		isPublicLinkFullyEnabled,
		isPublicLinkAllowedForContentType,
		isPublicLinkEditAllowedForContentType,
		isEditPublicLink,
		canEditPublicLink,
		publicLinkStatus: pageData.data?.pageStatus,
		publicLinkSharePath: pageData.data?.publicLinkUrlPath,
		refetch,
	};
};

// the primary reason this hook exists is the initialization of the type variables due to the PublicLinkPageProviderData type
// the type needs to be refactored to not return 2 variations of data depending on the loading state, but as it stands now
// the old share dialog would need some refactoring
export const usePublicLinkData = (props: {
	contentId: string;
	skip?: boolean;
	contentQueryProps?: PublicLinkContentQueryProps;
	fetchPolicy?: WatchQueryFetchPolicy;
}): PublicLinkPageDataCalculated & { isLoading: boolean } => {
	const publicLinkData = usePublicLinkPageDataProvider({ pageId: props.contentId, ...props });

	return publicLinkData.isLoading
		? {
				isPublicLinkFullyEnabled: false,
				isPublicLinkAdminEnabled: false,
				isPublicLinkAllowedForContentType: false,
				isPublicLinkEditAllowedForContentType: false,
				canEditPublicLink: false,
				publicLinkStatus: undefined,
				publicLinkSharePath: undefined,
				isEditPublicLink: false,
				refetch: () => {},
				...publicLinkData,
			}
		: publicLinkData;
};

export const usePublicLinkPageDataProvider = (props: {
	pageId: string;
	skip?: boolean;
	contentQueryProps?: PublicLinkContentQueryProps;
	fetchPolicy?: WatchQueryFetchPolicy;
}): PublicLinkPageProviderData => {
	const enrolledContentTypes = ['page', 'whiteboard'];

	const intl = useIntl();

	const { isLicensed, edition } = useSessionData();

	let contentData = usePublicLinkContentQuery({
		contentId: props.pageId,
		skip: props.contentQueryProps?.skip,
	});

	const contentType = props.contentQueryProps?.skip
		? props.contentQueryProps?.contentType
		: contentData?.data?.contentType;

	contentData = {
		...contentData,
		data: {
			...contentData?.data,
			canEditPublicLink: contentData?.data?.canEditPublicLink ?? false,
			contentType: contentType ?? '',
		},
	};

	const shouldSkip =
		props.skip ||
		!enrolledContentTypes.includes(contentData?.data?.contentType || '') ||
		!isLicensed ||
		edition === ConfluenceEdition.FREE;

	const rawPageData = useQuery<PublicLinkPageQueryType, PublicLinkPageQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PublicLinkPageQuery,
		{
			variables: { pageId: props.pageId },
			fetchPolicy: props.fetchPolicy,
			skip: shouldSkip,
		},
	);

	const permissionsData = usePublicLinkPermissionsForObject({
		objectId: props.pageId,
		skip:
			shouldSkip ||
			edition !== ConfluenceEdition.PREMIUM ||
			contentData?.data?.contentType !== 'whiteboard',
	});

	const pageData: PublicLinkPageQueryData = useMemo(
		() => ({
			isLoading: rawPageData.loading,
			data: rawPageData.data?.publicLinkPage,
			error: rawPageData.error,
			refetch: rawPageData.refetch,
		}),
		[rawPageData.loading, rawPageData.data, rawPageData.error, rawPageData.refetch],
	);

	if (
		isUnauthorizedError(rawPageData.error) ||
		// We currently aren't being given error types by the backend.
		// When we do, we can better parse these expected errors.
		// https://product-fabric.atlassian.net/browse/CPE-1991
		KNOWN_ERROR_MESSAGES.some((msg) => rawPageData.error?.message?.includes(msg))
	) {
		markErrorAsHandled(rawPageData.error);
	}

	// Is Public Link Status for page ON
	const isPublicLinkStatusOnForContent =
		!pageData.error && pageData.data?.pageStatus === PublicLinkPageStatus.ON;

	const loading = pageData.isLoading || contentData.isLoading || permissionsData.isLoading;
	const errors = useMemo(
		() =>
			[pageData, contentData, permissionsData]
				.map((query) => !query.isLoading && query.error)
				.filter((err): err is Error => Boolean(err)),
		[pageData, contentData, permissionsData],
	);

	if (isPublicLinkStatusOnForContent && pageData.data?.publicLinkUrlPath === null) {
		errors.push(new Error(intl.formatMessage(i18n.publicLinkUrlPathError)));
	}
	return loading
		? {
				isLoading: true,
				errors,
			}
		: {
				isLoading: false,
				errors,
				...computeData(
					pageData,
					contentData,
					permissionsData,
					isPublicLinkStatusOnForContent,
					enrolledContentTypes,
					edition,
				),
			};
};

export const PublicLinkPageDataProvider: FC<{
	contentId: string;
	children: (data: PublicLinkPageProviderData) => JSX.Element | null;
}> = ({ contentId, children }) => (
	<>{children(usePublicLinkPageDataProvider({ pageId: contentId }))}</>
);
