import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import AvatarGroup from '@atlaskit/avatar-group';
import { useIsReducedMotion } from '@atlaskit/motion';
import { SpotlightCard, SpotlightPulse, SpotlightTransition } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { useSkippableCoordination } from '@confluence/skippable-coordination-client';

import FollowCursorOnboardingHeader from './assets/FollowCursorOnboardingHeader.svg';

const SHOWN_COLLABORATORS = 4;
const isOverflowCollaborator = (index, groupSize) => {
	if (groupSize > SHOWN_COLLABORATORS) {
		return index + 1 >= SHOWN_COLLABORATORS;
	}

	return index === groupSize;
};

export interface Collaborator {
	name: string;
	color: string;
	onClick?: () => void;
	avatar: {
		src?: string;
	};
	followCursor?: boolean;
}

export interface Collaborators {
	collaborators: Collaborator[];
	me: Collaborator | null;
}

interface CollaboratorProps {
	collaboratorData: Collaborators;
	handleRegisterOnShowCollaboratorSpotlight?: (callback: () => void) => void;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	display: 'flex',
	marginRight: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarSkeleton = styled.div({
	width: '36px',
	height: '36px',
	borderRadius: '50%',
	background: token('color.skeleton'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarGroupContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginRight: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		content: "''",
		border: `1px solid ${token('color.border')}`,
		height: '16px',
		margin: `${token('space.0')} ${token('space.050')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > ul': {
		marginLeft: token('space.050'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollaboratorWhiteBorder = styled.div<{ backgroundColor: string }>({
	width: '36px',
	height: '36px',
	borderRadius: '50%',
	boxShadow: `0 0 0 2px ${token('color.border.inverse')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: `${(props) => props.backgroundColor}`,
});

const i18n = defineMessages({
	followCursor: {
		id: 'content-types-header.collaborators.follow-cursor-message',
		defaultMessage: '{name} (click to follow)',
		description:
			'The message shown when hovering over a collaborator to indicate the name and to follow them.',
	},
	followCursorOnboardingMessage: {
		id: 'content-types-header.collaborators.follow-cursor-onboarding-message',
		defaultMessage: 'Click on anyone’s avatar to follow them around the whiteboard.',
		description:
			'The onboarding message shown over the collaborators list to prompt them to try the follow cursor feature.',
	},
	followCursorOnboardingButton: {
		id: 'content-types-header.collaborators.follow-cursor-onboarding-button',
		defaultMessage: 'OK',
		description:
			'The onboarding button CTA shown over the collaborators list to prompt them to try the follow cursor feature.',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpotlightWrapper = styled.div({
	position: 'absolute',
	transform: 'translateX(-100%)',
	left: `calc(100% - ${token('space.600')} - ${token('space.150')})`,
	top: token('space.800'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpotlightPulseContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	padding: `${token('space.050')} 0px ${token('space.050')} ${token('space.050')}`,
	marginTop: `-${token('space.025')}`,
	marginBottom: `-${token('space.025')}`,
	boxSizing: 'border-box',
});

export const CollaboratorsComponent: FC<CollaboratorProps> = ({
	collaboratorData,
	handleRegisterOnShowCollaboratorSpotlight,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isSpotlightActive, setIsSpotlightActive] = useState(false);

	const [hasStartedFollowCursorCoordination, stopFollowCursorCoordination] =
		useSkippableCoordination('cc-whiteboards-onboarding-follow-cursor', !isSpotlightActive);

	const onEndSpotlight = useCallback(
		(ctaClose: boolean) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'closed',
					actionSubject: 'changeboarding',
					attributes: {
						feature: 'followCursor',
						ctaClose,
					},
					source: 'contentTypesHeader',
				},
			}).fire();
			setIsSpotlightActive(false);
			void stopFollowCursorCoordination();
		},
		[createAnalyticsEvent, stopFollowCursorCoordination],
	);

	useEffect(() => {
		handleRegisterOnShowCollaboratorSpotlight?.(() => {
			setIsSpotlightActive(true);
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'changeboarding',
					attributes: {
						feature: 'followCursor',
					},
					source: 'contentTypesHeader',
				},
			}).fire();
		});
	}, [createAnalyticsEvent, handleRegisterOnShowCollaboratorSpotlight]);

	const prefersReducedMotion = useIsReducedMotion();

	const isPulsing =
		hasStartedFollowCursorCoordination && !prefersReducedMotion && isSpotlightActive;

	const onEndSpotlightHandler = isSpotlightActive ? onEndSpotlight : undefined;

	if (!collaboratorData?.me && collaboratorData.collaborators.length === 0) {
		return (
			<AvatarContainer>
				<AvatarSkeleton data-testid="content-types-header-avatar-skeleton" />
			</AvatarContainer>
		);
	}

	const data = collaboratorData?.collaborators.map((user, index) => {
		const followCursorMessage = intl.formatMessage(i18n.followCursor, {
			name: user.name,
		});
		const numCollaborators = collaboratorData?.collaborators.length;

		const onClick =
			user.followCursor === true
				? () => {
						user.onClick?.();
						onEndSpotlightHandler?.(false);
					}
				: user.onClick;

		return {
			name:
				user.followCursor === true && !isOverflowCollaborator(index, numCollaborators)
					? followCursorMessage
					: user.name,
			onClick,
			children: isOverflowCollaborator(index, numCollaborators) ? (
				<Avatar src={user.avatar.src} borderColor={user.color} />
			) : (
				<CollaboratorWhiteBorder backgroundColor={user.color}>
					<Avatar src={user.avatar.src} borderColor={user.color} onClick={onClick} />
				</CollaboratorWhiteBorder>
			),
		};
	});

	// Coordination has started, internal state is true and we have collaborators to follow
	const showSpotlight = hasStartedFollowCursorCoordination && isSpotlightActive && !!data?.length;

	const WithSpotlight = ({ children }) =>
		showSpotlight ? (
			<SpotlightPulse radius={4} pulse={isPulsing}>
				<SpotlightPulseContainer>{children}</SpotlightPulseContainer>
			</SpotlightPulse>
		) : (
			children
		);

	return (
		<>
			<WithSpotlight>
				{collaboratorData.me && (
					<Tooltip content={collaboratorData.me?.name}>
						<AvatarContainer>
							<Avatar
								name={collaboratorData.me?.name}
								src={collaboratorData.me?.avatar.src}
								borderColor={collaboratorData.me?.color}
								testId="content-types-header-user"
							/>
						</AvatarContainer>
					</Tooltip>
				)}
				{!!data?.length && (
					<AvatarGroupContainer>
						<AvatarGroup
							appearance="stack"
							maxCount={SHOWN_COLLABORATORS}
							data={data}
							testId="content-types-header-collaborators"
						/>
					</AvatarGroupContainer>
				)}
			</WithSpotlight>
			{showSpotlight && (
				<SpotlightTransition>
					<SpotlightWrapper data-testid="more-actions-spotlight">
						<SpotlightCard
							actions={[
								{
									onClick: () => onEndSpotlight(true),
									text: intl.formatMessage(i18n.followCursorOnboardingButton),
									testId: 'onboarding-follow-cursor-spotlight-button',
								},
							]}
							key="more-actions-menu"
							width={287}
							image={FollowCursorOnboardingHeader}
						>
							{intl.formatMessage(i18n.followCursorOnboardingMessage)}
						</SpotlightCard>
					</SpotlightWrapper>
				</SpotlightTransition>
			)}
		</>
	);
};
