import type { FC } from 'react';
import React from 'react';

import type { CollabEditOptions } from '@atlaskit/editor-common/collab';

import { fg } from '@confluence/feature-gating';
import { ContentHeaderPresenceLoader } from '@confluence/team-presence/entry-points/ContentHeaderPresenceLoader';
import type { ShareContentType } from '@confluence/share';
import { EditorPresenceAvatarGroupLoader } from '@confluence/editor-presence-avatar-group';

type PresenceProps = {
	contentId: string;
	contentType: ShareContentType;
	contentMode: string | undefined;
	collabEditOption?: CollabEditOptions;
};

// only contentType page and blogpost uses Presence until the new ContentHeaderPresenceLoader handles other content types as well
export const Presence: FC<PresenceProps> = ({
	contentId,
	contentType,
	contentMode,
	collabEditOption,
}) => {
	if (fg('confluence_team_presence_general_availability')) {
		return (
			<ContentHeaderPresenceLoader
				contentId={contentId}
				contentType={contentType}
				analyticSource={contentMode === 'view' ? 'viewPageScreen' : 'editPageScreen'}
			/>
		);
	} else if (contentMode === 'edit' || contentMode === 'draft' || contentMode === 'live') {
		// for edit page, draft pages, & live pages, default to old editor presence if the new presence fg is not on
		return (
			<EditorPresenceAvatarGroupLoader
				isLivePage={contentMode === 'live'}
				collabEditOption={collabEditOption}
			/>
		);
	}

	// else return nothing if new presence fg is not on and user is on view page.
	return null;
};
