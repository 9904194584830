import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Button, { LinkButton } from '@atlaskit/button/new';
import type { Appearance } from '@atlaskit/button/new';
import AkTooltip from '@atlaskit/tooltip/Tooltip';

export interface MainAction {
	id: string;
	text?: string;
	icon?: any;
	onClick?: () => void;
	appearance?: Appearance;
	isSelected?: boolean;
	styles?: {
		// Not used currently
		[prop: string]: string;
	};
	buttonLink?: string;
	openInNewTab?: boolean;
	label?: string;
	tooltip?: string;
}

export interface MainActions {
	mainActions: MainAction[];
}

type ConditionalWrapperChildren = React.PropsWithChildren<any> | null;
type ConditionalWrapperType = {
	condition: boolean;
	wrapper: (children: ConditionalWrapperChildren) => ConditionalWrapperChildren;
	children: ConditionalWrapperChildren;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledMainActions = styled.div({
	margin: `0px ${token('space.025')}`,
});

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperType) =>
	condition ? wrapper(children) : children;

const TooltipWrapper = ({
	mainAction,
	children,
}: {
	mainAction: MainAction;
	children: ConditionalWrapperChildren;
}) => (
	<AkTooltip content={mainAction.tooltip} testId={`${mainAction.id}-tooltip`}>
		{children}
	</AkTooltip>
);

export const MainActionsComponent: FC<MainActions> = ({ mainActions }) => {
	return (
		<>
			{mainActions?.map((mainAction) => (
				<StyledMainActions key={mainAction.id}>
					<ConditionalWrapper
						condition={mainAction.tooltip !== undefined}
						wrapper={(children) => TooltipWrapper({ mainAction, children })}
					>
						{mainAction.buttonLink ? (
							<LinkButton
								href={mainAction.buttonLink}
								appearance={mainAction.appearance}
								isSelected={mainAction.isSelected}
								onClick={mainAction.onClick}
								iconBefore={() => mainAction.icon}
								testId={mainAction.id}
								target={mainAction.openInNewTab ? '_blank' : '_self'}
							>
								{mainAction.text}
							</LinkButton>
						) : (
							<Button
								appearance={mainAction.appearance}
								isSelected={mainAction.isSelected}
								onClick={mainAction.onClick}
								iconBefore={() => mainAction.icon}
								testId={mainAction.id}
							>
								{mainAction.text || mainAction.label}
							</Button>
						)}
					</ConditionalWrapper>
				</StyledMainActions>
			))}
		</>
	);
};
