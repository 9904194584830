import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { xcss, Stack, Text, Pressable, Box } from '@atlaskit/primitives';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';

import {
	useMoreActionsObjectSidebarTourActions,
	MoreActionsObjectSidebarTourSteps,
} from '@confluence/object-sidebar-components';
import { useSetOnboardingState } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

const { IS_MORE_ACTIONS_HINT_VISIBLE } = ONBOARDING_EXPERIENCES;

const i18n = defineMessages({
	tryingToFindSomething: {
		id: 'content-types-header.content-types.more-actions.trying-to-find-something',
		description:
			'A message notifying users that menu items may have been moved to the Details panel in the sidebar',
		defaultMessage:
			'Trying to find something? Check the <strong>Details</strong> panel in the floating action bar. <link>Show me</link>',
	},
	dontTellMeAgain: {
		id: 'content-types-header.content-types.more-actions.dont-tell-me-again',
		description:
			'The label of a button used to dismiss a notification that menu items may have been moved to the Details panel in the sidebar',
		defaultMessage: 'Don’t tell me again',
	},
});

const missingActionsHintMenuItemStyles = xcss({
	margin: 'space.150',
});

const textStyles = xcss({
	width: '216px',
});

const underlinedButtonStyles = xcss({
	color: 'color.text.subtlest',
	textDecoration: 'underline',
	font: token('font.body'),

	':hover': {
		textDecoration: 'none',
	},
});

const linkButtonStyles = xcss({
	color: 'color.link',
	marginLeft: 'space.050',
});

export type MissingActionsHintMenuItemProps = {
	setIsMenuOpen: (isMenuOpen: boolean) => void;
};

export const MissingActionsHintMenuItem = ({ setIsMenuOpen }: MissingActionsHintMenuItemProps) => {
	const { setOnboardingState } = useSetOnboardingState();
	const { goToStep } = useMoreActionsObjectSidebarTourActions();

	return (
		<DropdownItemGroup hasSeparator>
			<Stack alignInline="start" space="space.300" xcss={missingActionsHintMenuItemStyles}>
				<Box xcss={textStyles}>
					<FormattedMessage
						{...i18n.tryingToFindSomething}
						values={{
							strong: (text) => (
								<Text color="color.text.subtlest" as="strong">
									{text}
								</Text>
							),
							link: (text) => (
								<Pressable
									onClick={() => {
										setIsMenuOpen(false);
										goToStep(MoreActionsObjectSidebarTourSteps.SECOND);
									}}
									padding="space.0"
									backgroundColor="color.background.neutral.subtle"
									xcss={linkButtonStyles}
								>
									{text}
								</Pressable>
							),
						}}
					/>
				</Box>
				<Pressable
					onClick={() => setOnboardingState({ key: IS_MORE_ACTIONS_HINT_VISIBLE, value: 'false' })}
					padding="space.0"
					backgroundColor="color.background.neutral.subtle"
					xcss={underlinedButtonStyles}
				>
					<FormattedMessage {...i18n.dontTellMeAgain} />
				</Pressable>
			</Stack>
		</DropdownItemGroup>
	);
};
