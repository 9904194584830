import React from 'react';
import type { FC } from 'react';
import { styled } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';
import Badge from '@atlaskit/badge';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import type { OnOpenChangeArgs } from '@atlaskit/dropdown-menu';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';

export type ExpandableNestedMenuItemItemType = {
	id: string;
	label: string;
	tooltip?: string;
	url: string;
	badgeLabel?: string;
	styleClass?: string; // Some Connect apps have a styleClass to apply custom styles
	customComponent?: () => React.ReactElement;
	closeParentMenu?: () => void; // Need this prop when we don't use a custom component
};

export type ExpandableNestedMenuItemProps = {
	label: string | React.ReactNode;
	items: ExpandableNestedMenuItemItemType[];
	isNestedMenuOpen: boolean;
	onClick: () => void;
	iconBefore?: React.ReactNode;
	additionalMenuItems?: React.ReactNode;
	onMenuClose: () => void;
};

// this is to keep consistent with ContentTools PopupMenuGroup
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledDropdownItemGroup = styled(DropdownItemGroup)({
	minWidth: '220px',
	maxWidth: '800px',
});

// styled(DropdownItem) doesn't apply css to component
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownItemLabelContainer = styled.span({
	display: 'flex',
	justifyContent: 'space-between',
});

const badgeContainerStyles = xcss({
	paddingLeft: 'space.075',
});

// Don't want to apply aui button styles in the dropdown menu since style needs to be uniform
export const getStyleClass = (styleClass?: string) => {
	return !styleClass?.includes('aui-button') ? styleClass : '';
};

export const ExpandableNestedMenuItem: FC<ExpandableNestedMenuItemProps> = ({
	label,
	items,
	isNestedMenuOpen,
	onClick,
	iconBefore,
	additionalMenuItems,
	onMenuClose,
}) => {
	if (!items.length) return null;
	return (
		<DropdownMenu
			placement="left-start"
			isOpen={isNestedMenuOpen}
			// need this as onClose/onBlur handler for proper focus management
			onOpenChange={({ isOpen }: OnOpenChangeArgs) => {
				if (!isOpen) {
					onMenuClose();
				}
			}}
			trigger={({ triggerRef, ...triggerProps }) => (
				<DropdownItem
					{...triggerProps}
					ref={triggerRef}
					onClick={onClick}
					elemAfter={<ChevronRightIcon label="" />}
					elemBefore={iconBefore}
				>
					{label}
				</DropdownItem>
			)}
		>
			<StyledDropdownItemGroup>
				{items.map((item) => {
					if (item.customComponent) {
						return item.customComponent();
					}
					const { id, url, label: itemLabel, badgeLabel, tooltip, closeParentMenu } = item;
					return (
						<div
							// className is needed here for Connect modals to show properly
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={getStyleClass(item?.styleClass)}
							key={id}
						>
							<DropdownItem href={url} onClick={closeParentMenu} UNSAFE_shouldDisableRouterLink>
								<DropdownItemLabelContainer>
									{/* Some Connect apps don't have a label so we'll use the tooltip as the text since it's the next best thing */}
									<span>{itemLabel || tooltip}</span>
									{badgeLabel && (
										<Box xcss={badgeContainerStyles}>
											<Badge>{badgeLabel}</Badge>
										</Box>
									)}
								</DropdownItemLabelContainer>
							</DropdownItem>
						</div>
					);
				})}
				{additionalMenuItems}
			</StyledDropdownItemGroup>
		</DropdownMenu>
	);
};
