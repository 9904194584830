import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';
import debounce from 'lodash/debounce';

import { token } from '@atlaskit/tokens';
import { Inline, Pressable, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';

import type { ContentMode } from '@confluence/content-types-utils';
import type { ShareContentType } from '@confluence/share';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { Link } from '@confluence/route-manager';
import { useSidePanelStateActions } from '@confluence/side-panel-state-container';
import { useDocChangeContext } from '@confluence/editor-features';

import {
	lastEditedHeaderFriendlyFormatter,
	lastEditedTooltipTimestampFormatter,
} from '../LastEditedUtils';

const i18n = defineMessages({
	edited: {
		id: 'content-types-header.last-edited.edited',
		defaultMessage: 'Edited on {timestamp}',
		description: 'Timestamp string that indicates the content was edited at the time in timestamp',
	},
	published: {
		id: 'content-types-header.last-edited.published',
		defaultMessage: 'Published on {timestamp}',
		description:
			'Timestamp string that indicates the content was published at the time in timestamp',
	},
});

const lastEditedStyles = xcss({
	color: 'color.text.subtle',
	alignItems: 'center',
	flexDirection: 'row',
	padding: 'space.050',
	textWrap: 'nowrap',
});

const linkStyles = {
	color: token('color.text.subtle'),
};

const pressableStyles = xcss({
	color: 'color.text.subtle',
	font: token('font.body'),
	':hover': {
		textDecoration: 'underline',
	},
});

export type LastEditedUserShape = {
	isAnonymous: boolean;
	userId: string;
	fullName: string;
	avatarUrl: string;
};

export type LastEditedVersion = {
	by?: LastEditedUserShape;
	contentTypeModified: boolean;
	number: number;
	when: string;
	friendlyWhen: string;
};

export type LastEditedProps = {
	contentId: string;
	version: LastEditedVersion;
	contentType: ShareContentType;
	contentMode: ContentMode | undefined;
	spaceKey: string;
};

export const LastEdited: FC<LastEditedProps> = ({
	contentId,
	version,
	contentType,
	contentMode,
	spaceKey,
}) => {
	const { showSidePanel } = useSidePanelStateActions();
	const { formatMessage, formatTime, formatDate } = useIntl();

	// We need local version state outside of contentTypesHeader query data because backend version takes up to 30 seconds
	// to update. To keep last edited responsive, we need to optimistically update version state for the client on editor changes
	const [recentVersion, setRecentVersion] = useState({
		when: version.when,
	});

	const { subscribeDocChange, unsubscribeDocChange } = useDocChangeContext();

	const prevChangedTimeRef = useRef<boolean>(false);

	useEffect(() => {
		// Make sure that we set the latest version between server and local. In case a newer server version exists
		if (version.when && version.when > recentVersion.when) {
			setRecentVersion({
				when: version.when,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to trigger this on server data change
	}, [version]);

	// Debounce to avoid rapid calls and prevent performance lag
	const debouncedSetRecentVersion = useMemo(
		() =>
			debounce(() => {
				const now = new Date();
				setRecentVersion({
					when: now.toISOString(),
				});
			}, 3000),
		[setRecentVersion],
	);

	const handleDocChange = useCallback(() => {
		// Doc change listener is fired once on page reload, this was causing immediate update to last edited
		// without any changes from the user. Workaround for that here
		if (prevChangedTimeRef.current) {
			debouncedSetRecentVersion();
		}
		prevChangedTimeRef.current = true;
	}, [debouncedSetRecentVersion]);

	// Subscribe to editor changes
	useEffect(() => {
		subscribeDocChange(handleDocChange);
		return () => unsubscribeDocChange(debouncedSetRecentVersion);
	}, [debouncedSetRecentVersion, handleDocChange, subscribeDocChange, unsubscribeDocChange]);

	const createEditorChangeLink = (formattedMessage: ReactNode) => {
		return (
			<Link
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={linkStyles}
				href={`${CONTEXT_PATH}/spaces/${spaceKey}/history/${contentId}`}
			>
				{formattedMessage}
			</Link>
		);
	};

	const handleContentTypesLinkClick = () => {
		showSidePanel();
	};

	const createContentTypesChangeLink = (formattedMessage: ReactNode) => {
		return (
			<Pressable
				xcss={pressableStyles}
				backgroundColor="color.background.neutral.subtle"
				onClick={handleContentTypesLinkClick}
			>
				{formattedMessage}
			</Pressable>
		);
	};

	/**
	 * Formats header timestamp as well as tooltip timestamp
	 */
	const formatTimestamps = () => {
		let headerMessage: ReactNode | null;
		let tooltipMessage: ReactNode | null;

		const lastEditedTime = new Date(recentVersion.when);
		const headerTimestamp = lastEditedHeaderFriendlyFormatter(
			lastEditedTime,
			version.friendlyWhen,
			formatMessage,
		);
		const tooltipTimestamp = lastEditedTooltipTimestampFormatter(
			lastEditedTime,
			version.friendlyWhen,
			formatMessage,
			formatTime,
			formatDate,
		);

		const isPageOrBlog = contentType === 'page' || contentType === 'blogpost';
		if (isPageOrBlog) {
			const hasEditorVersionHistory =
				version.number > 1 && !version.contentTypeModified && spaceKey && contentMode !== 'draft';

			headerMessage = hasEditorVersionHistory
				? createEditorChangeLink(headerTimestamp)
				: headerTimestamp;

			tooltipMessage =
				contentMode === 'view' || contentMode === 'archived'
					? formatMessage(i18n.published, { timestamp: tooltipTimestamp })
					: formatMessage(i18n.edited, { timestamp: tooltipTimestamp });
		} else if (contentType === 'whiteboard' || contentType === 'database') {
			const hasContentTypesVersionHistory =
				version.number > 1 && !version.contentTypeModified && contentType === 'whiteboard';

			headerMessage = hasContentTypesVersionHistory
				? createContentTypesChangeLink(headerTimestamp)
				: headerTimestamp;

			tooltipMessage = formatMessage(i18n.edited, { timestamp: tooltipTimestamp });
		} else {
			headerMessage = null;
			tooltipMessage = null;
		}

		return { headerMessage, tooltipMessage };
	};

	// Ensures that timestamp will only display when most accurate timestamp is available
	if (contentMode === 'edit' && !recentVersion.when) {
		return null;
	}

	const { headerMessage: formattedHeaderMessage, tooltipMessage: formattedTooltipMessage } =
		formatTimestamps();

	// Only show tooltip if header timestamp is not full format > 2 days
	return (
		<Tooltip content={formattedTooltipMessage}>
			<Inline xcss={lastEditedStyles}>{formattedHeaderMessage}</Inline>
		</Tooltip>
	);
};
