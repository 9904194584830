export type HeaderFeaturesType = {
	showMove: boolean;
	showArchive: boolean;
	showCopy: boolean;
	showBreadcrumbs: boolean;
	showFavourite?: boolean;
	showShare?: boolean;
	showMainActions?: boolean;
	showMoreActions?: boolean;
	showClassificationMenuAction?: boolean;
	showContentRestrictions?: boolean;
	showWatchDialog?: boolean;
};

export const DEFAULT_HEADER_FEATURES: HeaderFeaturesType = {
	showMove: true,
	showArchive: true,
	showCopy: true,
	showBreadcrumbs: true,
	showFavourite: true,
	showShare: true,
	showMainActions: true,
	showMoreActions: true,
	showClassificationMenuAction: false,
	showContentRestrictions: true,
	showWatchDialog: true,
};
