import gql from 'graphql-tag';

export const LabelCountQuery = gql`
	query LabelCountQuery($contentId: ID!) {
		content(id: $contentId, status: ["current", "draft"]) {
			nodes {
				id
				labels(orderBy: { sortField: LABELLING_CREATIONDATE, direction: ASCENDING }) {
					count
				}
			}
		}
	}
`;
