import { getApolloClient } from '@confluence/graphql';

import { DeleteDraftMutation } from './DeleteDraftMutation.graphql';
import { ContentDeleteActionType } from './__types__/DeleteDraftMutation';

/**
 * A draft should be deleted if:
 *
 * 1. Is unpublished draft
 * 2. Title is blank
 * 3. Content is blank
 * 4. There are no other collaborators
 * 5. No restrictions are applied
 * 6. No cover photo added
 * 7. No title emoji selected
 * 8. No labels
 */
export const blankDraftInfo = ({
	isUnpublishedDraft,
	isSingleUserSession,
	hasTitle,
	hasBody,
	hasEmoji,
	hasCoverPicture,
	hasContentState,
	hasRestrictions,
	hasLabels,
}: {
	isSingleUserSession: boolean;
	isUnpublishedDraft: boolean;
	hasTitle: boolean;
	hasBody: boolean;
	hasEmoji: boolean;
	hasContentState: boolean;
	hasCoverPicture: boolean;
	hasRestrictions: boolean;
	hasLabels: boolean;
}) => {
	const isBlank =
		isSingleUserSession &&
		isUnpublishedDraft &&
		!hasTitle &&
		!hasBody &&
		!hasEmoji &&
		!hasEmoji &&
		!hasCoverPicture &&
		!hasContentState &&
		!hasRestrictions &&
		!hasLabels;

	return {
		isBlank,
		isUnpublishedDraft,
		isSingleUserSession,
		hasTitle,
		hasBody,
		hasEmoji,
		hasCoverPicture,
		hasContentState,
		hasRestrictions,
		hasLabels,
	};
};

/**
 * Delete draft
 *
 */
type DeleteDraftProps = {
	contentId: string;
	deleteOnlyIfBlank: boolean;
};
export const deleteDraft = ({ contentId, deleteOnlyIfBlank }: DeleteDraftProps) =>
	getApolloClient().mutate({
		mutation: DeleteDraftMutation,
		variables: {
			contentId,
			action: deleteOnlyIfBlank
				? ContentDeleteActionType.DELETE_DRAFT_IF_BLANK
				: ContentDeleteActionType.DELETE_DRAFT,
		},
	});
