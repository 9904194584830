/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PublicLinkPageStatus {
  BLOCKED_BY_CLASSIFICATION_LEVEL = "BLOCKED_BY_CLASSIFICATION_LEVEL",
  BLOCKED_BY_CONTAINER_POLICY = "BLOCKED_BY_CONTAINER_POLICY",
  BLOCKED_BY_ORG = "BLOCKED_BY_ORG",
  BLOCKED_BY_PRODUCT = "BLOCKED_BY_PRODUCT",
  BLOCKED_BY_SPACE = "BLOCKED_BY_SPACE",
  OFF = "OFF",
  ON = "ON",
  SITE_BLOCKED = "SITE_BLOCKED",
  SITE_DISABLED = "SITE_DISABLED",
  SPACE_BLOCKED = "SPACE_BLOCKED",
  SPACE_DISABLED = "SPACE_DISABLED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublicLinkPageQuery
// ====================================================

export interface PublicLinkPageQuery_publicLinkPage {
  pageId: string;
  pageStatus: PublicLinkPageStatus;
  publicLinkUrlPath: string | null;
}

export interface PublicLinkPageQuery {
  publicLinkPage: PublicLinkPageQuery_publicLinkPage | null;
}

export interface PublicLinkPageQueryVariables {
  pageId: string;
}
