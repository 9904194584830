import {
	confluenceLocalStorageInstance,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

export const getIsSideNavCollapsed = () => {
	const sideNavCollapsedValue =
		confluenceLocalStorageInstance.getItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_COLLAPSED,
		) ?? 'expanded';
	return sideNavCollapsedValue === 'collapsed';
};
