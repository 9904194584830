import { fg } from '@confluence/feature-gating';
import { useGetOnboardingState } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

import { isOnboardingStateEqual, IS_DISMISSED_FLAG } from './objectSidebarTourHelpers';

const { IS_MORE_ACTIONS_HINT_VISIBLE } = ONBOARDING_EXPERIENCES;

export const useIsMoreActionsHintDismissed = () => {
	const {
		data: isMoreActionsHintOnboardingStateData,
		error: isMoreActionsHintOnboardingStateError,
		loading: isMoreActionsHintOnboardingStateLoading,
	} = useGetOnboardingState(
		IS_MORE_ACTIONS_HINT_VISIBLE,
		!fg('confluence_frontend_object_sidebar_changeboarding'),
	);

	const isHintDismissed = isMoreActionsHintOnboardingStateData?.onboardingState?.some(
		isOnboardingStateEqual(IS_MORE_ACTIONS_HINT_VISIBLE, IS_DISMISSED_FLAG),
	);

	return (
		!isMoreActionsHintOnboardingStateError &&
		!isMoreActionsHintOnboardingStateLoading &&
		isHintDismissed
	);
};
