/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { GeneralShortcutListener, STAR_BUTTON_SHORTCUT } from '@confluence/shortcuts';
import type { ShortcutEvent } from '@confluence/shortcuts';

import type {
	SpaceStarShortcutListenerIsSpaceFavouritedQuery as SpaceStarShortcutListenerIsSpaceFavouritedQuery$data,
	SpaceStarShortcutListenerIsSpaceFavouritedQueryVariables as SpaceStarShortcutListenerIsSpaceFavouritedQuery$variables,
} from './__types__/SpaceStarShortcutListenerIsSpaceFavouritedQuery';
import { SpaceStar } from './SpaceStar';

// Space starring only works for view mode for space overviews.
// Starring on an edit space overview uses PageStar, not SpaceStar.
export const SpaceStarShortcutListener: React.FC<{
	spaceKey: string;
	contentMode?: string;
	source?: string;
}> = ({ spaceKey, contentMode, source = 'FavouriteMoreActionsMenuItem' }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		data: spaceFavouriteStatusData,
		loading,
		error,
	} = useQuery<
		SpaceStarShortcutListenerIsSpaceFavouritedQuery$data,
		SpaceStarShortcutListenerIsSpaceFavouritedQuery$variables
	>(
		gql`
			query SpaceStarShortcutListenerIsSpaceFavouritedQuery($spaceKey: String!) {
				space(key: $spaceKey) {
					id
					alias
					name
					key
					currentUser {
						isFavourited
					}
				}
			}
		`,
		{
			variables: {
				spaceKey,
			},
			fetchPolicy: 'cache-first',
		},
	);

	if (loading || error) {
		return null;
	}

	const isStarred = spaceFavouriteStatusData?.space?.currentUser?.isFavourited || false;
	const spaceName =
		spaceFavouriteStatusData?.space?.name ||
		spaceFavouriteStatusData?.space?.alias ||
		spaceFavouriteStatusData?.space?.key ||
		'';
	const spaceId = spaceFavouriteStatusData?.space?.id || '';

	const handleShortcut = (toggleStar) => (e: KeyboardEvent | ShortcutEvent) => {
		toggleStar(e);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'spaceStar',
				attributes: {
					triggeredByKeyboardShortcut: true,
					action: isStarred ? 'unstar' : 'star',
					isInEditor: contentMode === 'edit',
				},
				source,
			},
		}).fire();
	};

	return (
		<SpaceStar
			spaceId={spaceId}
			spaceKey={spaceKey}
			isStarred={isStarred}
			spaceName={spaceName}
			shouldShowFlags
		>
			{({ toggle: toggleStar }) => (
				<GeneralShortcutListener
					accelerator={STAR_BUTTON_SHORTCUT}
					listener={handleShortcut(toggleStar)}
				/>
			)}
		</SpaceStar>
	);
};
