/**
 * @graphql cc
 */
import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { differenceInDays } from 'date-fns';

import DropdownMenu, {
	DropdownItem,
	DropdownItemGroup,
	type OnOpenChangeArgs,
} from '@atlaskit/dropdown-menu';
import EyeOpenIcon from '@atlaskit/icon/core/eye-open';
import EyeOpenFilledIcon from '@atlaskit/icon/core/eye-open-filled';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { useSessionData, AccessStatus } from '@confluence/session-data';
import {
	ManageWatcherDialogLoader,
	MANAGE_WATCHER_TAB_TYPES,
} from '@confluence/manage-watcher-dialog';
import { DialogsStateContainer } from '@confluence/dialogs';
import { withFlags, type FlagsStateContainer } from '@confluence/flags';
import { fg } from '@confluence/feature-gating';

import type { WatchSettingsItemTrialDetailsQuery as WatchSettingsItemTrialDetailsQuery$data } from './__types__/WatchSettingsItemTrialDetailsQuery';
import { WatchSettingsCheckboxItems } from './WatchSettingsCheckboxItems';
import { UpdateContentType } from './UpdateType';
import { CONFLUENCE_EDITION } from './watch-constants.js';
import { getWatchDialogQuery } from './getWatchDialogQuery';
import { processWatchData } from './processWatchData';

const i18n = defineMessages({
	watchSettings: {
		id: 'watch-dialog.overflow-menu.watch-settings',
		defaultMessage: 'Watch settings',
		description: 'Label for item in the overflow menu to open the watch settings menu',
	},
	manageWatchers: {
		id: 'watch-dialog.overflow-menu.manage-watchers',
		defaultMessage: 'Manage watchers',
		description: 'Label for a dropdown item option to manage the watchers on content',
	},
	watchingIconLabel: {
		id: 'watch-dialog.overflow-menu.watching-icon-label',
		defaultMessage: 'Watching content',
		description: 'Label for the eye icon indicating that the user is watching content',
	},
	notWatchingIconLabel: {
		id: 'watch-dialog.overflow-menu.not-watching-icon-label',
		defaultMessage: 'Not watching content',
		description: 'Label for the eye icon indicating that the user is not watching content',
	},
});

export enum WatchSettingsRadioOption {
	Unwatch = 'unwatch',
	ThisItem = 'this-item',
	ThisAndNewItems = 'this-and-new-items',
	AllContent = 'all-content',
}

type WatchSettingsItemComponentProps = {
	isMenuOpen: boolean;
	contentId: string;
	contentType: string;
	spaceId: string | undefined;
	spaceKey: string;
	setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	flags: FlagsStateContainer;
	onMenuClose: () => void;
	onClick: () => void;
};

export const WatchSettingsItemComponent: React.FC<WatchSettingsItemComponentProps> = ({
	isMenuOpen,
	contentId,
	contentType,
	spaceId = '',
	spaceKey,
	setIsMenuOpen,
	flags,
	onMenuClose,
	onClick,
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const updateQueryHandler = (cache: any, watchTarget: UpdateContentType, newDataValue: any) => {
		const query = getWatchDialogQuery();
		const contentData = cache.readQuery({
			query,
			variables: { contentId },
		});

		switch (watchTarget) {
			case UpdateContentType.CONTENT:
				contentData.singleContent.currentUserIsWatching = newDataValue;
				break;
			case UpdateContentType.CHILDREN:
				contentData.singleContent.currentUserIsWatchingChildren = newDataValue;
				break;
			case UpdateContentType.SPACE:
				contentData.singleContent.space.currentUser.isWatched = newDataValue;
				break;
			default:
				contentData.singleContent.space.currentUser.isWatchingBlogs = newDataValue;
				break;
		}

		cache.writeQuery({
			query,
			data: contentData,
		});
	};

	const { accessStatus, edition } = useSessionData();
	const isLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
	const {
		data: watchDialogData,
		error: watchDialogError,
		loading: watchDialogDataLoading,
	} = useQuery(getWatchDialogQuery(), {
		variables: { contentId },
		skip: !isLicensed,
		onError: (error) => {
			if (isUnauthorizedError(error)) {
				markErrorAsHandled(error);
			}
		},
	});

	const {
		isCurrentUserWatching,
		isWatchingSpace,
		isWatchingBlogs,
		isWatchingChildren,
		hasAncestorWatchingChildren,
	} = processWatchData(watchDialogData);

	const isWatching = isLicensed && (isCurrentUserWatching || isWatchingSpace);

	const { data: watchSettingsTrialDetailsData, error: watchSettingsTrialDetailsError } =
		useQuery<WatchSettingsItemTrialDetailsQuery$data>(
			gql`
				query WatchSettingsItemTrialDetailsQuery {
					license {
						trialEndDate
					}
				}
			`,
			{
				skip: !isLicensed || edition === CONFLUENCE_EDITION.FREE,
				onError: (error) => {
					if (isUnauthorizedError(error)) {
						markErrorAsHandled(error);
					}
				},
			},
		);

	const handleWatchSettingsItemClick = useCallback(() => {
		onClick();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'content-watch-button',
				attributes: {
					isContentWrapper: fg('confluence_frontend_object_header'),
				},
				source: 'overflowMenu',
			},
		}).fire();
	}, [createAnalyticsEvent, onClick]);

	const makeHandleManageWatchersClickHandler = (dialogs) => {
		return () => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'ManageWatcherDialogButton',
					source: 'overflowMenu',
					attributes: {
						source: 'v2',
						isContentWrapper: fg('confluence_frontend_object_header'),
					},
				},
			}).fire();
			dialogs.showDialog(ManageWatcherDialogLoader, {
				contentId,
				spaceId,
				spaceKey,
				contentType,
				defaultTab: MANAGE_WATCHER_TAB_TYPES.PAGE,
			});
			setIsMenuOpen(false);
		};
	};

	const isSpaceAdmin =
		watchDialogData?.singleContent?.space?.operations?.some(
			(op) => op.operation === 'administer',
		) ?? false;
	let inTrial = false;
	if (watchSettingsTrialDetailsError) {
		inTrial = true;
	} else {
		const trialEndDate = watchSettingsTrialDetailsData?.license?.trialEndDate;
		if (trialEndDate) {
			// +1 day to round up for fractional days
			const trialDaysLeft = differenceInDays(new Date(trialEndDate), new Date()) + 1;
			inTrial = trialDaysLeft !== null && trialDaysLeft > 0;
		}
	}

	return (
		<DropdownMenu
			placement="left-start"
			isOpen={isMenuOpen}
			onOpenChange={({ isOpen }: OnOpenChangeArgs) => {
				if (!isOpen) {
					onMenuClose();
				}
			}}
			trigger={({ triggerRef, ...triggerProps }) => (
				<DropdownItem
					{...triggerProps}
					ref={triggerRef}
					isDisabled={!isLicensed || watchDialogDataLoading}
					elemBefore={
						isWatching ? (
							<EyeOpenFilledIcon label={formatMessage(i18n.watchingIconLabel)} />
						) : (
							<EyeOpenIcon label={formatMessage(i18n.notWatchingIconLabel)} />
						)
					}
					elemAfter={<ChevronRightIcon label="" />}
					testId="nested-watch-menu-trigger"
					onClick={handleWatchSettingsItemClick}
				>
					<FormattedMessage {...i18n.watchSettings} />
				</DropdownItem>
			)}
		>
			<WatchSettingsCheckboxItems
				isCurrentUserWatching={isCurrentUserWatching}
				isWatchingSpace={isWatchingSpace}
				isWatchingBlogs={isWatchingBlogs}
				isWatchingChildren={isWatchingChildren}
				hasAncestorWatchingChildren={hasAncestorWatchingChildren}
				contentId={contentId}
				contentType={contentType}
				spaceId={spaceId}
				updateQueryHandler={updateQueryHandler}
				flags={flags}
			/>
			{isSpaceAdmin && edition !== CONFLUENCE_EDITION.FREE && !inTrial && !watchDialogError && (
				<DropdownItemGroup hasSeparator>
					<Subscribe to={[DialogsStateContainer]}>
						{(dialogs) => (
							<DropdownItem onClick={makeHandleManageWatchersClickHandler(dialogs)}>
								<FormattedMessage {...i18n.manageWatchers} />
							</DropdownItem>
						)}
					</Subscribe>
				</DropdownItemGroup>
			)}
		</DropdownMenu>
	);
};

export const WatchSettingsItem = withFlags(WatchSettingsItemComponent);
