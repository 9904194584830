import { useCallback, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useIntl } from 'react-intl-next';

import type { FlagsStateContainer } from '@confluence/flags';

import type {
	experimentalRequestAccessMutation,
	experimentalRequestAccessMutationVariables,
} from './__types__/experimentalRequestAccessMutation';
import { experimentalRequestAccessMutation as RequestAccessMutation } from './RequestAccessMutation.experimentalgraphql.js';
import { messages } from './messages';

const REQUEST_SENT_FLAG_ID = 'request-to-edit-sent';

export type TriggerAnalyticsEventType = (
	eventName: string,
	extraData?: { [key: string]: any },
) => Promise<void>;

export type RequestToEditContentProps = {
	contentId: string | undefined | null;
	flags?: FlagsStateContainer;
	triggerAnalyticsEvent?: TriggerAnalyticsEventType;
};

export const useRequestToEditContent = ({
	contentId,
	flags,
	triggerAnalyticsEvent,
}: RequestToEditContentProps) => {
	const [requestSent, setRequestSent] = useState<boolean>(false);
	const intl = useIntl();

	const showRequestToEditFailedFlag = useCallback(() => {
		if (triggerAnalyticsEvent) {
			void triggerAnalyticsEvent('content.request.to.edit.failed');
		}

		if (!flags) {
			return;
		}
		void flags.showWarningFlag({
			title: intl.formatMessage(messages.notSentFlagTitle),
			description: intl.formatMessage(messages.notSentFlagDescription),
			appearance: 'warning',
			isAutoDismiss: true,
		});
	}, [flags, intl, triggerAnalyticsEvent]);

	const showRequestToEditSentFlag = useCallback(
		(ownerName: string) => {
			if (triggerAnalyticsEvent) {
				void triggerAnalyticsEvent('content.request.to.edit.success');
			}

			if (!flags) {
				return;
			}
			void flags.showSuccessFlag({
				id: REQUEST_SENT_FLAG_ID,
				title: intl.formatMessage(messages.requestSentFlagTitle),
				description: intl.formatMessage(messages.requestSentFlagDescription, {
					ownerName,
				}),
				actions: [
					{
						content: intl.formatMessage(messages.requestSentFlagAction),
						onClick: () => flags.hideFlag(REQUEST_SENT_FLAG_ID),
					},
				],
			});
		},
		[flags, intl, triggerAnalyticsEvent],
	);

	const handleRequestComplete = (result: experimentalRequestAccessMutation) => {
		if (result?.experimentalRequestAccess?.complete) {
			setRequestSent(true);
			const ownerName = result?.experimentalRequestAccess?.fullName || '';
			showRequestToEditSentFlag(ownerName);
		} else {
			showRequestToEditFailedFlag();
			setRequestSent(false);
		}
	};
	const handleRequestError = () => {
		showRequestToEditFailedFlag();
		setRequestSent(false);
	};

	const [requestToEditContent, { loading }] = useMutation<
		experimentalRequestAccessMutation,
		experimentalRequestAccessMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		RequestAccessMutation,
		{
			variables: { contentId: contentId || '', accessType: 'edit' },
			onCompleted: handleRequestComplete,
			onError: handleRequestError,
		},
	);

	return {
		requestToEditContent,
		loading,
		hasRequestedEditPermission: requestSent,
	};
};
