import { useState, useEffect } from 'react';

import { useNativeCollabConnected } from '@confluence/native-collab';

import { blankDraftInfo } from '../utils/deleteDraft/deleteDraft';

type Props = {
	isUnpublishedDraft: boolean;
	isSingleUserSession: boolean;
	hasTitle: boolean;
	hasBody: boolean;
	hasEmoji: boolean;
	hasCoverPicture: boolean;
	hasContentState: boolean;
	hasRestrictions: boolean;
	hasLabels: boolean;
};

export const useWillDeleteBlankDraftOnClose = ({
	isUnpublishedDraft,
	isSingleUserSession,
	hasTitle,
	hasBody,
	hasEmoji,
	hasCoverPicture,
	hasContentState,
	hasRestrictions,
	hasLabels,
}: Props) => {
	const [willDeleteBlankDraftOnClose, setWillDeleteBlankDraftOnClose] = useState<boolean>(false);
	const [{ collabEditConnected }] = useNativeCollabConnected();

	useEffect(() => {
		// necessary for showing correct tooltip on close button
		const { isBlank } = blankDraftInfo({
			isUnpublishedDraft,
			isSingleUserSession,
			hasTitle,
			hasBody,
			hasEmoji,
			hasCoverPicture,
			hasContentState,
			hasRestrictions,
			hasLabels,
		});
		setWillDeleteBlankDraftOnClose(collabEditConnected && isBlank);
	}, [
		isUnpublishedDraft,
		isSingleUserSession,
		hasTitle,
		hasBody,
		hasEmoji,
		hasCoverPicture,
		hasContentState,
		hasRestrictions,
		hasLabels,
		collabEditConnected,
	]);

	return willDeleteBlankDraftOnClose;
};
