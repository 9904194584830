import { useQuery } from '@apollo/react-hooks';

import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { markErrorAsHandled } from '@confluence/graphql';
import { isUnauthorizedError } from '@confluence/error-boundary';

import { EditorToolbarDockingPreferenceQuery } from './EditorToolbarDockingPreferenceQuery.graphql';
import type { EditorToolbarDockingPreferenceQuery as EditorToolbarDockingPreferenceQueryType } from './__types__/EditorToolbarDockingPreferenceQuery';

// returns true if the editor toolbar is docked at the top (when platform_editor_controls experiment is off, or when it's on and the userPreference is "top" )
// otherwise returns false
export const useIsEditorToolbarDockedAtTop = () => {
	const { data, loading, error } = useQuery<EditorToolbarDockingPreferenceQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		EditorToolbarDockingPreferenceQuery,
	);

	if (editorExperiment('platform_editor_controls', 'variant1')) {
		if (loading) {
			return false;
		}

		if (error) {
			if (isUnauthorizedError(error)) {
				markErrorAsHandled(error);
			}
			// couldn't query for user's docking preference, default to contextual toolbar
			return false;
		}

		// get graphql user preference value
		const dockingPreference =
			data?.userPreferences?.confluenceEditorSettings?.toolbarDockingInitialPosition;
		return dockingPreference === 'top';
	}

	// if experiment is not on, the editor toolbar will always be docked at top
	return true;
};
