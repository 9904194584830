/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import React, { useCallback, useState, useContext } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { useMutation } from 'react-apollo';

import { Box, xcss } from '@atlaskit/primitives';
import { DropdownItemCheckbox, DropdownItemCheckboxGroup } from '@atlaskit/dropdown-menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { FlagsStateContainer } from '@confluence/flags';
import { isSpaceOverview } from '@confluence/named-routes';
import { ContentType } from '@confluence/content-types-utils';
import { fg } from '@confluence/feature-gating';
import {
	ExperienceTrackerContext,
	WATCH_DIALOG_WATCH_CHILDREN_EXPERIENCE,
} from '@confluence/experience-tracker';
import { isUnauthorizedError } from '@confluence/error-boundary';

import type {
	WatchSettingsCheckboxItemsWatchDialogWatchContentMutation as WatchSettingsCheckboxItemsWatchDialogWatchContentMutation$data,
	WatchSettingsCheckboxItemsWatchDialogWatchContentMutationVariables as WatchSettingsCheckboxItemsWatchDialogWatchContentMutation$variables,
} from './__types__/WatchSettingsCheckboxItemsWatchDialogWatchContentMutation';
import type {
	WatchSettingsCheckboxItemsWatchDialogUnwatchContentMutation as WatchSettingsCheckboxItemsWatchDialogUnwatchContentMutation$data,
	WatchSettingsCheckboxItemsWatchDialogUnwatchContentMutationVariables as WatchSettingsCheckboxItemsWatchDialogUnwatchContentMutation$variables,
} from './__types__/WatchSettingsCheckboxItemsWatchDialogUnwatchContentMutation';
import type {
	WatchSettingsCheckboxItemsWatchDialogWatchBlogsMutation as WatchSettingsCheckboxItemsWatchDialogWatchBlogsMutation$data,
	WatchSettingsCheckboxItemsWatchDialogWatchBlogsMutationVariables as WatchSettingsCheckboxItemsWatchDialogWatchBlogsMutation$variables,
} from './__types__/WatchSettingsCheckboxItemsWatchDialogWatchBlogsMutation';
import type {
	WatchSettingsCheckboxItemsWatchDialogUnwatchBlogsMutation as WatchSettingsCheckboxItemsWatchDialogUnwatchBlogsMutation$data,
	WatchSettingsCheckboxItemsWatchDialogUnwatchBlogsMutationVariables as WatchSettingsCheckboxItemsWatchDialogUnwatchBlogsMutation$variables,
} from './__types__/WatchSettingsCheckboxItemsWatchDialogUnwatchBlogsMutation';
import type {
	WatchSettingsCheckboxItemsWatchDialogWatchSpaceMutation as WatchSettingsCheckboxItemsWatchDialogWatchSpaceMutation$data,
	WatchSettingsCheckboxItemsWatchDialogWatchSpaceMutationVariables as WatchSettingsCheckboxItemsWatchDialogWatchSpaceMutation$variables,
} from './__types__/WatchSettingsCheckboxItemsWatchDialogWatchSpaceMutation';
import type {
	WatchSettingsCheckboxItemsWatchDialogUnwatchSpaceMutation as WatchSettingsCheckboxItemsWatchDialogUnwatchSpaceMutation$data,
	WatchSettingsCheckboxItemsWatchDialogUnwatchSpaceMutationVariables as WatchSettingsCheckboxItemsWatchDialogUnwatchSpaceMutation$variables,
} from './__types__/WatchSettingsCheckboxItemsWatchDialogUnwatchSpaceMutation';
import type {
	WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation as WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation$data,
	WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutationVariables as WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation$variables,
} from './__types__/WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation';
import { UpdateContentType, UpdateType } from './UpdateType';

const i18n = defineMessages({
	watchThisContent: {
		id: 'watch-dialog.overflow-menu.watch-this-content',
		defaultMessage: 'Watch this content',
		description:
			'Option in the watch settings checkbox menu to only watch the content the user is viewing',
	},
	watchChildItems: {
		id: 'watch-dialog.overflow-menu.watch-child-items',
		defaultMessage: 'Watch child items',
		description: 'Option in the watch settings checkbox menu to watch children of content',
	},
	watchNewBlogposts: {
		id: 'watch-dialog.overflow-menu.watch-new-blog-posts',
		defaultMessage: 'Watch new blog posts',
		description: 'Option in the watch settings checkbox menu to watch new blogs in the space',
	},
	watchAllContent: {
		id: 'watch-dialog.overflow-menu.watch-all-content',
		defaultMessage: 'Watch all content in this space',
		description:
			'Option in the watch settings checkbox menu to watch all content in the current space',
	},
	watchingContentTitle: {
		id: 'watch-dialog.overflow-menu.watching-content-flag-title',
		defaultMessage: 'You’re watching this content',
		description: 'Title for success flag indicating a user is now watching content',
	},
	watchingContentDescription: {
		id: 'watch-dialog.overflow-menu.watching-content-flag-description',
		defaultMessage: 'You’ll receive email updates about changes to it.',
		description: 'Description for success flag indicating a user is now watching content',
	},
	watchingWhiteboardTitle: {
		id: 'watch-dialog.overflow-menu.watching-whiteboard-flag-title',
		defaultMessage: 'You’re watching this whiteboard',
		description: 'Title for success flag indicating a user is now watching a whiteboard',
	},
	watchingWhiteboardDescription: {
		id: 'watch-dialog.overflow-menu.watching-whiteboard-flag-description',
		defaultMessage: 'You’ll receive email updates about mentions and comment replies.',
		description: 'Description for success flag indicating a user is now watching a whiteboard',
	},
	watchingDatabaseTitle: {
		id: 'watch-dialog.overflow-menu.watching-database-flag-title',
		defaultMessage: 'You’re watching this database',
		description: 'Title for success flag indicating a user is now watching a database',
	},
	watchingDatabaseDescription: {
		id: 'watch-dialog.overflow-menu.watching-database-flag-description',
		defaultMessage: 'You’ll receive email updates about mentions and comment replies.',
		description: 'Description for success flag indicating a user is now watching a database',
	},
	stoppedWatchingContentTitle: {
		id: 'watch-dialog.overflow-menu.stopped-watching-content-flag-title',
		defaultMessage: 'You stopped watching this content',
		description: 'Title for success flag indicating a user has stopped watching content',
	},
	stoppedWatchingContentDescription: {
		id: 'watch-dialog.overflow-menu.stopped-watching-content-flag-description',
		defaultMessage: 'You’ll no longer receive email updates about changes to it.',
		description: 'Description for success flag indicating a user has stopped watching content',
	},
	stoppedWatchingWhiteboardTitle: {
		id: 'watch-dialog.overflow-menu.stopped-watching-whiteboard-flag-title',
		defaultMessage: 'You stopped watching this whiteboard',
		description: 'Title for success flag indicating a user has stopped watching a whiteboard',
	},
	stoppedWatchingWhiteboardDescription: {
		id: 'watch-dialog.overflow-menu.stopped-watching-whiteboard-flag-description',
		defaultMessage: 'You’ll no longer receive email updates about mentions and comment replies.',
		description: 'Description for success flag indicating a user has stopped watching a whiteboard',
	},
	stoppedWatchingDatabaseTitle: {
		id: 'watch-dialog.overflow-menu.stopped-watching-database-flag-title',
		defaultMessage: 'You stopped watching this database',
		description: 'Title for success flag indicating a user has stopped watching a database',
	},
	stoppedWatchingDatabaseDescription: {
		id: 'watch-dialog.overflow-menu.stopped-watching-database-flag-description',
		defaultMessage: 'You’ll no longer receive email updates about mentions and comment replies.',
		description: 'Description for success flag indicating a user has stopped watching a database',
	},
	watchingNewBlogpostsTitle: {
		id: 'watch-dialog.overflow-menu.watching-blogs-flag-title',
		defaultMessage: 'You’re watching for new blog posts',
		description:
			'Title for success flag indicating a user has started watching for blogs in the current space',
	},
	watchingNewBlogpostsDescription: {
		id: 'watch-dialog.overflow-menu.watching-blogs-flag-description',
		defaultMessage: 'You’ll receive email updates about new blog posts in this space.',
		description:
			'Description for success flag indicating a user has started watching for blogs in the current space',
	},
	stoppedWatchingBlogpostsTitle: {
		id: 'watch-dialog.overflow-menu.stopped-watching-blogs-flag-title',
		defaultMessage: 'You stopped watching for new blog posts',
		description:
			'Title for success flag indicating a user has stopped watching for blogs in the current space',
	},
	stoppedWatchingBlogpostsDescription: {
		id: 'watch-dialog.overflow-menu.stopped-watching-blogs-flag-description',
		defaultMessage: 'You’ll no longer receive email updates about new blog posts in this space.',
		description:
			'Description for success flag indicating a user has stopped watching for blogs in the current space',
	},
	watchSpaceTitle: {
		id: 'watch-dialog.overflow-menu.watching-space-flag-title',
		defaultMessage: 'You’re watching content in this space',
		description:
			'Title for success flag indicating a user has started watching all content in the current space',
	},
	watchSpaceDescription: {
		id: 'watch-dialog.overflow-menu.watching-space-flag-description',
		defaultMessage: 'You’ll receive email updates about changes to content in this space.',
		description:
			'Description for success flag indicating a user has started watching all content in the current space',
	},
	stoppedWatchingSpaceTitle: {
		id: 'watch-dialog.overflow-menu.stopped-watching-space-flag-title',
		defaultMessage: 'You stopped watching content in this space',
		description:
			'Title for success flag indicating a user has stopped watching all content in the current space',
	},
	stoppedWatchingSpaceDescription: {
		id: 'watch-dialog.overflow-menu.stopped-watching-space-flag-description',
		defaultMessage:
			'You’ll no longer receive email updates about changes to content in this space.',
		description:
			'Description for success flag indicating a user has stopped watching all content in the current space',
	},
	watchChildrenTitle: {
		id: 'watch-dialog.overflow-menu.watching-children-flag-title',
		defaultMessage: 'You’re watching child items',
		description: 'Title for success flag indicating a user has started watching child items',
	},
	watchChildrenDescription: {
		id: 'watch-dialog.overflow-menu.watching-children-flag-description',
		defaultMessage: 'You’ll receive email updates about changes to this content’s child items.',
		description: 'Description for success flag indicating a user has started watching child items',
	},
	stoppedWatchingChildrenTitle: {
		id: 'watch-dialog.overflow-menu.stopped-watching-children-flag-title',
		defaultMessage: 'You stopped watching child items',
		description: 'Title for success flag indicating a user has stopped watching child items',
	},
	stoppedWatchingChildrenDescription: {
		id: 'watch-dialog.overflow-menu.stopped-watching-children-flag-description',
		defaultMessage:
			'You’ll no longer receive email updates about changes to this content’s child items.',
		description: 'Description for success flag indicating a user has stopped watching child items',
	},
	ancestorWatchStatus: {
		id: 'watch-dialog.overflow-menu.ancestor-watch-status',
		defaultMessage:
			'You watch all items under a parent of this content. Adjust its settings anytime.',
		description:
			'Text that notifies users to change watch settings of ancestor to update current content‘s watch settings',
	},
});

type WatchSettingsCheckboxItemsProps = {
	isCurrentUserWatching: boolean;
	isWatchingSpace: boolean;
	isWatchingBlogs: boolean;
	isWatchingChildren: boolean;
	hasAncestorWatchingChildren: boolean;
	contentType: string;
	contentId: string;
	spaceId: string;
	updateQueryHandler: Function;
	flags: FlagsStateContainer;
};

const ancestorWatchStatusContainer = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingRight: 'space.200',
	paddingLeft: 'space.200',
	maxWidth: '270px',
	font: 'font.body.small',
});

const AncestorWatchStatus = () => {
	return (
		<Box xcss={ancestorWatchStatusContainer}>
			<FormattedMessage {...i18n.ancestorWatchStatus} />
		</Box>
	);
};

export const WatchSettingsCheckboxItems: React.FC<WatchSettingsCheckboxItemsProps> = ({
	isCurrentUserWatching,
	isWatchingSpace,
	isWatchingBlogs,
	isWatchingChildren,
	hasAncestorWatchingChildren,
	contentType,
	contentId,
	spaceId,
	updateQueryHandler,
	flags,
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [isCurrentUserWatchingContent, setIsCurrentUserWatchingContent] =
		useState<boolean>(isCurrentUserWatching);
	const [isCurrentUserWatchingBlogs, setIsCurrentUserWatchingBlogs] =
		useState<boolean>(isWatchingBlogs);
	const [isCurrentUserWatchingSpace, setIsCurrentUserWatchingSpace] =
		useState<boolean>(isWatchingSpace);
	const [isCurrentUserWatchingChildren, setIsCurrentUserWatchingChildren] =
		useState<boolean>(isWatchingChildren);

	const isSpaceOverviewPage = isSpaceOverview();
	const isWatchChildrenRendered =
		contentType !== 'blogpost' && !isSpaceOverviewPage && fg('confluence_watch_children_pages');
	const isAncestorWatchStatusRendered =
		hasAncestorWatchingChildren && fg('confluence_watch_children_pages');

	const watchContentTitleI18nMap = {
		[ContentType.WHITEBOARD]: i18n.watchingWhiteboardTitle,
		[ContentType.DATABASE]: i18n.watchingDatabaseTitle,
		[ContentType.PAGE]: i18n.watchingContentTitle,
		[ContentType.BLOGPOST]: i18n.watchingContentTitle,
	};

	const watchContentDescriptionI18nMap = {
		[ContentType.WHITEBOARD]: i18n.watchingWhiteboardDescription,
		[ContentType.DATABASE]: i18n.watchingDatabaseDescription,
		[ContentType.PAGE]: i18n.watchingContentDescription,
		[ContentType.BLOGPOST]: i18n.watchingContentDescription,
	};

	const stoppedWatchingContentTitleI18nMap = {
		[ContentType.WHITEBOARD]: i18n.stoppedWatchingWhiteboardTitle,
		[ContentType.DATABASE]: i18n.stoppedWatchingDatabaseTitle,
		[ContentType.PAGE]: i18n.stoppedWatchingContentTitle,
		[ContentType.BLOGPOST]: i18n.stoppedWatchingContentTitle,
	};

	const stoppedWatchingContentDescriptionI18nMap = {
		[ContentType.WHITEBOARD]: i18n.stoppedWatchingWhiteboardDescription,
		[ContentType.DATABASE]: i18n.stoppedWatchingDatabaseDescription,
		[ContentType.PAGE]: i18n.stoppedWatchingContentDescription,
		[ContentType.BLOGPOST]: i18n.stoppedWatchingContentDescription,
	};

	const fireWatchAnalyticsEvent = (action: UpdateType, actionSubject: UpdateContentType) => {
		const isActionSubjectSpace = actionSubject === UpdateContentType.SPACE;
		const contentObjectMetadata = {
			objectId: isActionSubjectSpace ? spaceId : contentId,
			objectType: isActionSubjectSpace ? 'space' : contentType,
		};

		const analyticsData = {
			action,
			actionSubject,
			actionSubjectId: contentId,
			source: 'overflowMenu',
			attributes: {
				componentVersion: 'v2',
			},
			...contentObjectMetadata,
		};

		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: analyticsData,
		}).fire();
	};

	const [watchContentMutation] = useMutation<
		WatchSettingsCheckboxItemsWatchDialogWatchContentMutation$data,
		WatchSettingsCheckboxItemsWatchDialogWatchContentMutation$variables
	>(
		gql`
			mutation WatchSettingsCheckboxItemsWatchDialogWatchContentMutation($id: ID!) {
				watchContent(watchContentInput: { contentId: $id, currentUser: true }) {
					content {
						id
						currentUserIsWatching
					}
				}
			}
		`,
		{
			update: (cache, { data }) => {
				updateQueryHandler(
					cache,
					UpdateContentType.CONTENT,
					data?.watchContent?.content?.currentUserIsWatching,
				);
			},
			onCompleted: () => {
				fireWatchAnalyticsEvent(UpdateType.WATCH, UpdateContentType.CONTENT);
				const titleI18n = watchContentTitleI18nMap[contentType];
				const descriptionI18n = watchContentDescriptionI18nMap[contentType];
				void flags.showSuccessCircleFlag({
					id: 'overflow-menu.watch-settings.watch-content-success',
					title: formatMessage(titleI18n),
					description: formatMessage(descriptionI18n),
					isAutoDismiss: true,
				});
			},
		},
	);

	const [unwatchContentMutation] = useMutation<
		WatchSettingsCheckboxItemsWatchDialogUnwatchContentMutation$data,
		WatchSettingsCheckboxItemsWatchDialogUnwatchContentMutation$variables
	>(
		gql`
			mutation WatchSettingsCheckboxItemsWatchDialogUnwatchContentMutation($id: ID!) {
				unwatchContent(watchContentInput: { contentId: $id, currentUser: true }) {
					content {
						id
						currentUserIsWatching
					}
				}
			}
		`,
		{
			update: (cache, { data }) => {
				updateQueryHandler(
					cache,
					UpdateContentType.CONTENT,
					data?.unwatchContent?.content?.currentUserIsWatching,
				);
			},
			onCompleted: () => {
				fireWatchAnalyticsEvent(UpdateType.UNWATCH, UpdateContentType.CONTENT);
				const titleI18n = stoppedWatchingContentTitleI18nMap[contentType];
				const descriptionI18n = stoppedWatchingContentDescriptionI18nMap[contentType];
				void flags.showSuccessCircleFlag({
					id: `overflow-menu.watch-settings.stopped-watching-content-success`,
					title: formatMessage(titleI18n),
					description: formatMessage(descriptionI18n),
					isAutoDismiss: true,
				});
			},
		},
	);

	const [watchBlogsMutation] = useMutation<
		WatchSettingsCheckboxItemsWatchDialogWatchBlogsMutation$data,
		WatchSettingsCheckboxItemsWatchDialogWatchBlogsMutation$variables
	>(
		gql`
			mutation WatchSettingsCheckboxItemsWatchDialogWatchBlogsMutation($spaceId: ID!) {
				watchBlogs(watchSpaceInput: { spaceId: $spaceId, currentUser: true }) {
					space {
						id
						currentUser {
							isWatchingBlogs
						}
					}
				}
			}
		`,
		{
			update: (cache, { data }) => {
				updateQueryHandler(
					cache,
					UpdateContentType.BLOGS_IN_SPACE,
					data?.watchBlogs?.space?.currentUser?.isWatchingBlogs,
				);
			},
			onCompleted: () => {
				fireWatchAnalyticsEvent(UpdateType.WATCH, UpdateContentType.BLOGS_IN_SPACE);
				void flags.showSuccessCircleFlag({
					id: 'overflow-menu.watch-settings.watch-blogs-success',
					title: formatMessage(i18n.watchingNewBlogpostsTitle),
					description: formatMessage(i18n.watchingNewBlogpostsDescription),
					isAutoDismiss: true,
				});
			},
		},
	);

	const [unwatchBlogsMutation] = useMutation<
		WatchSettingsCheckboxItemsWatchDialogUnwatchBlogsMutation$data,
		WatchSettingsCheckboxItemsWatchDialogUnwatchBlogsMutation$variables
	>(
		gql`
			mutation WatchSettingsCheckboxItemsWatchDialogUnwatchBlogsMutation($spaceId: ID!) {
				unwatchBlogs(watchSpaceInput: { spaceId: $spaceId, currentUser: true }) {
					space {
						id
						currentUser {
							isWatchingBlogs
						}
					}
				}
			}
		`,
		{
			update: (cache, { data }) => {
				updateQueryHandler(
					cache,
					UpdateContentType.BLOGS_IN_SPACE,
					data?.unwatchBlogs?.space?.currentUser?.isWatchingBlogs,
				);
			},
			onCompleted: () => {
				fireWatchAnalyticsEvent(UpdateType.UNWATCH, UpdateContentType.BLOGS_IN_SPACE);
				void flags.showSuccessCircleFlag({
					id: 'overflow-menu.watch-settings.unwatch-blogs-success',
					title: formatMessage(i18n.stoppedWatchingBlogpostsTitle),
					description: formatMessage(i18n.stoppedWatchingBlogpostsDescription),
					isAutoDismiss: true,
				});
			},
		},
	);

	const [watchSpaceMutation] = useMutation<
		WatchSettingsCheckboxItemsWatchDialogWatchSpaceMutation$data,
		WatchSettingsCheckboxItemsWatchDialogWatchSpaceMutation$variables
	>(
		gql`
			mutation WatchSettingsCheckboxItemsWatchDialogWatchSpaceMutation($spaceId: ID!) {
				watchSpace(watchSpaceInput: { spaceId: $spaceId, currentUser: true }) {
					space {
						id
						currentUser {
							isWatched
						}
					}
				}
			}
		`,
		{
			update: (cache, { data }) => {
				updateQueryHandler(
					cache,
					UpdateContentType.SPACE,
					data?.watchSpace?.space?.currentUser?.isWatched,
				);
			},
			onCompleted: () => {
				fireWatchAnalyticsEvent(UpdateType.WATCH, UpdateContentType.SPACE);
				void flags.showSuccessCircleFlag({
					id: 'overflow-menu.watch-settings.watch-space-success',
					title: formatMessage(i18n.watchSpaceTitle),
					description: formatMessage(i18n.watchSpaceDescription),
					isAutoDismiss: true,
				});
			},
		},
	);

	const [unwatchSpaceMutation] = useMutation<
		WatchSettingsCheckboxItemsWatchDialogUnwatchSpaceMutation$data,
		WatchSettingsCheckboxItemsWatchDialogUnwatchSpaceMutation$variables
	>(
		gql`
			mutation WatchSettingsCheckboxItemsWatchDialogUnwatchSpaceMutation($spaceId: ID!) {
				unwatchSpace(watchSpaceInput: { spaceId: $spaceId, currentUser: true }) {
					space {
						id
						currentUser {
							isWatched
						}
					}
				}
			}
		`,
		{
			update: (cache, { data }) => {
				updateQueryHandler(
					cache,
					UpdateContentType.SPACE,
					data?.unwatchSpace?.space?.currentUser?.isWatched,
				);
			},
			onCompleted: () => {
				fireWatchAnalyticsEvent(UpdateType.UNWATCH, UpdateContentType.SPACE);
				void flags.showSuccessCircleFlag({
					id: 'overflow-menu.watch-settings.unwatch-space-success',
					title: formatMessage(i18n.stoppedWatchingSpaceTitle),
					description: formatMessage(i18n.stoppedWatchingSpaceDescription),
					isAutoDismiss: true,
				});
			},
		},
	);

	const [watchChildrenMutation] = useMutation<
		WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation$data,
		WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation$variables
	>(
		gql`
			mutation WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation(
				$id: ID!
				$includeChildren: Boolean
			) {
				watchContent(
					watchContentInput: {
						contentId: $id
						currentUser: true
						includeChildren: $includeChildren
					}
				) {
					content {
						id
						currentUserIsWatchingChildren
					}
				}
			}
		`,
		{
			update: (cache, { data }) => {
				updateQueryHandler(
					cache,
					UpdateContentType.CHILDREN,
					data?.watchContent?.content.currentUserIsWatchingChildren,
				);
			},
			onCompleted: () => {
				experienceTracker.succeed({
					name: WATCH_DIALOG_WATCH_CHILDREN_EXPERIENCE,
					attributes: {
						startWatchingChildren: true,
						contentType,
					},
				});
				fireWatchAnalyticsEvent(UpdateType.WATCH, UpdateContentType.CHILDREN);
				void flags.showSuccessCircleFlag({
					id: 'overflow-menu.watch-settings.watch-children-success',
					title: formatMessage(i18n.watchChildrenTitle),
					description: formatMessage(i18n.watchChildrenDescription),
					isAutoDismiss: true,
				});
			},
			onError: (mutationError) => {
				handleWatchChildrenError(mutationError, true);
			},
		},
	);

	const [unwatchChildrenMutation] = useMutation<
		WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation$data,
		WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation$variables
	>(
		gql`
			mutation WatchSettingsCheckboxItemsWatchDialogWatchChildrenMutation(
				$id: ID!
				$includeChildren: Boolean
			) {
				watchContent(
					watchContentInput: {
						contentId: $id
						currentUser: true
						includeChildren: $includeChildren
					}
				) {
					content {
						id
						currentUserIsWatchingChildren
					}
				}
			}
		`,
		{
			update: (cache, { data }) => {
				updateQueryHandler(
					cache,
					UpdateContentType.CHILDREN,
					data?.watchContent?.content.currentUserIsWatchingChildren,
				);
			},
			onCompleted: () => {
				experienceTracker.succeed({
					name: WATCH_DIALOG_WATCH_CHILDREN_EXPERIENCE,
					attributes: {
						startWatchingChildren: false,
						contentType,
					},
				});
				fireWatchAnalyticsEvent(UpdateType.UNWATCH, UpdateContentType.CHILDREN);
				void flags.showSuccessCircleFlag({
					id: 'overflow-menu.watch-settings.stopped-watching-children-success',
					title: formatMessage(i18n.stoppedWatchingChildrenTitle),
					description: formatMessage(i18n.stoppedWatchingChildrenDescription),
					isAutoDismiss: true,
				});
			},
			onError: (mutationError) => {
				handleWatchChildrenError(mutationError, false);
			},
		},
	);
	const handleWatchChildrenError = (mutationError, startWatchingChildren) => {
		if (isUnauthorizedError(mutationError)) {
			experienceTracker.abort({
				name: WATCH_DIALOG_WATCH_CHILDREN_EXPERIENCE,
				reason: mutationError.message,
				attributes: {
					startWatchingChildren,
					contentType,
					isUnauthorized: true,
				},
			});
		} else {
			experienceTracker.fail({
				name: WATCH_DIALOG_WATCH_CHILDREN_EXPERIENCE,
				error: mutationError.message,
				attributes: {
					startWatchingChildren,
					contentType,
				},
			});
		}
	};

	const onWatchContentChange = useCallback(async () => {
		if (isCurrentUserWatchingContent) {
			await unwatchContentMutation({
				variables: { id: contentId },
				optimisticResponse: {
					__typename: 'Mutation',
					unwatchContent: {
						__typename: 'WatchContentPayload',
						content: {
							__typename: 'Content',
							id: contentId,
							currentUserIsWatching: false,
						},
					},
				},
			});
			setIsCurrentUserWatchingContent(false);
		} else {
			await watchContentMutation({
				variables: { id: contentId },
				optimisticResponse: {
					__typename: 'Mutation',
					watchContent: {
						__typename: 'WatchContentPayload',
						content: {
							__typename: 'Content',
							id: contentId,
							currentUserIsWatching: true,
						},
					},
				},
			});
			setIsCurrentUserWatchingContent(true);
		}
	}, [isCurrentUserWatchingContent, contentId, unwatchContentMutation, watchContentMutation]);

	const onWatchBlogpostsChange = useCallback(async () => {
		if (isCurrentUserWatchingBlogs) {
			await unwatchBlogsMutation({
				variables: { spaceId },
				optimisticResponse: {
					__typename: 'Mutation',
					unwatchBlogs: {
						__typename: 'WatchSpacePayload',
						space: {
							__typename: 'Space',
							id: spaceId,
							currentUser: {
								__typename: 'SpaceUserMetadata',
								isWatchingBlogs: false,
							},
						},
					},
				},
			});
			setIsCurrentUserWatchingBlogs(false);
		} else {
			await watchBlogsMutation({
				variables: { spaceId },
				optimisticResponse: {
					__typename: 'Mutation',
					watchBlogs: {
						__typename: 'WatchSpacePayload',
						space: {
							__typename: 'Space',
							id: spaceId,
							currentUser: {
								__typename: 'SpaceUserMetadata',
								isWatchingBlogs: true,
							},
						},
					},
				},
			});
			setIsCurrentUserWatchingBlogs(true);
		}
	}, [isCurrentUserWatchingBlogs, spaceId, unwatchBlogsMutation, watchBlogsMutation]);

	const onWatchSpaceChange = useCallback(async () => {
		if (isCurrentUserWatchingSpace) {
			await unwatchSpaceMutation({
				variables: { spaceId },
				optimisticResponse: {
					__typename: 'Mutation',
					unwatchSpace: {
						__typename: 'WatchSpacePayload',
						space: {
							__typename: 'Space',
							id: spaceId,
							currentUser: {
								__typename: 'SpaceUserMetadata',
								isWatched: false,
							},
						},
					},
				},
			});
			setIsCurrentUserWatchingSpace(false);
		} else {
			await watchSpaceMutation({
				variables: { spaceId },
				optimisticResponse: {
					__typename: 'Mutation',
					watchSpace: {
						__typename: 'WatchSpacePayload',
						space: {
							__typename: 'Space',
							id: spaceId,
							currentUser: {
								__typename: 'SpaceUserMetadata',
								isWatched: true,
							},
						},
					},
				},
			});
			setIsCurrentUserWatchingSpace(true);
		}
	}, [isCurrentUserWatchingSpace, spaceId, unwatchSpaceMutation, watchSpaceMutation]);

	const onWatchChildrenChange = useCallback(async () => {
		experienceTracker.start({
			name: WATCH_DIALOG_WATCH_CHILDREN_EXPERIENCE,
			attributes: {
				startWatchingChildren: !isCurrentUserWatchingChildren,
				contentType,
			},
		});
		if (isCurrentUserWatchingChildren) {
			await unwatchChildrenMutation({
				variables: { id: contentId, includeChildren: false },
				optimisticResponse: {
					__typename: 'Mutation',
					watchContent: {
						__typename: 'WatchContentPayload',
						content: {
							__typename: 'Content',
							id: contentId,
							currentUserIsWatchingChildren: false,
						},
					},
				},
			});
			setIsCurrentUserWatchingChildren(false);
		} else {
			await watchChildrenMutation({
				variables: { id: contentId, includeChildren: true },
				optimisticResponse: {
					__typename: 'Mutation',
					watchContent: {
						__typename: 'WatchContentPayload',
						content: {
							__typename: 'Content',
							id: contentId,
							currentUserIsWatchingChildren: true,
						},
					},
				},
			});
			setIsCurrentUserWatchingChildren(true);
		}
	}, [
		isCurrentUserWatchingChildren,
		contentId,
		unwatchChildrenMutation,
		watchChildrenMutation,
		contentType,
		experienceTracker,
	]);

	const watchContentCheckbox = () => {
		const isImplicitlyWatching =
			isWatchingSpace ||
			((hasAncestorWatchingChildren || isWatchingChildren) &&
				fg('confluence_watch_children_pages'));

		return (
			<DropdownItemCheckbox
				id="watch-this-content-option"
				testId="watch-this-content-option"
				isSelected={isCurrentUserWatching || isImplicitlyWatching}
				isDisabled={isImplicitlyWatching}
				onClick={onWatchContentChange}
			>
				<FormattedMessage {...i18n.watchThisContent} />
			</DropdownItemCheckbox>
		);
	};

	const watchBlogpostsCheckbox = () => {
		return (
			<DropdownItemCheckbox
				id="watch-blogs-option"
				testId="watch-blogs-option"
				isSelected={isWatchingBlogs || isWatchingSpace}
				isDisabled={isWatchingSpace}
				onClick={onWatchBlogpostsChange}
			>
				<FormattedMessage {...i18n.watchNewBlogposts} />
			</DropdownItemCheckbox>
		);
	};

	const watchChildrenCheckbox = () => {
		return (
			<DropdownItemCheckbox
				id="watch-children-option"
				testId="watch-children-option"
				isSelected={hasAncestorWatchingChildren || isWatchingSpace || isWatchingChildren}
				isDisabled={hasAncestorWatchingChildren || isWatchingSpace}
				onClick={onWatchChildrenChange}
			>
				<FormattedMessage {...i18n.watchChildItems} />
			</DropdownItemCheckbox>
		);
	};

	const watchSpaceCheckbox = () => {
		return (
			<DropdownItemCheckbox
				id="watch-all-content-option"
				testId="watch-all-content-option"
				isSelected={isWatchingSpace}
				onClick={onWatchSpaceChange}
			>
				<FormattedMessage {...i18n.watchAllContent} />
			</DropdownItemCheckbox>
		);
	};

	return (
		<DropdownItemCheckboxGroup id="overflow-menu-watch-settings">
			{isAncestorWatchStatusRendered && <AncestorWatchStatus />}
			{watchContentCheckbox()}
			{isSpaceOverviewPage && watchBlogpostsCheckbox()}
			{isWatchChildrenRendered && watchChildrenCheckbox()}
			{watchSpaceCheckbox()}
		</DropdownItemCheckboxGroup>
	);
};
