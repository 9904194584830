import { useState, useEffect, useCallback } from 'react';
import type { RefObject } from 'react';

type UseIsTitleIntersectingWithHeaderProps = {
	contentId: string;
	objectHeaderRef: RefObject<HTMLDivElement | null>;
	shouldUseProgressiveHeader: boolean;
};

// this hook is used to check if the content title is overlapping with the object header (in view page/blogpost or live page view mode)
// the objectHeaderRef is mapped to the object header element and the content title for the content is obtained via query selector
// We opted for this approach instead of creating a context that wraps the entire PageLayout to pass containerRef and titleRefs across the entire app which can cause unneccessary rerendering.
export const useIsTitleIntersectingWithHeader = ({
	contentId,
	objectHeaderRef,
	shouldUseProgressiveHeader,
}: UseIsTitleIntersectingWithHeaderProps) => {
	const [isIntersecting, setIsIntersecting] = useState(false);

	const checkIntersection = useCallback(() => {
		if (objectHeaderRef?.current) {
			const title =
				document.getElementById('content-title-id') || document.getElementById('editor-title-id');
			if (title) {
				const titleRect = title.getBoundingClientRect();
				const headerRect = objectHeaderRef.current.getBoundingClientRect();
				setIsIntersecting(titleRect.top < headerRect.bottom);
			}
		}
	}, [objectHeaderRef]);

	useEffect(() => {
		// only add progressive header listeners when for view page/blogpost and live pages view mode
		if (shouldUseProgressiveHeader) {
			// need to run this on initialization once in case the page was initially loaded with a non visible title (scrolled down)
			checkIntersection();
			window.addEventListener('scroll', checkIntersection, true);
			window.addEventListener('resize', checkIntersection);
		}
		return () => {
			window.removeEventListener('scroll', checkIntersection, true);
			window.removeEventListener('resize', checkIntersection);
		};
	}, [contentId, objectHeaderRef, checkIntersection, shouldUseProgressiveHeader]);

	return isIntersecting;
};
