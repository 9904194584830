export const getObjectHeaderBreadcrumbsContentTitle = ({
	isSpaceOverviewPage,
	contentMode,
	editorTitle,
	contentTitle,
}: {
	isSpaceOverviewPage: boolean;
	contentMode: string | undefined;
	editorTitle: string | null;
	contentTitle: string | null | undefined;
}): string | undefined => {
	if (isSpaceOverviewPage) {
		// Pass an undefined string to contentTitle to prevent the breadcrumbs from rendering
		// a title when this is a Space Overview page
		return undefined;
	}

	if (contentMode === 'edit' || contentMode === 'draft') {
		// pass the editorTitle as-is, even if it is an empty string
		// also intentionally exclude live mode, we want to return published content title since live mode publishes their title on every change
		return editorTitle ?? undefined;
	}

	return contentTitle ?? undefined;
};
