import React, { useEffect } from 'react';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

type VersionHistoryOnboardingSpotlightProps = {
	children: React.ReactNode;
	contentType: string | null | undefined;
	source: string;
};

// These custom styles are neccessary so the SpotlightPulse doesn't get cut off by the bounds of the menu.
const spotlightStyles = css({
	marginLeft: token('space.025'),
	marginRight: token('space.025'),
});

export const VersionHistoryOnboardingSpotlight = ({
	children,
	contentType,
	source,
}: VersionHistoryOnboardingSpotlightProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'versionHistorySpotlightPulse',
				attributes: {
					source,
					objectType: contentType,
				},
			},
		}).fire();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
		<SpotlightPulse data-testId="version-history-spotlight-pulse" radius={3} css={spotlightStyles}>
			{children}
		</SpotlightPulse>
	);
};
