import React from 'react';
import type { FC } from 'react';
import { useQuery } from 'react-apollo';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	GeneralShortcutListener,
	STAR_BUTTON_SHORTCUT,
	STAR_BUTTON_SHORTCUT_IN_EDIT,
} from '@confluence/shortcuts';
import type { ShortcutEvent } from '@confluence/shortcuts';
import { fg } from '@confluence/feature-gating';
import { PageStar } from '@confluence/action-buttons/entry-points/pageStar';

import { FavouriteButtonQuery } from './FavouriteButtonQuery.graphql';
import type {
	FavouriteButtonQuery as FavouriteButtonQueryType,
	FavouriteButtonQueryVariables,
} from './__types__/FavouriteButtonQuery';

export const getStarShortcut = (contentType?: string, contentMode?: string) => {
	if (contentType === 'page' || contentType === 'blogpost') {
		// pages and live page shortcuts are behind their own shortcut gate
		if (
			contentMode === 'live' ||
			(contentMode === 'edit' && fg('confluence_frontend_edit_page_keyboard_shortcut')) ||
			contentMode === 'draft'
		) {
			return STAR_BUTTON_SHORTCUT_IN_EDIT;
		} else if (contentMode === 'view') {
			return STAR_BUTTON_SHORTCUT;
		} else {
			return null;
		}
	} else {
		// for all other content types, use F keyboard shortcut
		return STAR_BUTTON_SHORTCUT;
	}
};

type FavouriteContentShortcutListenerType = {
	contentId: string;
	contentTitle: string;
	contentMode?: string;
	contentType?: string;
	source?: string;
};

export const FavouriteContentShortcutListener: FC<FavouriteContentShortcutListenerType> = ({
	contentId,
	contentTitle,
	contentMode,
	contentType,
	source = 'FavouriteMoreActionsMenuItem',
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const shortcut = getStarShortcut(contentType, contentMode);

	const { data, loading, error } = useQuery<
		FavouriteButtonQueryType,
		FavouriteButtonQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		FavouriteButtonQuery,
		{
			variables: {
				contentId,
			},
			fetchPolicy: 'cache-first',
		},
	);

	if (loading || error || !shortcut) {
		return null;
	}

	const isStarred = Boolean(data?.singleContent?.metadata.currentuser?.favourited?.isFavourite);

	const handleShortcut = (toggleStar) => (e: KeyboardEvent | ShortcutEvent) => {
		toggleStar(e);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'pageStar',
				attributes: {
					triggeredByKeyboardShortcut: true,
					action: isStarred ? 'unstar' : 'star',
					isInEditor: contentMode === 'live' || contentMode === 'edit' || contentMode === 'draft',
				},
				source,
			},
		}).fire();
	};

	return (
		<PageStar
			contentId={contentId}
			isStarred={isStarred}
			contentTitle={contentTitle}
			shouldShowFlags
		>
			{({ toggle: toggleStar }) => (
				<GeneralShortcutListener accelerator={shortcut} listener={handleShortcut(toggleStar)} />
			)}
		</PageStar>
	);
};
