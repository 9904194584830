import gql from 'graphql-tag';

export const experimentalRequestAccessMutation = gql`
	mutation experimentalRequestAccessMutation($contentId: String!, $accessType: String!)
	@experimental {
		experimentalRequestAccess(contentId: $contentId, accessType: $accessType) {
			complete
			fullName
		}
	}
`;
