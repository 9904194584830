import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { CSSTransition } from 'react-transition-group';

import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import CloudOfflineIcon from '@atlaskit/icon-lab/core/cloud-offline';
import CheckCircleIcon from '@atlaskit/icon/core/check-circle';
import SyncIcon from '@atlaskit/icon-lab/core/sync';

export enum ContentStatusName {
	OFFLINE = 'offline',
	SAVING = 'saving',
	SAVED = 'saved',
	HIDDEN = 'hidden',
}

export interface ContentStatus {
	status: ContentStatusName;
	tooltipText?: string;
}

type ContentStatusComponentProps = {
	contentStatus: ContentStatus;
};

type ContentStatusIconProps = {
	status: ContentStatusName;
	label?: string;
};

const Icons = {
	saved: CheckCircleIcon,
	saving: SyncIcon,
	offline: CloudOfflineIcon,
};

export const ContentStatusIcon: React.FC<ContentStatusIconProps> = ({ status, label }) => {
	const Icon = Icons[status];

	return <Icon color={token('color.icon.subtle')} label={label} testId={`${status}-icon`} />;
};

export const ContentStatusComponent: FC<ContentStatusComponentProps> = ({ contentStatus }) => {
	const [inProp, setInProp] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(ContentStatusName.HIDDEN);

	useEffect(() => {
		setInProp(contentStatus.status !== currentStatus);
		setCurrentStatus(contentStatus.status);
	}, [contentStatus, currentStatus]);

	return (
		<TransitionGroupComponent data-testid="content-types-content-status">
			<Tooltip content={contentStatus.tooltipText}>
				<CSSTransition
					in={inProp}
					key={currentStatus}
					classNames={`indicator-icon-${contentStatus.status}`}
					timeout={300}
				>
					{contentStatus.status !== ContentStatusName.HIDDEN ? (
						<IndicatorIconContainer>
							<ContentStatusIcon status={contentStatus.status} label={contentStatus.tooltipText} />
						</IndicatorIconContainer>
					) : (
						<div data-testid="content-types-hidden-status" />
					)}
				</CSSTransition>
			</Tooltip>
		</TransitionGroupComponent>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TransitionGroupComponent = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IndicatorIconContainer = styled.div({
	width: '32px',
	height: '32px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '3px',

	'&:hover': {
		backgroundColor: token('color.background.accent.gray.subtlest'),
	},

	// Saving
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes spin': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		from: {
			transform: 'rotate(0deg)',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		to: {
			transform: 'rotate(-360deg)',
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saving-enter': {
		zIndex: '1',
		opacity: '0',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saving-enter-active': {
		zIndex: '1',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& svg': {
			opacity: '1',
			transition: 'opacity 0.2s ease-in',
			transitionDelay: '0.2s',
			animationName: 'spin',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			transformOrigin: 'center',
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saving-exit': {
		zIndex: '1',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& svg': {
			opacity: '1',
			animationName: 'spin',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			transformOrigin: 'center',
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saving-exit-active': {
		zIndex: '1',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& svg': {
			opacity: '0',
			transition: 'opacity 0.2s ease-in',
			animationName: 'spin',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			transformOrigin: 'center',
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saving-exit-done': {
		zIndex: '1',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& svg': {
			opacity: '1',
			animationName: 'spin',
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			transformOrigin: 'center',
		},
	},

	// Saved
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saved-enter': {
		zIndex: '2',
		opacity: '0',
		transform: 'scale(0)',
		transformOrigin: 'center',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saved-enter-active': {
		zIndex: '2',
		opacity: '0',
		transform: 'scale(0)',
		transformOrigin: 'center',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saved-exit': {
		zIndex: '2',
		opacity: '0',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saved-exit-active': {
		zIndex: '2',
		opacity: '0',
		transition: 'opacity 0.2s ease-in',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-saved-exit-done': {
		zIndex: '2',
		opacity: '1',
		transform: 'scale(1)',
		transition: 'all 0.2s ease-in',
		transformOrigin: 'center',
	},

	// Offline
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .strike': {
		strokeDasharray: '13.2',
		strokeDashoffset: '13.2',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& .strike-cutout': {
		transform: 'translate(-60%, 60%)',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-offline-enter': {
		zIndex: '1',
		width: '32px',
		height: '32px',
		opacity: '0',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-offline-enter-active': {
		zIndex: '1',
		opacity: '1',
		transition: 'opacity 0.2s ease-in',
		transitionDelay: '0.2s',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-offline-exit': {
		zIndex: '1',
		opacity: '1',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-offline-exit-active': {
		zIndex: '1',
		opacity: '0',
		transition: 'opacity 0.2s ease-in',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.indicator-icon-offline-exit-done': {
		zIndex: '1',
		opacity: '1',
		transition: 'opacity 0.2s ease-in',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& .strike': {
			strokeDashoffset: '0',
			transition: 'stroke-dashoffset 0.2s 0.3s ease-in',
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& .strike-cutout': {
			transform: 'translate(0, 0)',
			transition: 'transform 0.2s 0.3s ease-in',
		},
	},
});
