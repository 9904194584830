import { useEffect } from 'react';

import { usePublicLinkPageDataProvider } from '@confluence/public-links';
import { markErrorAsHandled } from '@confluence/graphql';
import type { ContentMode } from '@confluence/content-types-utils';

import { useEditorObjectHeaderContext } from './EditorObjectHeaderContext';

export type DisableEditorActionsData = {
	shouldDisableEditorButtons: boolean;
	isPublishDisabledForPublishModal: boolean;
	isPublishDisabled: boolean;
	pageIsExternallyShared: boolean;
};

/**
 * This hook is used to determine what elements should be disabled for editor
 */
export const useDisableEditorActions = (
	contentId: string,
	contentMode: ContentMode | undefined,
): DisableEditorActionsData => {
	// This is only used to disable the buttons in the editor
	const publicLinkData = usePublicLinkPageDataProvider({
		pageId: contentId,
		fetchPolicy: 'cache-and-network',
	});

	const publicLinkDataIsLoading = publicLinkData.isLoading;
	const pageIsExternallyShared =
		!publicLinkData.isLoading && publicLinkData.isPublicLinkFullyEnabled;

	// Data is not used so we don't worry about errors here
	useEffect(() => {
		for (const error of publicLinkData.errors) {
			markErrorAsHandled(error);
		}
	}, [publicLinkData.errors]);

	const {
		isPublishingContent,
		userOffline,
		forceDisabled,
		editorTitle,
		collabLimitReached,
		collabEditConnected,
	} = useEditorObjectHeaderContext();

	const isEditorMode = contentMode === 'draft' || contentMode === 'edit' || contentMode === 'live';

	// this check should be equivalent to areMainButtonsDisabled
	const disableEditorButtons =
		isPublishingContent || userOffline || !!forceDisabled || publicLinkDataIsLoading;
	const shouldDisableEditorButtons = isEditorMode && disableEditorButtons;

	const isEditorTitleEmpty = !editorTitle || !editorTitle.trim();
	const isPublishDisabledForPublishModal =
		shouldDisableEditorButtons || collabLimitReached || !collabEditConnected;
	const isPublishDisabled = isEditorTitleEmpty || isPublishDisabledForPublishModal;

	return {
		shouldDisableEditorButtons,
		isPublishDisabledForPublishModal,
		isPublishDisabled,
		pageIsExternallyShared,
	};
};
