import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { useSpaceKey } from '@confluence/space-utils';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';

import { PublicLinkContentQuery } from '../graphql/PublicLinkContentQuery.graphql';
import type {
	PublicLinkContentQuery as PublicLinkContentQueryType,
	PublicLinkContentQueryVariables,
} from '../graphql/__types__/PublicLinkContentQuery';

export type PublicLinkContentData = {
	contentType: string;
	canEditPublicLink: boolean;
};

export type PublicLinkContentQueryData = {
	isLoading: boolean;
	error?: Error;
	data?: PublicLinkContentData;
};

const computeData = (
	data: PublicLinkContentQueryType | undefined,
	isExternalCollaborator: boolean,
): PublicLinkContentData => {
	const contentType = data?.content?.nodes?.[0]?.type || '';
	const operations = data?.content?.nodes?.[0]?.operations || [];
	const isSpaceAdmin = Boolean(data?.space?.currentUser.isAdmin);
	const canEditPage =
		operations.filter(
			(operationCheckResult) =>
				operationCheckResult?.operation === 'update' &&
				operationCheckResult?.targetType === contentType,
		).length > 0;

	return {
		contentType,
		canEditPublicLink: (isSpaceAdmin || canEditPage) && !isExternalCollaborator,
	};
};

export const usePublicLinkContentQuery = (props: {
	contentId: string;
	skip?: boolean;
}): PublicLinkContentQueryData => {
	const spaceKey = useSpaceKey();
	const { isExternalCollaborator } = useIsExternalCollaborator();
	const { loading, data, error } = useQuery<
		PublicLinkContentQueryType,
		PublicLinkContentQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PublicLinkContentQuery,
		{
			skip: props.skip,
			variables: {
				contentId: props.contentId,
				hasSpaceKey: Boolean(spaceKey),
				spaceKey: spaceKey ?? '',
			},
		},
	);
	useEffect(() => {
		if (isUnauthorizedError(error)) markErrorAsHandled(error);
	}, [error]);

	return loading
		? { isLoading: true }
		: {
				isLoading: false,
				error,
				data: error ? undefined : computeData(data, isExternalCollaborator),
			};
};
