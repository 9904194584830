import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';

import type {
	LabelCountQuery as LabelCountQueryData,
	LabelCountQueryVariables,
} from './__types__/LabelCountQuery';
import { LabelCountQuery } from './LabelCountQuery.graphql';

export const useLabels = (contentId: string) => {
	const [hasLabels, setHasLabels] = useState<boolean>(true);

	const { data } = useQuery<LabelCountQueryData, LabelCountQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		LabelCountQuery,
		{
			variables: { contentId },
		},
	);

	useEffect(() => {
		setHasLabels((data?.content?.nodes?.[0]?.labels?.count ?? 0) > 0);
	}, [data]);

	return hasLabels;
};
