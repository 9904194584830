/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PublicLinkPermissionsObjectType {
  CONTENT = "CONTENT",
}

export enum PublicLinkPermissionsType {
  EDIT = "EDIT",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublicLinkPermissionsForObject
// ====================================================

export interface PublicLinkPermissionsForObject_publicLinkPermissionsForObject {
  permissions: PublicLinkPermissionsType[];
}

export interface PublicLinkPermissionsForObject {
  publicLinkPermissionsForObject: PublicLinkPermissionsForObject_publicLinkPermissionsForObject | null;
}

export interface PublicLinkPermissionsForObjectVariables {
  objectId: string;
  objectType: PublicLinkPermissionsObjectType;
}
