import gql from 'graphql-tag';

export const PublicLinkContentQuery = gql`
	query PublicLinkContentQuery($contentId: ID!, $spaceKey: String!, $hasSpaceKey: Boolean!) {
		content(id: $contentId) {
			nodes {
				id
				type
				operations {
					operation
					targetType
				}
			}
		}
		space(key: $spaceKey) @include(if: $hasSpaceKey) {
			id
			currentUser {
				isAdmin
			}
		}
	}
`;
