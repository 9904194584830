import type { WatchDialogQuery as WatchDialogQueryType } from './__types__/WatchDialogQuery';
import type { WatchDialogQueryWithWatchChildren as WatchDialogQueryWithWatchChildrenType } from './__types__/WatchDialogQueryWithWatchChildren';

export const processWatchData = (
	data: WatchDialogQueryType | WatchDialogQueryWithWatchChildrenType,
) => {
	const isCurrentUserWatching = data?.singleContent?.currentUserIsWatching ?? false;
	const isWatchingSpace = data?.singleContent?.space?.currentUser?.isWatched ?? false;
	const isWatchingBlogs = data?.singleContent?.space?.currentUser?.isWatchingBlogs ?? false;
	// TODO remove type checks once watch with children gate cleaned up
	const isWatchingChildren =
		data?.singleContent && 'currentUserIsWatchingChildren' in data?.singleContent
			? data?.singleContent?.currentUserIsWatchingChildren ?? false
			: false;
	const hasAncestorWatchingChildren =
		data?.singleContent && 'currentUserHasAncestorWatchingChildren' in data?.singleContent
			? data?.singleContent?.currentUserHasAncestorWatchingChildren ?? false
			: false;

	return {
		isCurrentUserWatching,
		isWatchingSpace,
		isWatchingBlogs,
		isWatchingChildren,
		hasAncestorWatchingChildren,
	};
};
