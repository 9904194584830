import { LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';

import { PlaceholderPublishButton } from './PlaceholderPublishButton';

export const PublishButtonWithMoreOptions = LoadableAfterPaint({
	loader: async () => {
		return (
			await import(
				/* webpackChunkName: "loadable-PublishButtonWithMoreOptions" */ './PublishButtonWithMoreOptions'
			)
		).PublishButtonWithMoreOptions;
	},
	loading: PlaceholderPublishButton,
});

export const PublishModalMountPoint = LoadableLazy({
	loader: async () => {
		return (
			await import(
				/* webpackChunkName: "loadable-PublishModalMountPoint" */ './PublishModalMountPoint'
			)
		).PublishModalMountPoint;
	},
});

export const PublishModalKeyboardShortcuts = LoadableLazy({
	loader: async () => {
		return (
			await import(
				/* webpackChunkName: "loadable-PublishModalKeyboardShortcuts" */ './PublishModalKeyboardShortcuts'
			)
		).PublishModalKeyboardShortcuts;
	},
});

export { usePublishModalMountStore, usePublishModalMountActions } from './PublishModalMountState';

export { usePrePublishTitleValidation } from './usePrePublishTitleValidation';

export type { AutoFocusTargetOptions } from './types';
