import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import Button, { IconButton, SplitButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import GlobeIcon from '@atlaskit/icon/core/globe';
import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';

import { i18nShared } from './i18nShared';

export type Props = {
	publishButtonDisabled: boolean | undefined;
	isUnpublishedDraft: boolean;
	shouldShowWorldIcon: boolean;
};

export const PlaceholderPublishButton = ({
	publishButtonDisabled,
	isUnpublishedDraft,
	shouldShowWorldIcon,
}: Props) => {
	const intl = useIntl();

	if (!isUnpublishedDraft) {
		const WorldIcon = () => (
			<GlobeIcon
				label={intl.formatMessage(i18nShared.updatePublicContentLabel)}
				color={token('color.icon.inverse')}
			/>
		);
		return (
			<SplitButton appearance="primary" isDisabled={publishButtonDisabled}>
				<Button
					appearance="primary"
					isDisabled={publishButtonDisabled}
					iconBefore={shouldShowWorldIcon ? WorldIcon : undefined}
				>
					{intl.formatMessage(i18nShared.updateButton)}
				</Button>
				<IconButton
					icon={ChevronDownIcon}
					label={intl.formatMessage(
						fg('confluence_frontend_object_header')
							? i18nShared.chevronButtonTooltipObjectHeader
							: i18nShared.chevronButtonTooltip,
					)}
				/>
			</SplitButton>
		);
	} else {
		return (
			<Button appearance="primary" isDisabled={publishButtonDisabled}>
				<FormattedMessage {...i18nShared.publishButton} />
			</Button>
		);
	}
};
