import gql from 'graphql-tag';

export const PublicLinkPermissionsForObjectQuery = gql`
	query PublicLinkPermissionsForObject(
		$objectId: ID!
		$objectType: PublicLinkPermissionsObjectType!
	) {
		publicLinkPermissionsForObject(objectId: $objectId, objectType: $objectType) {
			permissions
		}
	}
`;
